import { Box, Button, ButtonBase, DialogTitle, Drawer, FormControl, FormHelperText, FormLabel, Grid, InputAdornment, InputBase, Slide, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import React from "react";
import ReactTimePicker from "./TimePicker";


import "../../../styles/Calendar.css";
import downarrow_back from "../../../assets/back.png";
import calender_black from "../../../assets/calender_black.svg";
import info_i from "../../../assets/i.svg";
import IOSSwitch from "../../../components/IOSSwitch";
import { RadioGroup } from "@headlessui/react";
import { DateObject, Calendar as MultiPickCalendar } from "react-multi-date-picker";
import { dateInArray } from "../../../helpers";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

const formLableStyle = {
  my: 1,
  color: "rgba(0, 0, 0, 0.6);"
};

const formInputStyle = {
  p: 2,
  // border: 1,
  borderRadius: 3,
  // borderColor: formErrors["className"] ? "#d32f2f" : "#CCCCCC",
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  minWidth: 455,
  maxWidth: 455,
  "&:hover, &.Mui-focused": {
    border: 1,
    borderColor: "#7E00DD"
  },
  '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0,
    borderColor: 'rgba(0, 0, 0, 0)',
  },
};

const formInputClasses = {
  input: {
    "&::placeholder": {
      color: "#808080",
      fontWeight: 300,
    },
  },
};
const taboptions = [
  {
    value: "freeTime",
    text: "Free Time",
  },
  {
    value: "freeDays",
    text: "Free Days",
  },
];

export default function AddEditFreeSlotModal({
  isEdit, loading, open, text, data, onClose, onSubmit
}) {
  const [formValues, setFormValues] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const [openSlotDate, setOpenSlotDate] = React.useState(false);
  React.useEffect(() => {
    console.log(data);
    setFormValues(data)
  }, [data]);
  React.useEffect(() => {
    console.log(data);
    // setFormValues(data)
    setFormValues((prevValues) => ({
      ...prevValues,
      slot_start_time: formValues.isFullDay ? moment().startOf('day').format('hh:mm A') : moment(data.slot_start_time, 'hh:mm A').format('hh:mm A'),
      slot_end_time: formValues.isFullDay ? moment().startOf('day').format('hh:mm A') : moment(data.slot_end_time, "h:mm A").format("h:mm A"),
      slotDates: formValues.isFullDay ? (formValues.slotDates.lenght ? formValues.slotDates : []) : [moment(formValues.slot_date).format("YYYY-MM-DD")],
    }));
  }, [formValues.isFullDay]);

  const RadioOption = ({ value, text }) => {
    return (
      <ButtonBase
        // onClick={() =>
        //   value === "Custom" ? handleClearDaysInWeek() : undefined
        // }
        sx={{ borderRadius: 3, "&:focus": { outline: 0 } }}
      >
        <RadioGroup.Option value={value}>
          {({ checked }) => (
            <span
              className={`w-[165.6px] h-[40.62px] rounded-xl grid place-items-center font-semibold text-[12.5px] cursor-pointer ${checked
                ? "bg-light-brown-coffee text-white"
                : "bg-white border border-light-brown-coffee text-light-brown-coffee"
                }`}
            >
              {text}
            </span>
          )}
        </RadioGroup.Option>
      </ButtonBase>
    );
  };

  const handleCalendarDaysChange = (dates) => {
    const datesSelected = dates.map((date) => {
      let transformedDate;
      if (date instanceof DateObject) {
        transformedDate = date.toDate();
      }
      return transformedDate;
    });
    setFormValues((prevValues) => ({
      ...prevValues,
      slotDates: datesSelected,
    }));
  };

  return (
    <Drawer
      ModalProps={{
        onBackdropClick: () => { },
        keepMounted: true,
      }}
      anchor="right"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      PaperProps={{
        sx: {
          // borderRadius: 5,
        },
      }}
    >
      <DialogTitle>{isEdit ? "Edit" : "Add"} Personal Time <br></br> <span className="text-xs"><img className={"w-4 h-4 inline mr-2 align-text-top"} src={info_i} alt={""} /> Add your holidays and  free slots here</span></DialogTitle>
      <Box className={"flex flex-col w-full gap-3 py-6 px-8"}>
        <RadioGroup
          value={formValues.isFullDay ? "freeDays" : "freeTime"}
          onChange={(newValue) => {
            if (newValue === "freeDays") {
              setFormValues((prevValues) => ({
                ...prevValues,
                isFullDay: true,
              }));
            } else {
              setFormValues((prevValues) => ({
                ...prevValues,
                isFullDay: false,
              }));
            }
          }}
          className={"flex flex-row items-center gap-2"}
        >
          {taboptions.map((opt, idx) => (
            <RadioOption key={idx} {...opt} />
          ))}
        </RadioGroup>
        <Grid container xs={12} direction={"column"}>
          <Grid item >
            <FormControl error={formErrors["title"] ? true : false}>
              <FormLabel sx={formLableStyle}>Title</FormLabel>
              <InputBase
                name={"title"}
                type="text"
                placeholder="Title"
                value={formValues.title}
                inputProps={{ maxLength: 30 }}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    title: e.target.value,
                  }))
                }}
                classes={formInputClasses}
                sx={formInputStyle}
              />
              {formErrors["title"] && (
                <FormHelperText>{formErrors["title"]}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {formValues.isFullDay === false && (<Grid item >
            <FormControl error={formErrors["slot_date"] ? true : false}>
              <FormLabel sx={formLableStyle}>Start Date</FormLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  minDate={new Date()}
                  value={formValues.slot_date}
                  // value={moment(formValues.slot_date, 'YYYY-MM-DD').format("MM/DD/YYYY")}
                  onChange={(newValue) => {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      slot_date: newValue,
                    }))
                  }}
                  open={openSlotDate}
                  onOpen={() => { setOpenSlotDate(true) }}
                  onClose={() => { setOpenSlotDate(false) }}
                  renderInput={(params) => {
                    const { inputRef, inputProps, InputProps } = params;
                    const { placeholder, classes, ...restinputProps } =
                      inputProps;
                    return (
                      <InputBase
                        ref={inputRef}
                        {...restinputProps}
                        placeholder={"Select Start Date"}
                        classes={formInputClasses}
                        sx={{
                          ...formInputStyle,
                          borderColor: formErrors["slot_date"]
                            ? "#d32f2f"
                            : "#EBEBEB",
                        }}
                        onClick={() => { setOpenSlotDate(!openSlotDate) }}
                        startAdornment={
                          <InputAdornment position={"start"}>
                            <img className={"w-5"} src={calender_black} alt={""} />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position={"end"} className={"cursor-pointer"} >
                            <img className={"w-3"} src={downarrow_back} alt={""} />
                          </InputAdornment>
                        }
                      />
                    );
                  }}
                />
              </LocalizationProvider>
              {formErrors["slot_date"] && (
                <FormHelperText>{formErrors["slot_date"]}</FormHelperText>
              )}
            </FormControl>
          </Grid>)}
          {formValues.isFullDay === true && (<Grid item className="py-5" sx={{ minWidth: 455, }}>
            <MultiPickCalendar
              className='bookzy-calendar bookzy-border'
              minDate={new Date()}
              value={formValues.slotDates}
              onChange={handleCalendarDaysChange}
              multiple
              numberOfMonths={1}
              weekDays={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]}
              mapDays={({
                date,
                today,
                selectedDate,
                currentMonth,
                isSameDate,
              }) => {
                let props = {};
                if (isSameDate(date, today)) {
                  props.className = "default-current-date";
                  props.style = {
                    ...props.style,
                    border: "1px solid #777",
                  };
                }
                const dateTransformed = date.toDate();
                const selectedDatesTransformed = selectedDate.map((day) =>
                  day.toDate()
                );
                if (dateInArray(dateTransformed, selectedDatesTransformed)) {
                  props.className = "selected-date";
                }
                return props;
              }}
            />
            {formErrors["slot_date"] && (
              <FormHelperText sx={{ color: "#d32f2f" }}>{formErrors["slot_date"]}</FormHelperText>
            )}
          </Grid>)}
          {/* <Grid item >
            <FormControl error={formErrors["isFullDay"] ? true : false}>
              <div style={{ display: 'inline-flex' }}>
                <Box sx={formLableStyle}>
                  Is Full day
                </Box>
                <Box sx={{
                  alignSelf: 'center',
                  px: 2
                }}>
                  <IOSSwitch
                    className={"place-self-end"}
                    name={"freeCancellation"}
                    type={"checkbox"}
                    checked={formValues.isFullDay}
                    onChange={() => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        isFullDay: !formValues.isFullDay,
                      }));
                    }}
                  />
                  {formErrors["isFullDay"] && (
                    <FormHelperText>{formErrors["isFullDay"]}</FormHelperText>
                  )}
                </Box>
              </div>

            </FormControl>
          </Grid> */}
          {
            formValues.isFullDay === false &&
            (<Grid item >
              <FormControl error={formErrors["slot_time"] ? true : false}>
                <FormLabel sx={formLableStyle}>Time</FormLabel>
                <div
                  className="flex flex-row items-center"
                  style={{ marginBottom: "11px" }}
                >
                  <ReactTimePicker
                    time={formValues?.slot_start_time}
                    placeholderLabel="Select Start Time"
                    handleTimeChange={(time) =>
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        slot_start_time: moment(time).format("h:mm A"),
                      }))
                    }
                  />
                  <span style={{ marginLeft: "23px", marginRight: "23px" }}>
                    to
                  </span>
                  <ReactTimePicker
                    time={formValues?.slot_end_time}
                    placeholderLabel="Select End Time"
                    handleTimeChange={(time) =>
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        slot_end_time: moment(time).format("h:mm A"),
                      }))
                    }
                  />
                </div>
                {formErrors["slot_time"] && (
                  <FormHelperText>{formErrors["slot_time"]}</FormHelperText>
                )}
              </FormControl>
            </Grid>)}
          <FormHelperText sx={{ color: "#d32f2f" }}>{text}</FormHelperText>
        </Grid>
      </Box>
      <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 3 }}>
        <Button
          variant={"contained"}
          // sx={{ textTransform: "none", borderRadius: 2.5 }}
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 220,
            height: 52,
            fontSize: 16,
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color={"secondary"}
          disabled={loading}
          // sx={{ textTransform: "none", borderRadius: 2.5 }}
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 220,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            const errors = {};
            if (!formValues.title) {
              errors.title = "Required!";
            }
            if (formValues.isFullDay === false && !formValues.slot_date) {
              errors.slot_date = "Required!";
            }
            if (!formValues.slot_start_time) {
              errors.slot_time = "Required!";
            }
            if (!formValues.slot_end_time) {
              errors.slot_time = "Required!";
            }
            if (moment(formValues.slot_end_time, "h:mm A").isBefore(moment(formValues.slot_start_time, "h:mm A"), 'minute')) {
              errors.slot_time = "Past slot time is not allowed!";
            }
            if (formValues.isFullDay === true && formValues.slotDates.length <= 0) {
              errors.slot_date = "Required!";
            }
            console.log("formValues", formValues);
            console.log("errors", errors);
            setFormErrors(errors)
            if (Object.keys(errors).length === 0) {
              onSubmit({
                ...formValues,
                slot_date: formValues.isFullDay && formValues.slotDates.length > 0 ? formValues.slotDates.map((slotdate) => moment(slotdate).format("YYYY-MM-DD")) : [moment(formValues.slot_date).format("YYYY-MM-DD")],
              });
            }
          }}
        >
          Submit
        </Button>
      </Stack>
    </Drawer>
  );
}
