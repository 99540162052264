import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Box, useMediaQuery, IconButton } from "@mui/material";
import GoogleMapReact from "google-map-react";
import IconTint from "react-icon-tint";
import { useFormik } from "formik";

import { useLocalStorage } from "../../../hooks";
import { schoolState } from "../../../redux/reducers/schoolReducer";
import { userState } from "../../../redux/reducers/userReducer";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { useTheme } from "@mui/material/styles";

import {
    LoadingBackdrop,
} from "../../../layouts";

import edit_img from "../../../assets/edit.png";
import map_marker from "../../../assets/map-marker.png";
import email_img from "../../../assets/Email.png";
import phone_img from "../../../assets/Phone.png";
import website_img from "../../../assets/Website.png";
import facebook_img from "../../../assets/Facebook F.png";
import instagram_img from "../../../assets/Instagram.png";
import youtube_img from "../../../assets/YouTube.png";
import linkedin_img from "../../../assets/LinkedIn 2.png";
import black_map_icon from "../../../assets/black_map_icon.png";
import twitter_img from "../../../assets/Twitter.png";
import bookzy_cover from "../../../assets/bookzy_cover.png";
import { useNavigate } from "react-router-dom";
import NeewSidebar from "../../../components/NewSideBar";
import { getUrlForUsers } from "../../../utils/confighelpers";

const defaultMapProps = {
    center: {
        lat: 52.370216,
        lng: 4.895168,
    },
    zoom: 11,
};

export default function ViewBusinessProfile({ navigation }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const schoolReduxState = useSelector(schoolState);
    const userReduxState = useSelector(userState);
    const [userToken] = useLocalStorage("userToken");
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [schoolData, setSchoolData] = React.useState({});
    const [contactDetails, setContactDetails] = React.useState([]);
    const [isAboutExpanded, setAboutExpanded] = React.useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isLaptopL = useMediaQuery(
        "(min-width: 1024px) and (max-width: 1279px)"
    );
    const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

    const formik = useFormik({
        initialValues: {
            businessTypes: {},
            businessProfileType: "individual", //business
        }
    })

    React.useEffect(() => {
        (async () => {
            setLoading(true);
            const config = {
                method: "get",
                // url: `${getUrlForStage()}schools/mySchoolList`,
                url: `${getUrlForUsers()}getUserInfo`,
                headers: {
                    "x-access-token": userToken,
                },
            };
            const request = await axios(config);
            if (request.data.statusCode === 200) {
                // setSchoolData(request.data.data[0]);
                setSchoolData(request.data.data.myBusinessProfile);
            }
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const phoneNumberObj = userReduxState?.userPhone ? userReduxState.userPhone : "";
        const phoneNumberReduced = userReduxState.length && userReduxState?.userPhone ? Object.values(phoneNumberObj).reduce(
            (t, n) => (t += n)
        ) : "";

        const boxes = [
            {
                icon: email_img,
                text: schoolData?.workEmail || "No e-mail provided",
            },
            {
                icon: phone_img,
                // text: phoneNumberReduced || "No phone number provided",
                text: schoolData?.workNumber || "No phone number provided",
            },
            {
                icon: website_img,
                text: schoolData?.workWebsite || "No website provided",
            },
        ];
        setContactDetails([...boxes]);
    }, [schoolData, userReduxState.userPhone]);

    const handleEdit = () => {
        dispatch(dashboardActions.setSchoolData(schoolData));
        navigation("/businessprofile/update");
    };

    if (!userToken || userToken.length < 1) {
        navigate("/", { replace: true });
    }

    return (
        <NeewSidebar
            navBarTitleComp={
                <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
                    Business Profile
                </h3>
            }
            children={<LoadingBackdrop loading={loading}>
                <Box
                    component='main'
                    sx={{
                        flexGrow: 1,
                        py: 2,
                        px: 3,
                        overflowX: "hidden",
                        minHeight: "100vh",
                    }}
                    className="grid"
                >
                    <Box className="flex justify-end p-3 max-w-5xl">
                        <Box className="flex-none">
                            <button
                                className={"bg-[#3699FF] text-white rounded w-[160px] p-3"}
                                onClick={handleEdit}
                            >
                                <span className={"flex flex-row items-center"}>
                                    <p className={"w-full"}>Edit</p>
                                </span>
                            </button>
                        </Box>
                    </Box>
                    <div
                        className={
                            "container min-w-[48rem] max-w-3xl"
                        }
                    >
                        <div className="rounded-lg">
                            <div className={"max-w-3xl p-3"}>
                                <div className={"-space-y-12"}>
                                    {/* <div
                                        className={
                                            "w-full h-[250px] rounded-xl border border-bright-gray bg-white"
                                        }
                                    >
                                        {schoolData?.images?.length &&
                                            schoolData?.images[0] !== null ? (
                                            <img
                                                className={"w-full h-[250px] object-cover rounded-xl"}
                                                src={schoolData?.images[0]}
                                                alt={""}
                                            />
                                        ) : (
                                            <div
                                                className={
                                                    "text-center text-lg font-semibold h-full grid place-items-center"
                                                }
                                            >
                                                No Cover Photo Uploaded
                                            </div>
                                        )}
                                    </div> */}
                                    <div
                                        className={
                                            "w-full h-[134px] rounded-xl border border-bright-gray bg-white"
                                        }
                                    >
                                        <img
                                            className={"w-full h-[134px] object-cover rounded-xl"}
                                            src={bookzy_cover}
                                            alt={""}
                                        />
                                    </div>
                                    <div className={"flex flex-col items-left"}>
                                        <div
                                            className={
                                                "ml-6 w-[100px] h-[100px] rounded-xl bg-white border-0 border-[#ccc] shadow-2xl"
                                            }
                                        >
                                            {schoolData?.logo ? (
                                                <img
                                                    className={
                                                        "min-w-[100px] h-[100px] object-cover-1 rounded-xl -translate-y-0 border-0 border-[#e5e7eb]"
                                                    }
                                                    src={schoolData?.logo}
                                                    alt={""}
                                                />
                                            ) : (
                                                <div
                                                    className={
                                                        "text-center text-base font-semibold h-full grid place-items-center border border-[#e5e7eb] rounded-full"
                                                    }
                                                >
                                                    No Logo Uploaded
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                "mt-2 flex flex-row items-center justify-end w-4/5"
                                            }
                                        >
                                            {/* <button
                                                className={"bg-[#3699FF] text-white rounded-lg p-2 px-3"}
                                                onClick={handleEdit}
                                                sx={{ backgroundColor: "common.white" }}
                                            >
                                                <img
                                                    className={"w-[27px] h-[27px]"}
                                                    src={edit_img}
                                                    alt={""}
                                                /> Edit
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mt-10">
                                    <h4 className={"font-bold text-brown-coffee text-lg"}>
                                        Event Organizer
                                    </h4>
                                    <FormikProvider value={formik}>
                                        <Box
                                            className={
                                                "grid grid-cols-2 grid-flow-col w-full"
                                            }
                                            sx={{
                                                minWidth: isMobile ? 290 : isTablet ? 340 : 620,
                                                maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
                                            }}
                                        >
                                            <Box
                                                className=""
                                                sx={{
                                                    my: 1,
                                                    mr: 1,
                                                    p: 2,
                                                    borderRadius: 3,
                                                    border: 1,
                                                    borderColor: "#EBEBEB",
                                                    fontSize: 17,
                                                    "&:hover, &.Mui-focused": {
                                                        border: 1,
                                                        borderColor: "#7E00DD"
                                                    },
                                                    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderWidth: 0,
                                                        borderColor: 'rgba(0, 0, 0, 0)',
                                                    },
                                                }}>
                                                <Field
                                                    selected={schoolData?.businessProfileType === 'individual'}
                                                    type="radio"
                                                    name={"businessProfileType"}
                                                    value={"individual"}
                                                    form={formik}
                                                    onClick={(event) => null}
                                                    sx={{
                                                        verticalAlign: 'middle',
                                                        mr: 1
                                                    }}
                                                    id="idIndividual"
                                                    size={isMobile || isTablet ? "small" : "medium"}
                                                /> <label htmlFor="idIndividual">Individual</label>
                                            </Box>
                                            <Box
                                                className=""
                                                sx={{
                                                    my: 1,
                                                    ml: 1,
                                                    p: 2,
                                                    borderRadius: 3,
                                                    border: 1,
                                                    borderColor: "#EBEBEB",
                                                    fontSize: 17,
                                                    "&:hover, &.Mui-focused": {
                                                        border: 1,
                                                        borderColor: "#7E00DD"
                                                    },
                                                    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderWidth: 0,
                                                        borderColor: 'rgba(0, 0, 0, 0)',
                                                    },
                                                }}>
                                                <Field
                                                    selected={schoolData?.businessProfileType !== 'individual'}
                                                    type="radio"
                                                    name={"businessProfileType"}
                                                    value={"business"}
                                                    form={formik}
                                                    onClick={(event) => null}
                                                    sx={{
                                                        verticalAlign: 'middle',
                                                        mr: 1
                                                    }}
                                                    id="idBusiness"
                                                    size={isMobile || isTablet ? "small" : "medium"}
                                                /> <label htmlFor="idBusiness">Business</label>
                                            </Box>
                                        </Box>
                                    </FormikProvider>
                                </div> */}
                                {/* <div className={"mt-10"}>
                                    <h4 className={"font-bold text-brown-coffee text-lg"}>
                                        Class Categories
                                    </h4>
                                    <div className="flex flex-wrap mx-5 sm:mx-0 mb-2">
                                        {schoolReduxState.schoolCategories.map((cat) => (
                                            <CategoryBoxSelected
                                                key={cat.catId}
                                                add={false}
                                                remove={false}
                                                label={cat.catName}
                                                vector={cat.catImage}
                                                bgColor={"#481B3A"}
                                                svgFill={"#FFFFFF"}
                                            />
                                        ))}
                                    </div>
                                </div> */}
                                <div className={"mt-6"}>
                                    <h4 className={"font-bold text-brown-coffee text-lg"}>
                                        {schoolData?.businessProfileType === 'individual' ? "Host Name" : "Company Name"}
                                    </h4>
                                    <p className={"text-brown-coffee"}>
                                        {schoolData?.businessName}
                                    </p>
                                    <h4 className={"mt-2 font-bold text-brown-coffee text-lg"}>
                                        About
                                    </h4>
                                    <p className={"mt-1 font-light text-base"}>
                                        {schoolData?.about?.length > 175 ? (
                                            !isAboutExpanded ? (
                                                <>
                                                    {schoolData?.about.slice(0, 175)}
                                                    <span
                                                        className={
                                                            "text-gray-400 font-light text-base cursor-pointer"
                                                        }
                                                        onClick={() => setAboutExpanded(true)}
                                                    >
                                                        ...see more
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {schoolData?.about}
                                                    <br />
                                                    <span
                                                        className={
                                                            "text-gray-400 font-light text-base cursor-pointer"
                                                        }
                                                        onClick={() => setAboutExpanded(false)}
                                                    >
                                                        see less
                                                    </span>
                                                </>
                                            )
                                        ) : (
                                            <span>{schoolData?.about}</span>
                                        )}
                                    </p>
                                </div>
                                <div className={"mt-6"}>
                                    <h4 className={"font-bold text-brown-coffee text-lg"}>
                                        Address
                                    </h4>
                                    <p className={"mt-2 font-light text-base mb-2"}>
                                        <span className="flex items-center">
                                            <img src={black_map_icon} height="16" width="16" />
                                            <span className="ml-2">
                                                {/* {schoolData?.locationObj?.address} */}
                                                {schoolData?.locationObj?.address || schoolData?.address?.address}
                                            </span>
                                        </span>
                                    </p>
                                    <div className={"w-full h-64 rounded-[60px] overflow-hidden"}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{
                                                key: process.env.REACT_APP_GOOGLE_MAPS_API,
                                            }}
                                            defaultCenter={defaultMapProps.center}
                                            defaultZoom={defaultMapProps.zoom}
                                            center={{
                                                lat: schoolData?.address?.location?.coordinates[0],
                                                lng: schoolData?.address?.location?.coordinates[1],
                                            }}
                                            zoom={11}
                                        >
                                            <div
                                                className={""}
                                                lat={schoolData?.address?.location?.coordinates[0]}
                                                lng={schoolData?.address?.location?.coordinates[1]}
                                            >
                                                <img
                                                    className={"w-[30px] h-[40px]"}
                                                    src={map_marker}
                                                    alt={""}
                                                />
                                            </div>
                                        </GoogleMapReact>
                                    </div>
                                </div>
                                <div className={"mt-6"}>
                                    <h4 className={"font-bold text-brown-coffee text-lg"}>
                                        Contact Info
                                    </h4>
                                    <div className={"mt-2 grid grid-cols-2 gap-y-4 gap-x-4"}>
                                        {contactDetails.map((box, idx) => (
                                            <div
                                                key={idx}
                                                className={
                                                    "flex flex-row items-center bg-white p-2 rounded-xl"
                                                }
                                            >
                                                <div
                                                    className={
                                                        "min-w-[35px] h-[35px] grid place-items-center rounded-full"
                                                    }
                                                >
                                                    <img
                                                        className={"w-[18px] h-[18px]"}
                                                        src={box.icon}
                                                        alt={""}
                                                    />
                                                </div>
                                                <p className={"ml-4 font-medium text-lg flex-wrap"}>
                                                    {box.text}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={"mt-6"}>
                                    <h4 className={"font-bold text-brown-coffee text-lg"}>
                                        Link to Social Media
                                    </h4>
                                    <div className={"mt-2 flex flex-row items-center gap-2"}>
                                        <div
                                            className={`w-[58px] h-[58px] grid place-items-center ${schoolData?.socialLink?.facebook
                                                ? "bg-brown-coffee cursor-pointer hover:bg-light-brown-coffee"
                                                : "bg-white"
                                                } border border-[#CCCCCC] rounded-full`}
                                            onClick={() => {
                                                if (schoolData?.socialLink?.facebook) {
                                                    let url = schoolData?.socialLink?.facebook;
                                                    if (!url.startsWith("https://")) {
                                                        url = `https://${schoolData?.socialLink?.facebook}`;
                                                    }
                                                    window.open(url);
                                                } else {
                                                    return undefined;
                                                }
                                            }}
                                        >
                                            <IconTint
                                                maxWidth={32}
                                                maxHeight={28}
                                                src={facebook_img}
                                                color={
                                                    schoolData?.socialLink?.facebook ? "#FFF" : "#888"
                                                }
                                            />
                                        </div>
                                        <div
                                            className={`w-[58px] h-[58px] grid place-items-center ${schoolData?.socialLink?.instagram
                                                ? "bg-brown-coffee cursor-pointer hover:bg-light-brown-coffee"
                                                : "bg-white"
                                                } border border-[#CCCCCC] rounded-full`}
                                            onClick={() => {
                                                if (schoolData?.socialLink?.instagram) {
                                                    let url = schoolData?.socialLink?.instagram;
                                                    if (!url.startsWith("https://")) {
                                                        url = `https://${schoolData?.socialLink?.instagram}`;
                                                    }
                                                    window.open(url);
                                                } else {
                                                    return undefined;
                                                }
                                            }}
                                        >
                                            <IconTint
                                                maxWidth={28}
                                                maxHeight={28}
                                                src={instagram_img}
                                                color={
                                                    schoolData?.socialLink?.instagram ? "#FFF" : "#888"
                                                }
                                            />
                                        </div>
                                        <div
                                            className={`w-[58px] h-[58px] grid place-items-center ${schoolData?.socialLink?.youtube
                                                ? "bg-brown-coffee cursor-pointer hover:bg-light-brown-coffee"
                                                : "bg-white"
                                                } border border-[#CCCCCC] rounded-full`}
                                            onClick={() => {
                                                if (schoolData?.socialLink?.youtube) {
                                                    let url = schoolData?.socialLink?.youtube;
                                                    if (!url.startsWith("https://")) {
                                                        url = `https://${schoolData?.socialLink?.youtube}`;
                                                    }
                                                    window.open(url);
                                                } else {
                                                    return undefined;
                                                }
                                            }}
                                        >
                                            <IconTint
                                                maxWidth={34}
                                                maxHeight={34}
                                                src={youtube_img}
                                                color={
                                                    schoolData?.socialLink?.youtube ? "#FFF" : "#888"
                                                }
                                            />
                                        </div>
                                        <div
                                            className={`w-[58px] h-[58px] grid place-items-center ${schoolData?.socialLink?.linkedIn
                                                ? "bg-brown-coffee cursor-pointer hover:bg-light-brown-coffee"
                                                : "bg-white"
                                                } border border-[#CCCCCC] rounded-full`}
                                            onClick={() => {
                                                if (schoolData?.socialLink?.linkedIn) {
                                                    let url = schoolData?.socialLink?.linkedIn;
                                                    if (!url.startsWith("https://")) {
                                                        url = `https://${schoolData?.socialLink?.linkedIn}`;
                                                    }
                                                    window.open(url);
                                                } else {
                                                    return undefined;
                                                }
                                            }}
                                        >
                                            <IconTint
                                                maxWidth={26}
                                                maxHeight={26}
                                                src={linkedin_img}
                                                color={
                                                    schoolData?.socialLink?.linkedIn ? "#FFF" : "#888"
                                                }
                                            />
                                        </div>
                                        <div
                                            className={`w-[58px] h-[58px] grid place-items-center ${schoolData?.socialLink?.twitter
                                                ? "bg-brown-coffee cursor-pointer hover:bg-light-brown-coffee"
                                                : "bg-white"
                                                } border border-[#CCCCCC] rounded-full`}
                                            onClick={() => {
                                                if (schoolData?.socialLink?.twitter) {
                                                    let url = schoolData?.socialLink?.twitter;
                                                    if (!url.startsWith("https://")) {
                                                        url = `https://${schoolData?.socialLink?.twitter}`;
                                                    }
                                                    window.open(url);
                                                } else {
                                                    return undefined;
                                                }
                                            }}
                                        >
                                            <IconTint
                                                maxWidth={27}
                                                maxHeight={27}
                                                src={twitter_img}
                                                color={
                                                    schoolData?.socialLink?.twitter ? "#FFF" : "#888"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </LoadingBackdrop>} />
    );
}
