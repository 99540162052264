import {
  Box, Button,
  FormControl,
  FormHelperText, FormLabel, InputBase, MenuItem, Select, Stack,
  Typography
} from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import { eachMinuteOfInterval, format, sub } from "date-fns";
import React from "react";
import Dropzone from "react-dropzone";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import defaultImage from "../../../../assets/defaultImage.svg";
import uploadImage from "../../../../assets/uploadImage.svg";
import LinearProgressWithLabel from "../../../../components/LinearProgressWithLabel";
import { handleUploadImageToS3 } from "../../../../helpers";
import { useLocalStorage } from "../../../../hooks";
import { dashboardState } from "../../../../redux/reducers/dashboardReducer";
import { schoolState } from "../../../../redux/reducers/schoolReducer";
import WarningDialog from "../../../confirmationModals/WarningDialog";
import { getUrlForServices, getUrlForUsers } from "../../../../utils/confighelpers";

const popperModifiers = { placement: "bottom" };

export default function GeneralTab({
  title,
  openSuccessModal,
  edit,
  setLoading,
  formValues,
  setFormValues,
  setTabCurrentState,
  handleUpdate,
  handleCancel,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userToken] = useLocalStorage("userToken");
  const dashboardReduxState = useSelector(dashboardState);
  const schoolReduxState = useSelector(schoolState);
  const sessionDataToDisplay = dashboardReduxState.calendarSessionData ? dashboardReduxState.calendarSessionData : {};
  const classInfo = sessionDataToDisplay?.classId ? sessionDataToDisplay.classId : {};
  const [eventcategoryOpts, setEventCategoryOpts] = React.useState([]);
  const [categoryOpts, setCategoryOpts] = React.useState([]);
  const [serviceAvailableForOpts, setServiceAvailableForOpts] = React.useState([
    "all_genders",
    "only_women",
    "only_men",
  ]);
  const [serviceAvailableForAgeGroupOpts, setServiceAvailableForAgeGroupOpts] = React.useState([
    "all_age_groups",
    "below_12",
    "12-18",
    "adults_only",
  ]);
  const [venueList, setVenueList] = React.useState([]);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [imageProgress, setImageProgress] = React.useState(0);
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await getListOfVenue();
      const config = {
        method: "get",
        url: `${getUrlForUsers()}categories/get?businesType=service`,
        headers: {
          "x-access-token": userToken.toString(),
          "Access-Control-Allow-Headers": "*",
        }
      };
      const request = await axios(config);
      setEventCategoryOpts(request.data.data);
      // setCategoryOpts(request.data.data);
      if (edit && request.data?.data?.length) {
        const allEventCategories = request.data.data.map((item) => [...item.category]).flat()
        const selectedeventCategory = allEventCategories.find(catItem => {
          return (catItem._id === formValues.serviceType._id)
        })
        setFormValues((prevValues) => ({
          ...prevValues,
          serviceType: selectedeventCategory,
        }));

        const selectedCat = selectedeventCategory.category.find(catItem => {
          return (catItem._id === formValues.categoryId)
        })
        setCategoryOpts(selectedeventCategory?.category ? selectedeventCategory.category : []);
        setFormValues((prevValues) => ({
          ...prevValues,
          categories: selectedCat,
        }));
      }
      setLoading(false);
    })();
  }, []);

  const getListOfVenue = async () => {
    const manageClassesConfig = {
      method: "get",
      url: `${getUrlForServices()}venue/list?getall=${true}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const responce = await axios(manageClassesConfig);
    if (responce.status === 200) {
      if (responce.data.data.length) {
        setVenueList(responce.data.data);
      }else{
        setOpenWarningDialog(true);
      }
    }
  };

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    // cogoToast.loading("Loading...");
    // setLoading(true);
    const errors = {};
    if (!formValues.servicePhoto) {
      errors.servicePhoto = "Required!";
    }
    if (!formValues.serviceName) {
      errors.serviceName = "Required!";
    }
    if (!Object.keys(formValues.serviceType).length) {
      errors.serviceType = "Required!";
    }
    if (!Object.keys(formValues.categories).length) {
      errors.categories = "Required!";
    }
    if (!formValues.serviceAvailableFor) {
      errors.serviceAvailableFor = "Required!";
    }
    if (!formValues.serviceAvailableForAgeGroup) {
      errors.serviceAvailableForAgeGroup = "Required!";
    }
    if (!formValues.description) {
      errors.description = "Required!";
    } else if (formValues.description.length < 5) {
      errors.description = `Enter at least 5 letters to explain your ${title}`;
    }
    if (!formValues.venueDetail) {
      errors.venueDetail = "Required";
    }
    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      setSubmitting(false);
      cogoToast.error("Please fix errors below", { hideAfter: 3 });
      return;
    }


    setSubmitting(false);
    setLoading(false);
    setTabCurrentState("2");

  };

  const clearFormValues = () => {
    setFormValues({
      servicePhoto: "",
      serviceName: "",
      serviceType: "",
      categories: "",
      serviceAvailableFor: "all_genders",
      serviceAvailableForAgeGroup: "all_age_groups",
      description: "",
      venueDetail: "",
    });
  };

  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    p: 2,
    border: 1,
    borderRadius: 3,
    borderColor: "rgba(0, 0, 0, 0.04)",
    // borderColor: formErrors["serviceName"] ? "#d32f2f" : "#CCCCCC",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    minWidth: 696,
    maxWidth: 696,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  const formSelectClasses = {
    sx: {
      "& .MuiMenu-paper": {
        border: 1,
        borderColor: "#424242",
        borderRadius: 3,
        maxHeight: 250,
        mt: 1,
      },
      "&:hover, &.Mui-focused": {
        border: 1,
        borderColor: "#7E00DD"
      },
    },
  };

  return (
    <Stack direction={"column"} className={"py-6 px-8"} spacing={2}>
      <Dropzone
        accept="image/png, image/jpeg"
        noDrag={true}
        onDrop={async (files) => {
          const errors = {};
          if (files[0]) {
            setImageProgress(Math.floor((Math.random() * 30) + 1));
            const file = await handleUploadImageToS3(files[0]);
            setImageProgress(100);
            if (file.result.ok) {
              setFormValues(prevValues => ({
                ...prevValues,
                servicePhoto: file.location,
              }))
            }
            setImageProgress(100);
          } else {
            setFormValues(prevValues => ({
              ...prevValues,
              servicePhoto: "",
            }))
            errors.servicePhoto = "Only PNG and JPG formats are allowed";
          }
          setFormErrors({ ...errors });
        }}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div
              {...getRootProps({ className: "dropzone" })}
              className={"max-h-[165px]"}
            >
              <div
                className={`p-2 border-dashed border-2 min-w-[696px] max-w-[696px] min-h-[160px] rounded-xl flex flex-col justify-center items-center ${formValues.servicePhoto ? "border-0" : "bg-white"
                  } ${formErrors.servicePhoto ? "border-red-500" : ""}`}
              >
                <div className={`grid grid-cols-2 w-full`}>
                  {formValues.servicePhoto ? (
                    <img
                      className={
                        // "min-w-[696px] max-w-[696px] min-h-[160px] max-h-[160px] rounded-xl object-cover"
                        "max-w-[320px] max-h-[160px] rounded-xl object-cover"
                      }
                      src={formValues.servicePhoto}
                      alt={`Your ${title} Cover`}
                    />
                  ) : (<img
                    className={
                      // "min-w-[696px] max-w-[696px] min-h-[160px] max-h-[160px] rounded-xl object-cover"
                      "max-w-[354px] max-h-[160px] rounded-xl object-cover"
                    }
                    src={defaultImage}
                    alt={`Your ${title} Cover`}
                  />)}
                  <Box className="flex flex-col justify-center items-center ">
                    <Box className="flex justify-center items-center w-4/5">
                      <Box className="flex-none">
                        <img
                          className={""}
                          src={uploadImage}
                          alt={""}
                        />
                      </Box>
                      <Box className="flex-1 pl-2">
                        <div className="text-[#33BE97]">Click here </div>
                        <div>
                          to upload Image
                        </div>
                      </Box>
                    </Box>
                    <Box className="w-full" sx={{ color: "#33BE97" }}>
                      <LinearProgressWithLabel color="progress" variant="determinate" value={imageProgress} />
                    </Box>
                  </Box>
                </div>
              </div>
              <input {...getInputProps()} />
            </div>
          );
        }}
      </Dropzone>
      {formErrors["servicePhoto"] && (
        <FormHelperText error={formErrors?.servicePhoto != ""}>{formErrors["servicePhoto"]}</FormHelperText>
      )}
      <Stack direction={"column"} spacing={2}>
        <Stack spacing={2}>
          <FormControl error={formErrors["serviceName"] ? true : false}>
            <FormLabel sx={formLableStyle}>Service name</FormLabel>
            <InputBase
              name={"serviceName"}
              // placeholder={"Service name"}
              value={formValues.serviceName}
              inputProps={{ maxLength: 30 }}
              onChange={handleFormValueChange}
              classes={formInputClasses}
              sx={formInputStyle}
            />
            {formErrors["serviceName"] && (
              <FormHelperText>{formErrors["serviceName"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={formErrors["serviceType"] ? true : false}>
            <FormLabel sx={formLableStyle}>Service type</FormLabel>
            <Select
              disableunderline="true"
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  {/* <img src={select_start_adornment} alt={""} /> */}
                  {value.name || (
                    <span className={"font-light text-plain-gray"}>
                      Select Service type
                    </span>
                  )}
                </div>
              )}
              value={formValues.serviceType}
              onChange={(event) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  serviceType: event.target.value,
                  categories: "",
                }));
                if (event.target.value.category) {
                  setCategoryOpts(event.target.value.category);
                }
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
              }}
            >
              {eventcategoryOpts.map((categorygroup, idx) => (
                categorygroup.category.map((category, idx) => (
                  <MenuItem value={category} key={idx}>
                    {category.name}
                  </MenuItem>
                ))
              ))}
            </Select>
            {formErrors["serviceType"] && (
              <FormHelperText>{formErrors["serviceType"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={formErrors["categories"] ? true : false}>
            <FormLabel sx={formLableStyle}>Category</FormLabel>
            <Select
              disableunderline="true"
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  {/* <img src={select_start_adornment} alt={""} /> */}
                  {value.name || (
                    <span className={"font-light text-plain-gray"}>
                      Select Category
                    </span>
                  )}
                </div>
              )}
              value={formValues.categories}
              onChange={(event) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  categories: event.target.value,
                }));
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
              }}
            >
              {categoryOpts.map((category, idx) => (
                <MenuItem value={category} key={idx}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {formErrors["categories"] && (
              <FormHelperText>{formErrors["categories"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={formErrors["serviceAvailableFor"] ? true : false}>
            <FormLabel sx={formLableStyle}>Service available for </FormLabel>
            <Select
              disableunderline="true"
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  {value !== "" ? value.replace(/_/g, " ").replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  }) : (
                    <span className={"font-light text-plain-gray"}>
                      Select Service available for...
                    </span>
                  )}
                </div>
              )}
              value={formValues.serviceAvailableFor}
              onChange={(event) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  serviceAvailableFor: event.target.value,
                }));
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
              }}
            >
              {serviceAvailableForOpts.map((option, idx) => (
                <MenuItem value={option} key={idx}>
                  {option.replace(/_/g, " ").replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  })}
                </MenuItem>
              ))}
            </Select>
            {formErrors["serviceAvailableFor"] && (
              <FormHelperText>{formErrors["serviceAvailableFor"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={formErrors["serviceAvailableForAgeGroup"] ? true : false}>
            <FormLabel sx={formLableStyle}>Service available for age group</FormLabel>
            <Select
              disableunderline="true"
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  {value !== "" ? value.replace(/_/g, " ").replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  }) : (
                    <span className={"font-light text-plain-gray"}>
                      Select Service available for age group...
                    </span>
                  )}
                </div>
              )}
              value={formValues.serviceAvailableForAgeGroup}
              onChange={(event) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  serviceAvailableForAgeGroup: event.target.value,
                }));
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
              }}
            >
              {serviceAvailableForAgeGroupOpts.map((option, idx) => (
                <MenuItem value={option} key={idx}>
                  {option.replace(/_/g, " ").replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  })}
                </MenuItem>
              ))}
            </Select>
            {formErrors["serviceAvailableForAgeGroup"] && (
              <FormHelperText>{formErrors["serviceAvailableForAgeGroup"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={formErrors["description"] ? true : false}>
            <FormLabel sx={formLableStyle}>Description of the service</FormLabel>
            <InputBase
              name={"description"}
              placeholder={
                "Add description about this class and what would participants of this class would experience..."
              }
              value={formValues.description}
              onChange={handleFormValueChange}
              multiline
              classes={formInputClasses}
              sx={{
                ...formInputStyle,
                minHeight: 116,
                maxHeight: 124,
                overflowY: "auto",
                flexWrap: "wrap",
              }}
            />
            {formErrors["description"] && (
              <FormHelperText>{formErrors["description"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={formErrors["venueDetail"] ? true : false}>

            <FormLabel sx={formLableStyle}>Available at Location (s)</FormLabel>
            <Select
              disableunderline="true"
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  {value === "Select Location" ? (
                    <span className={"font-light text-plain-gray"}>
                      Select Location
                    </span>
                  ) : (
                    value.address.address
                  )}
                </div>
              )}
              name={"durationstring"}
              value={formValues.venueDetail || "Select Location"}
              onChange={(event) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  venueDetail: event.target.value,
                }));
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
              }}
            >
              {venueList.map((venue, idx) => (
                <MenuItem value={venue} key={idx}>
                  <Box className="flex flex-col gap-1">
                    <Typography variant="body1">{venue.venueName}</Typography>
                    <Typography variant="caption">{venue.address.address}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
            {formErrors["venueDetail"] && (
              <FormHelperText>{formErrors["venueDetail"]}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      </Stack>
      <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}>
        <Button
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          variant={"contained"}
          disabled={isSubmitting}
          onClick={() => {
            if (edit) {
              handleUpdate();
            } else {
              handleSubmit();
            }
          }}
        >
          {edit ? "Update" : "Coninue"}
        </Button>
      </Stack>

      <WarningDialog
        open={openWarningDialog}
        text={"To create a service you need to add a venue first. Do you want to add a venue?"}
        onClose={() => {
          setOpenWarningDialog(false)
          navigate("/myservices");
        }}
        onSubmit={async () => {
          setOpenWarningDialog(false)
          navigate("/myvenue/add", {
            state: {
              from: "list",
            },
          });
        }}
      />
    </Stack>
  );
}
