import React from "react";
import axios from "axios";
import { Popover } from "@mui/material";

import { useLocalStorage } from "../hooks";

import bell_png from "../assets/bell.png";
import NotificationItem from "./NotificationItem";
import { getUrlForUsers } from "../utils/confighelpers";

export default function Notifications() {
  const [userToken] = useLocalStorage("userToken");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifList, setNotifList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const config = {
        method: "get",
        url: `${getUrlForUsers()}notificationsList?page=${0}&limit=${20}&sortBy=${"asc"}`,
        headers: {
          "x-access-token": userToken,
        },
      };
      const request = await axios(config);
      if (request.status === 200) {
        if (request.data.statusCode === 200) {
          setNotifList([...request.data.data.notificationList]);
        }
      }
    })();
  }, [userToken]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  return (
    <div>
      <div className={"indicator cursor-pointer"} onClick={handleOpen}>
        <img className={"w-6 sm:w-5 md:w-5 sm:mx-1"} src={bell_png} alt={""} />
        <span
          className={
            "indicator-item badge bg-portland-orange border-0 -translate-y-[0.1rem] translate-x-1 badge-xs sm:-translate-y-0 sm:translate-x-0"
          }
        />
      </div>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            px: 4,
            py: 2,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "visible",
          },
        }}
      >
        <h3
          className={
            "font-semibold text-light-brown-coffee text-[24.05px] mb-4"
          }
        >
          Notifications
        </h3>
        {notifList.map((data) => (
          <NotificationItem
            key={data.notificationId}
            imgSrc={data.senderInfo.profilePic}
            displayText={data.notificationBody}
            personName={data.senderInfo.fullName}
            timeElapsed={data.createdAt}
          />
        ))}
      </Popover>
    </div>
  );
}
