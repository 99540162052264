import { Box, Button, Drawer, Rating, Stack, useMediaQuery } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../hooks";
import * as dashboardActions from "../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../redux/reducers/dashboardReducer";

import NeewSidebar from "../../components/NewSideBar";
import {
  LoadingBackdrop
} from "../../layouts";
import GeneralTab from "./action/GeneralTab";
import PoliciesTab from "./action/PoliciesTab";
import PricingTab from "./action/PricingTab";
import PassesTab from "./action/PassesTab";
import ScheduleTab from "./action/schedule/ScheduleTab";

import { minutesToDuration } from "../../helpers";
import { schoolState } from "../../redux/reducers/schoolReducer";


import Link from "react-scroll/modules/components/Link";
import defaultImage from "../..//assets/defaultImage.svg";
import cancel_img from "../../assets/Cancel Pay.svg";
import charetleft from "../../assets/charetleft.svg";
import clock_png from "../../assets/Clock.png";
import copyOrange from "../../assets/copyOrange.svg";
import info_i from "../../assets/i.svg";
import invite_img from "../../assets/Invite Code.svg";
import payment from "../../assets/Payment.svg";
import shareOrange from "../../assets/shareOrange.svg";
import copy_squares from "../../assets/square.on.square.svg";
import StatBox from "./view/StatBox";
import { userState } from "../../redux/reducers/userReducer";
import { getUrlForClasses, getUrlForStage } from "../../utils/confighelpers";

const popperModifiers = { placement: "bottom" };

export default function ClassDetails({ navigation, profileFor }) {
  const [userToken] = useLocalStorage("userToken");
  if ((userToken === undefined || userToken == "")) { window.location.href = "/"; }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dashboardReduxState = useSelector(dashboardState);
  const userReduxState = useSelector(userState);
  const [loading, setLoading] = React.useState(false);
  const [successMgModalOpen, setSuccessMgModalOpen] = React.useState(false);
  const [tabCurrentState, setTabCurrentState] = React.useState('1');
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  // console.log("dashboardReduxState.calendarSessionData", dashboardReduxState.calendarSessionData)

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  // const title = " Add New " + (location.state ? location.state.title : "")
  const title = " Edit " + profileFor.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  })
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabCurrentState(newValue);
  };

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const sessionDataToDisplay = dashboardReduxState.calendarSessionData;
  const classInfo = sessionDataToDisplay.classId
  const edit = null;
  const editData = {};
  const durationObj = minutesToDuration(classInfo.duration);
  const classBodyData = {
    classId: classInfo._id,
    photo: classInfo.classPhoto,
    eventCategory: {},
    categories: {},
    className: classInfo.className,
    tagLine: classInfo.tagLines,
    classDescription: classInfo.description,
    price: classInfo.cost,
    seats: classInfo.capacity,
    duration: durationObj,
    durationstring: `${durationObj.hours} hr ${durationObj.mins} min`,
    sessions: "",
    whatToCarry: classInfo.whatToCarry,
    freeCancellation: classInfo.classCancelationPolicy?.isEnabled ? true : false,
    cancellationPeriod: classInfo.classCancelationPolicy?.minutes === 60
      ? "1hr"
      : classInfo.classCancelationPolicy?.minutes === 1440
        ? "24hrs"
        : classInfo.classCancelationPolicy?.minutes === 2880
          ? "48hrs"
          : classInfo.classCancelationPolicy?.minutes === 10080
            ? "1 week"
            : "",
    inviteCodeOnlyBooking: classInfo.isPrivate ? true : false,
    paymentAtVenue: classInfo.paymentAtVenue ? classInfo.paymentAtVenue : false,
    isEvent: false,
    eventPricingList: classInfo.eventPricingList ? classInfo.eventPricingList : [],
    multiSessionPassList: classInfo.multiSessionPassList ? classInfo.multiSessionPassList : [],
  };
  const [formValues, setFormValues] = React.useState(classBodyData);
  const [formScheduleValues, setFormScheduleValues] = React.useState({});
  const [defaultScheduleData, setDefaultScheduleData] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [catRefEl, setCatRefEl] = React.useState();
  const [catPopperEl, setCatPopperEl] = React.useState();
  const catPopper = usePopper(catRefEl, catPopperEl, popperModifiers);
  const [sesRefEl, setSesRefEl] = React.useState();
  const [sesPopperEl, setSesPopperEl] = React.useState();
  const sesPopper = usePopper(sesRefEl, sesPopperEl, popperModifiers);
  const [durRefEl, setDurRefEl] = React.useState();
  const [durPopperEl, setDurPopperEl] = React.useState();
  const durPopper = usePopper(durRefEl, durPopperEl, popperModifiers);
  const [seatsRefEl, setSeatsRefEl] = React.useState();
  const [seatsPopperEl, setSeatsPopperEl] = React.useState();
  const seatsPopper = usePopper(seatsRefEl, seatsPopperEl, popperModifiers);
  const schoolReduxState = useSelector(schoolState);
  const [showGeneralDrawer, setShowGeneralDrawer] = React.useState(false);
  const [showPoliciesDrawer, setShowPoliciesDrawer] = React.useState(false);
  const [isDescExpanded, setDescExpanded] = React.useState(false);
  const [isWhatToCarryExpanded, setWhatToCarryExpanded] = React.useState(false);

  const appUrl = classInfo?.appUrl ? classInfo?.appUrl : "https://www.devshop.bookzyme.com/";
  const bookingLink = appUrl + (classInfo?.bookingLink ? classInfo?.bookingLink : "");


  const defaultDataSaveProgress = {
    isStartSubmiting: false,
    isButtonShow: true,
  };
  const [dataSaveProgress, setDataSaveProgress] = React.useState(defaultDataSaveProgress);

  const handleUpdate = async (isPublish = true) => {
    setSubmitting(true);
    cogoToast.loading("Loading...");
    setLoading(true);
    console.log("formValues", formValues);
    const errors = {};
    if (!formValues.photo) {
      errors.photo = "Required!";
    }
    if (!Object.keys(formValues.eventCategory).length) {
      errors.eventCategory = "Required!";
    }
    if (!Object.keys(formValues.categories).length) {
      errors.categories = "Required!";
    }
    if (!formValues.className) {
      errors.className = "Required!";
    }
    if (!formValues.classDescription) {
      errors.classDescription = "Required!";
    } else if (formValues.classDescription.length < 5) {
      errors.classDescription = `Enter at least 5 letters to explain your ${title}`;
    }
    if (!formValues.duration) {
      errors.duration = "Required";
    }
    /* if (title === "Course" && !formValues.sessions) {
      errors.sessions = "Required!";
    } */
    if (formValues.freeCancellation) {
      if (!formValues.cancellationPeriod) {
        errors.cancellationPeriod = "Required!";
      }
    }
    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      setSubmitting(false);
      // cogoToast.error("Please fix errors", { hideAfter: 3 });
      return;
    }

    const durationSplit = formValues.durationstring.split(" ");
    const durationInNum =
      Number(durationSplit[0]) * 60 + Number(durationSplit[2]);

    const cancelationPolicy =
      formValues.cancellationPeriod === "1hr"
        ? { isEnabled: true, text: "1 hour", minutes: 60 }
        : formValues.cancellationPeriod === "24hrs"
          ? { isEnabled: true, text: "24 hours", minutes: 1440 }
          : formValues.cancellationPeriod === "48hrs"
            ? { isEnabled: true, text: "48 hours", minutes: 2880 }
            : formValues.cancellationPeriod === "1 week"
              ? { isEnabled: true, text: "1 week", minutes: 10080 }
              : null;

    let body = {
      classId: formValues.classId,
      isPackage: profileFor === "course" ? true : false,
      eventCategory: formValues.eventCategory._id,
      catId: formValues.categories._id,
      schoolId: schoolReduxState.schoolId,
      classType: formValues.categories.name,
      className: formValues.className,
      description: formValues.classDescription,
      cost: formValues.price,
      tagLines: formValues.tagLine,
      capacity: formValues.seats,
      duration: durationInNum,
      whatToCarry: formValues.whatToCarry,
      classPhoto: formValues.photo,
      ...(profileFor === "course" && { sessionInPackage: formValues.sessions }),
      isPrivate: formValues.inviteCodeOnlyBooking,
      classCancelationPolicy: formValues.freeCancellation
        ? cancelationPolicy
        : { isEnabled: false },
      paymentAtVenue: formValues.paymentAtVenue,
      isEvent: formValues.isEvent,
      eventPricingList: formValues.eventPricingList,
      multiSessionPassList: formValues.multiSessionPassList,
      isPublic: isPublish,
    };

    if (!edit) {
      //add
    } else {
      body = {
        classId: editData._id,
      };
    }
    let config = {};

    config = {
      method: "put",
      url: `${getUrlForClasses()}updateSetClass`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };

    console.log("saveClass", config);
    setDataSaveProgress((prevValues) => ({
      ...prevValues,
      isStartSubmiting: true,
    }));
    const request = await axios(config);

    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        let classData = request.data.data;
        setShowGeneralDrawer(false)
        setShowPoliciesDrawer(false)
        fetchScheduleDetails();
        setSubmitting(false);
        setLoading(false);
        setDataSaveProgress((prevValues) => ({
          ...prevValues,
          isStartSubmiting: false,
        }));

        /** **/
        return;
      } else if (request.data.statusCode === 403) {
        cogoToast.error(request.data.message);
        setLoading(false);
        setSubmitting(false);
      }
    } else {
      setLoading(false);
    }
  };
  const handleCancel = async (isPublish = true) => {

  };
  const clearFormValues = () => {
    setFormValues(classBodyData);
    setFormScheduleValues({});
  };

  const fetchScheduleDetails = async () => {
    setLoading(true);
    const isPckg = sessionDataToDisplay.isPackage;
    const config = {
      method: "get",
      url: `${getUrlForClasses()}listOfClasses?isPackage=false&classId=${classInfo._id}&listFor=${userReduxState.businessType}`,
      // url: `${getUrlForClasses()}getScheduleDetail?scheduleclassId=${sessionDataToDisplay._id}&isPackage=${isPckg}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        dispatch(dashboardActions.setCalendarSessionId(request.data.data._id));
        dispatch(dashboardActions.setCalendarSessionData({ classId: request.data.data, allClassSchedules: request.data.data.allClassSchedules }));
        // dispatch(dashboardActions.setCalendarSessionData(request.data.data));
      }
    }
    setLoading(false);
  };
  const onBackClick = () => {
    navigate("/myclasses", { replace: true });
  };

  React.useEffect(() => {
    setDataSaveProgress(defaultDataSaveProgress);
    fetchScheduleDetails();
  }, []);
  return (
    <NeewSidebar
      navBarTitleComp={

        <h3 className={"font-extrabold text-2xl text-brown-coffee content-center"}>
          <img src={charetleft} alt="" className="inline align-baseline mr-8 cursor-pointer" onClick={() => {
            onBackClick();
          }} />{classInfo.className}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"
        >
          <Box sx={{ width: '100%', typography: 'body1' }}>

            <Box className="flex flex-col w-full">

              <Box className="grid grid-cols-2 w-full py-2 px-3">
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                  <Stack direction={"column"} spacing={3}>
                    <div className={"w-full h-[283px] flex justify-center"}>
                      {classInfo.classPhoto ? (
                        <img
                          className={
                            // "w-full h-[283px] object-cover object-fill rounded-2xl"
                            "h-[283px] rounded-2xl"
                          }
                          src={classInfo.classPhoto}
                          alt={""}
                        />
                      ) : (<img
                        className={
                          "w-full h-[283px] object-cover rounded-2xl"
                        }
                        src={defaultImage}
                        alt={""}
                      />)}
                    </div>
                  </Stack>
                </Stack>
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                  <Box className={`grid grid-cols-1 w-full`}>
                    <Box className="flex flex-col justify-center items-left ">

                      <Box className="flex justify-center items-center w-full py-3">
                        <Box className="flex flex-col justify-start w-full mt-5">
                          <p className={"text-[16px] font-extrabold"}>Share Class Link</p>
                          <Box className="flex justify-center items-center w-full mt-5"
                            sx={{
                              backgroundColor: "#F4F4F4",
                              border: 1,
                              borderColor: "rgba(0, 0, 0, 0.14)",
                              borderRadius: "12px",
                            }}>
                            <Box className="flex-1 pl-2 text-[#000000] my-0 mx-5 text-ellipsis overflow-hidden cursor-pointer break-all"
                              sx={{ maxWidth: "475px", maxHeight: "25px" }}
                              onClick={() => {
                                navigator.clipboard.writeText(bookingLink);
                                cogoToast.success("Copied!");
                              }}
                            >
                              {bookingLink}
                            </Box>
                            <Box className="flex flex-none bg-brown-coffee py-2 px-8"
                              sx={{
                                borderTopRightRadius: "12px",
                                borderBottomRightRadius: "12px",
                              }}>
                              <Link className="cursor-pointer" component="button" underline="none" onClick={() => {
                                navigator.clipboard.writeText(bookingLink);
                                cogoToast.success("Copied!");
                              }}>
                                <img
                                  className={"w-5"}
                                  src={copyOrange}
                                  alt={""}
                                />
                              </Link>
                              <Link className="cursor-pointer" component="button" underline="none" target="_blank" onClick={() => {
                                window.open(bookingLink, '_blank');
                              }}>
                                <img
                                  className={"w-5 ml-3 mr-0"}
                                  src={shareOrange}
                                  alt={""}
                                />
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="flex justify-center items-center py-3">
                        <Box className="flex-1">
                          <StatBox {...{
                            title: "Revenue",
                            data: classInfo?.totalBookings?.totalRevenue ? classInfo?.totalBookings?.totalRevenue : 0,
                            bgColor: "bg-lilac",
                            icon: clock_png,
                            update: "",
                          }} />
                        </Box>
                        <Box className="flex-1 pl-2">
                          <StatBox {...{
                            title: "Seats Booked",
                            data: classInfo?.totalBookings?.totalConfirmedBooking ? classInfo?.totalBookings?.totalConfirmedBooking : 0,
                            bgColor: "bg-portland-orange",
                            icon: clock_png,
                            update: "",
                          }} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </Box>
              <Box className="grid grid-cols-1 w-full py-2 px-7">
                <div className="p-4 pt-3 pb-10 border-2 border-[#EBEBEB] hover:border-[#7E00DD] rounded-lg">
                  <Stack direction={"column"} spacing={3}>

                    <Stack direction={"row"} alignItems={"center"}>
                      <div className={"flex w-full"}>
                        <div className={"flex-1"}>
                          <h2 className={"font-bold text-[30px] text-brown-coffee"}>
                            {classInfo.className}
                          </h2>
                          <p className={"text-[15.35px]"}>
                            {classInfo.tagLines}
                          </p>
                          <div
                            className={
                              "min-w-[165px] pt-2 flex items-left bg-white rounded-lg"
                            }
                          >
                            <Stack direction={"row"} spacing={1}>
                              <Box className="pt-[2px]">
                                <Rating
                                  sx={{
                                    color: "#FFA800",
                                    fontSize: 17,
                                  }}
                                  value={
                                    Array.isArray(classInfo.rating) ? (classInfo.rating.length ? classInfo.rating[0] : 0) : classInfo.rating
                                  }
                                  precision={0.5}
                                  readOnly
                                />
                              </Box>
                              <p className={"text-sm text-[#FFA800] bg-[#F4F4F4] p-[2px]"}>
                                {`${Array.isArray(classInfo.rating) ? (classInfo.rating.length ? classInfo.rating[0] : 0) : classInfo.rating}`}
                              </p>
                            </Stack>
                          </div>
                          <span className={"rounded-lg py-0.5 px-1 text-[12px] bg-[#323232] text-[#FFF]"}> {(classInfo.duration > 60) ? `${durationObj.hours} hr ${durationObj.mins} min` : `${classInfo.duration} minutes`}</span>
                          <p className={"text-[15.35px] text-[#FF4040]"}> {(sessionDataToDisplay?.eventStatus && [/* 'Draft', 'Published',  */'cancelled', 'refunded'].indexOf(sessionDataToDisplay?.eventStatus?.toLowerCase()) > -1) ? "This Class instance is cancelled" : ""}</p>

                        </div>
                        <div>
                          <Button
                            variant={"contained"}
                            className="place-items-end"
                            sx={{
                              width: 152,
                              height: 48,
                              borderRadius: 3,
                              textTransform: "none",
                              backgroundColor: "secondary.mainLight",
                              mt: 1,
                            }}
                            onClick={() => {
                              setShowGeneralDrawer(true);
                            }}
                          >
                            Edit Basic Info
                          </Button>
                        </div>
                      </div>
                    </Stack>

                    <div className="mt-5">
                      <h4 className={"font-bold text-lg text-brown-coffee"}>
                        Description
                      </h4>
                      <p className={"font-light text-base"}>
                        {classInfo.description?.length > 175 ? (
                          !isDescExpanded ? (
                            <>
                              {classInfo.description.slice(0, 175)}
                              <span
                                className={
                                  "text-gray-400 font-light text-base cursor-pointer"
                                }
                                onClick={() => setDescExpanded(true)}
                              >
                                ...see more
                              </span>
                            </>
                          ) : (
                            <span>
                              {classInfo.description}
                              <br />
                              <span
                                className={
                                  "text-gray-400 font-light text-base cursor-pointer"
                                }
                                onClick={() => setDescExpanded(false)}
                              >
                                see less
                              </span>
                            </span>
                          )
                        ) : (
                          <span>{classInfo.description}</span>
                        )}
                      </p>
                    </div>
                    <div>
                      <h4 className={"font-bold text-lg text-brown-coffee"}>
                        What To Carry
                      </h4>
                      <p className={"font-light text-base"}>
                        {classInfo.whatToCarry?.length > 175 ? (
                          !isWhatToCarryExpanded ? (
                            <>
                              {classInfo.whatToCarry.slice(0, 175)}
                              <span
                                className={
                                  "text-gray-400 font-light text-base cursor-pointer"
                                }
                                onClick={() => setWhatToCarryExpanded(true)}
                              >
                                ...see more
                              </span>
                            </>
                          ) : (
                            <span>
                              {classInfo.whatToCarry}
                              <br />
                              <span
                                className={
                                  "text-gray-400 font-light text-base cursor-pointer"
                                }
                                onClick={() => setWhatToCarryExpanded(false)}
                              >
                                see less
                              </span>
                            </span>
                          )
                        ) : (
                          <span>{classInfo.whatToCarry}</span>
                        )}
                      </p>
                    </div>

                  </Stack>
                </div>
                <Stack direction={"column"} spacing={5} className="mt-2">
                  <div className="mt-10 p-4 pt-5 pb-10 border-2 border-[#EBEBEB] hover:border-[#7E00DD] rounded-lg grid grid-cols-1 gap-6">
                    <div>
                      <div className={"flex w-full"}>
                        <div className={"flex-1"}>
                          <h4 className={"font-bold text-lg text-brown-coffee"}>
                            Cancelation Policy
                          </h4>
                          {classInfo?.classCancelationPolicy?.isEnabled ? (
                            <p className={"font-light text-base"}>
                              Cancelation is free in case booking is cancelled{" "}
                              <span className={"font-bold"}>
                                {classInfo.classCancelationPolicy.text}
                              </span>{" "}
                              before start of the{" "}
                              {classInfo.isPackage ? "course" : "class"}.
                            </p>
                          ) : (
                            <p className={"font-light text-base"}>
                              No Cancelation Policy available.
                            </p>
                          )}
                        </div>
                        <div>
                          <Button
                            variant={"contained"}
                            className="place-items-end"
                            sx={{
                              width: 152,
                              height: 48,
                              borderRadius: 3,
                              textTransform: "none",
                              backgroundColor: "secondary.mainLight",
                            }}
                            onClick={() => {
                              setShowPoliciesDrawer(true);
                            }}
                          >
                            Edit Policies
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h4 className={"font-bold text-lg text-brown-coffee"}>
                        Pay at Venue
                      </h4>
                      {classInfo?.classCancelationPolicy?.isEnabled ? (
                        <p className={"font-light text-base"}>
                          Pay at venue is {(classInfo?.paymentAtVenue || (classInfo.paymentAtVenue && classInfo.paymentAtVenue === false)) ? "not " : ""}available for this class.
                        </p>
                      ) : (
                        <p className={"font-light text-base"}>
                          No Cancelation Policy available.
                        </p>
                      )}
                    </div>
                    {classInfo?.bookingCode ? (
                      <div>
                        <h4 className={"font-bold text-lg text-brown-coffee"}>
                          Booking Only by invite code
                        </h4>
                        <p className={"font-light text-base flex items-center gap-2"}>
                          Invite Code: <b>{classInfo?.bookingCode || ""}</b>
                          <span className={"inline-block items-center cursor-pointer"}
                            onClick={async () => {
                              try {
                                await navigator.clipboard.writeText(classInfo?.bookingCode);
                                cogoToast.success("Copied!");
                              } catch (e) {
                                cogoToast.error("Failed to copy!");
                              }
                            }}>
                            <img src={copy_squares} alt={"Copy"} title={"Copy"} />
                          </span>
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-10 p-4 pt-5 border-2 border-[#EBEBEB] hover:border-[#7E00DD] rounded-lg">
                    <Box>
                      <Box className="items-left w-full text-brown-coffee" sx={{ fontSize: 25 }}>
                        Class Pricing
                      </Box>
                      <Box
                        sx={{
                          // backgroundColor: "#F4F4F4",
                          borderRadius: "15px",
                        }}>
                        <PricingTab
                          edit={true}
                          setLoading={setLoading}
                          profileFor={profileFor}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          setTabCurrentState={setTabCurrentState}
                          handleUpdate={handleUpdate}
                          isFromDetail={true}
                        />
                      </Box>
                    </Box>
                  </div>
                  <div className="mt-10 p-4 pt-5 border-2 border-[#EBEBEB] hover:border-[#7E00DD] rounded-lg">
                    <Box>
                      <Box className="items-left w-full text-brown-coffee" sx={{ fontSize: 25 }}>
                        Multi-session Passes
                      </Box>
                      <Box
                        sx={{
                          // backgroundColor: "#F4F4F4",
                          borderRadius: "15px",
                        }}>
                        <PassesTab
                          edit={true}
                          setLoading={setLoading}
                          profileFor={profileFor}
                          formValues={formValues}
                          classInfo={dashboardReduxState.calendarSessionData.classId}
                          setFormValues={setFormValues}
                          setTabCurrentState={setTabCurrentState}
                          handleUpdate={handleUpdate}
                          isFromDetail={true}
                        />
                      </Box>
                    </Box>
                  </div>
                  <div className="mt-10 p-4 pt-5 border-2 border-[#EBEBEB] hover:border-[#7E00DD] rounded-lg">
                    <Box>
                      <Box
                        sx={{
                          // backgroundColor: "#F4F4F4",
                          borderRadius: "15px",
                        }}>
                        <ScheduleTab
                          edit={true}
                          reloadScheduleDetails={fetchScheduleDetails}
                          duration={formValues.duration}
                          classObj={formValues}
                          setLoading={setLoading}
                          formErrors={formErrors}
                          formScheduleValues={formScheduleValues}
                          setFormScheduleValues={setFormScheduleValues}
                          setTabCurrentState={setTabCurrentState}
                          defaultDataSaveProgress={defaultDataSaveProgress}
                          dataSaveProgress={dataSaveProgress}
                          setDataSaveProgress={setDataSaveProgress}
                          defaultScheduleData={defaultScheduleData}
                          setDefaultScheduleData={setDefaultScheduleData}
                          isFromDetail={true}
                        />
                      </Box>
                    </Box>
                  </div>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
        <Drawer
          ModalProps={{
            onBackdropClick: () => { },
            keepMounted: true,
          }}
          open={showGeneralDrawer}
          anchor="right"
        >
          <div
            className={"grid grid-rows-7 p-5"}
          >
            <GeneralTab
              profileFor={profileFor}
              title={title}
              openSuccessModal={() => setSuccessMgModalOpen(true)}
              edit={true}
              editData={null}
              setLoading={setLoading}
              formValues={formValues}
              setFormValues={setFormValues}
              setTabCurrentState={setTabCurrentState}
              handleUpdate={handleUpdate}
              handleCancel={() => {
                // alert("cancel");
                setShowGeneralDrawer(false)
              }}
            />
          </div>
        </Drawer>
        <Drawer
          ModalProps={{
            onBackdropClick: () => { },
            keepMounted: true,
          }}
          open={showPoliciesDrawer}
          anchor="right"
        >
          <div
            className={"grid grid-rows-7 p-5"}
          >

            <PoliciesTab
              edit={true}
              setLoading={setLoading}
              formErrors={formErrors}
              cancel_img={cancel_img}
              info_i={info_i}
              formValues={formValues}
              handleFormValueChange={handleFormValueChange}
              setFormValues={setFormValues}
              setTabCurrentState={setTabCurrentState}
              payment={payment}
              invite_img={invite_img}
              handleUpdate={handleUpdate}
              handleCancel={() => {
                setShowPoliciesDrawer(false)
              }}
              isFromDetail={true}
            />
            {/* <GeneralTab
              profileFor={profileFor}
              title={title}
              openSuccessModal={() => setSuccessMgModalOpen(true)}
              edit={true}
              editData={null}
              setLoading={setLoading}
              formValues={formValues}
              setFormValues={setFormValues}
              setTabCurrentState={setTabCurrentState}
              handleUpdate={handleUpdate}
              handleCancel={() => {
                // alert("cancel");
                setShowGeneralDrawer(false)
              }}
            /> */}
          </div>
        </Drawer>
      </LoadingBackdrop>} />
  );
}
