import {
    Button, FormControl, FormHelperText, FormLabel, IconButton, InputAdornment, InputBase, MenuItem, Select, Stack, Tooltip
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import React from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InitialsAvatar from 'react-initials-avatar';

import { handleUploadImageToS3 } from "../../../helpers";
import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import * as userActions from "../../../redux/reducers/userReducer";
import { userState } from "../../../redux/reducers/userReducer";

import cogoToast from "cogo-toast";
import add_img from "../../../assets/add_img_2.png";
import calender_black from "../../../assets/calender_black.svg";
import change_password from "../../../assets/change_password.svg";
import customer_name from "../../../assets/customer_name.svg";
import email_img from "../../../assets/Email.svg";
import mobile_icon from "../../../assets/mobile-icon.png";
import lock_img from "../../../assets/password.svg";
import { getUrlForUsers } from "../../../utils/confighelpers";

export default function ProfileForm({
    setLoading,
    setShowChangePassword
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userReduxState = useSelector(userState);
    const [formValues, setFormValues] = React.useState({
        ...userReduxState.userData,
        // gender: "",
        // dob: "",
        password: "..........",
        location: {},
        about: "",
    });
    const [formErrors, setFormErrors] = React.useState({});
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [openDOBDate, setOpenDOBDate] = React.useState(false);
    const [referenceElement, setReferenceElement] = React.useState();
    const [isThereChange, setIsThereChange] = React.useState(true);
    const [userToken] = useLocalStorage("userToken");

    React.useEffect(() => {
        setTimeout(() => {
            setIsThereChange(true)
        }, 1 * 1000);
    }, [])
    React.useEffect(() => {
        setIsThereChange(false)
    }, [formValues])
    const handleChangePic = async (url) => {
        setLoading(true);
        cogoToast.loading("Loading...").then(async () => {
            const result = await axios.put(
                `${getUrlForUsers()}updateProfile`,
                {
                    profilePic: url,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "x-access-token": userToken,
                    },
                }
            );
            if (result.data.statusCode === 200) {
                dispatch(
                    userActions.setUserData(
                        Object.assign({}, userReduxState.userData, { profilePic: url })
                    )
                );
                setFormValues((prevValues) => ({
                    ...prevValues,
                    profilePic: url,
                }));
                setIsThereChange(true)
            } else {
                cogoToast.error(result.data.message);
            }
        });
        setLoading(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        setSubmitting(true);

        const errors = {};
        if (!formValues.fullName) {
            errors.fullName = "Required!";
        }
        if (!formValues.email) {
            errors.email = "Required!";
        }
        /* if (!formValues.password) {
            errors.password = "Required!";
        }
        if (!formValues.gender) {
            errors.gender = "Required!";
        }
        if (!formValues.dob) {
            errors.dob = "Required";
        } */
        setFormErrors({ ...errors }); if (Object.keys(errors).length > 0) {
            setLoading(false);
            setSubmitting(false);
            cogoToast.error("Please fix errors below", { hideAfter: 3 });
            return;
        }

        let body = {
            fullName: formValues.fullName,
            // email: formValues.email,
            gender: formValues.gender,
            dob: formValues.dob,
        }
        console.log("body", body);
        cogoToast.loading("Loading...").then(async () => {
            const result = await axios.put(
                `${getUrlForUsers()}updateProfile`,
                {
                    ...body,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "x-access-token": userToken,
                    },
                }
            );
            setLoading(false);
            if (result.data.statusCode === 200) {
                dispatch(
                    userActions.setUserData(
                        Object.assign({}, userReduxState.userData, body)
                    )
                );
                setSubmitting(false);
            } else {
                cogoToast.error(result.data.message);
                setSubmitting(false);
            }
        });

        // setSubmitting(false);
        // setLoading(false);
    };

    const formLableStyle = {
        my: 1,
        color: "#32282F99"
    };

    const formInputStyle = {
        // m: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
        p: 2,
        borderRadius: 3,
        border: 1,
        borderColor: "#EBEBEB",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        // fontSize: isMobile ? 14 : isTablet ? 13 : 17,
        minWidth: 520,
        // maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
        "&:hover, &.Mui-focused": {
            border: 1,
            borderColor: "#7E00DD"
        },
        '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
            borderColor: 'rgba(0, 0, 0, 0)',
        },
    };

    const formInputClasses = {
        input: {
            "&::placeholder": {
                color: "#808080",
                fontWeight: 300,
            },
        },
    };

    const formSelectClasses = {
        sx: {
            "& .MuiMenu-paper": {
                border: 1,
                borderColor: "#424242",
                borderRadius: 3,
                maxHeight: 250,
                mt: 1,
            },
            "&:hover, &.Mui-focused": {
                border: 1,
                borderColor: "#7E00DD"
            },
        },
    };

    return (
        <div className={"flex flex-col p-3"}>

            <div className={"flex"}>
                <div className={"flex-1 my-12 w-full grow"}>
                    <Stack direction={"column"} spacing={1}>
                        <div className={"grid place-items-center mb-2"}>
                            <Dropzone
                                accept="image/png, image/jpeg"
                                noDrag={true}
                                onDrop={async (files) => {
                                    const errors = {};
                                    if (files[0]) {
                                        const file = await handleUploadImageToS3(files[0]);
                                        if (file.result.ok) {
                                            handleChangePic(file.location);
                                        }
                                    } else {
                                        // handleChangePic("https://bmcapp.s3.eu-west-1.amazonaws.com/assets/DefaultAvatar.png");
                                        errors.photo = "Only PNG and JPG formats are allowed";
                                    }
                                    setFormErrors({ ...errors });
                                }}
                            // getFilesFromEvent={async (files) => {
                            //   const file = await files[0].getFile();
                            //   const uploadedImageResponse = await handleUploadImageToS3(file);
                            //   if (uploadedImageResponse.result.ok) {
                            //     const publicUrl = uploadedImageResponse.location;
                            //     handleChangePic(publicUrl);
                            //   }
                            // }}
                            >
                                {({ getRootProps, getInputProps }) => {
                                    return (
                                        <div
                                            {...getRootProps({ className: "dropzone" })}
                                            className={""}
                                        >
                                            <div className={"ml-4 w-[171px] h-[161px] rounded-full"}>
                                                {formValues.profilePic ? (<img
                                                    className={
                                                        "min-w-[151px] h-[151px] object-cover rounded-full"
                                                    }
                                                    src={formValues.profilePic}
                                                    alt={""}
                                                />) : (
                                                    <InitialsAvatar name={formValues?.fullName} className={"initials-avatar w-[151px] h-[151px] bg-brown-coffee text-2xl"} />
                                                )}
                                            </div>
                                            <input {...getInputProps()} />
                                            <div className={"-translate-y-14 translate-x-28 w-14 h-0.5"}>
                                                <Tooltip title={"Upload A New You"}>
                                                    <IconButton component={"span"}>
                                                        <img src={add_img} alt={"add icon"} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                            {formErrors["photo"] && (
                                <FormHelperText error={formErrors?.photo != ""}>{formErrors["photo"]}</FormHelperText>
                            )}
                            <div style={{ display: "flex" }}>
                                <img className={"h-6"} src={mobile_icon} />
                                <p style={{ marginLeft: '15px' }}>{formValues.username}</p>
                            </div>
                        </div>
                        <FormControl error={formErrors["fullName"] ? true : false}>
                            <FormLabel sx={formLableStyle}>Name</FormLabel>
                            <InputBase
                                name={"fullName"}
                                value={formValues.fullName}
                                onChange={handleInputChange}
                                placeholder={"Full Name"}
                                startAdornment={
                                    <InputAdornment position={"start"}>
                                        <img className={"w-6"} src={customer_name} alt={""} />
                                    </InputAdornment>
                                }
                                classes={formInputClasses}
                                sx={formInputStyle}
                                fullWidth
                            />
                            {formErrors["fullName"] && (
                                <FormHelperText>{formErrors["fullName"]}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl error={formErrors["email"] ? true : false}>
                            <FormLabel sx={formLableStyle}>Email</FormLabel>
                            <InputBase
                                name={"email"}
                                value={formValues.email}
                                type={"email"}
                                onChange={handleInputChange}
                                placeholder={"Email Address"}
                                startAdornment={
                                    <InputAdornment position={"start"}>
                                        <img className={"w-6"} src={email_img} alt={""} />
                                    </InputAdornment>
                                }
                                classes={formInputClasses}
                                sx={formInputStyle}
                                fullWidth
                                disabled
                            />
                            {formErrors["email"] && (
                                <FormHelperText>{formErrors["email"]}</FormHelperText>
                            )}
                            {/* <FormHelperText>We will send you verification code to update your email</FormHelperText> */}
                        </FormControl>
                        <FormControl error={formErrors["password"] ? true : false}>
                            <FormLabel sx={formLableStyle}>Password</FormLabel>
                            <InputBase
                                ref={setReferenceElement}
                                name={"password"}
                                value={formValues.password}
                                onChange={handleInputChange}
                                type={passwordVisible ? "text" : "password"}
                                placeholder={"Enter Password"}
                                startAdornment={
                                    <InputAdornment position={"start"}>
                                        <img className={"w-6"} src={lock_img} alt={""} />
                                    </InputAdornment>
                                }
                                /* endAdornment={
                                    <InputAdornment position={"end"}>
                                        {passwordVisible ? (
                                            <IconButton
                                                onClick={() => setPasswordVisible(!passwordVisible)}
                                            >
                                                <Visibility style={{ color: "black" }} />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={() => setPasswordVisible(!passwordVisible)}
                                            >
                                                <VisibilityOff style={{ color: "black" }} />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                } */
                                classes={formInputClasses}
                                sx={formInputStyle}
                                fullWidth
                                disabled
                            />
                            {formErrors["password"] && (
                                <FormHelperText>{formErrors["password"]}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl error={formErrors["gender"] ? true : false}>
                            <FormLabel sx={formLableStyle}>Gender</FormLabel>
                            <Select
                                disableunderline="true"
                                startAdornment={
                                    <InputAdornment position={"start"}>
                                        <img className={"w-6"} src={customer_name} alt={""} />
                                    </InputAdornment>
                                }
                                renderValue={(value) => (
                                    <div className={"flex flex-row items-center gap-3"}>
                                        {value !== "Select Gender" ? value : (
                                            <span className={"font-light text-plain-gray"}>
                                                Select Gender
                                            </span>
                                        )}
                                    </div>
                                )}
                                name={"gender"}
                                value={formValues.gender || "Select Gender"}
                                onChange={handleInputChange}
                                MenuProps={formSelectClasses}
                                sx={{
                                    ...formInputStyle,
                                    py: 0.5,
                                }}
                            >
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                            {formErrors["gender"] && (
                                <FormHelperText>{formErrors["gender"]}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl error={formErrors["dob"] ? true : false}>
                            <FormLabel sx={formLableStyle}>Date of birth</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={(formValues.dob) ? formValues.dob : null}
                                    // value={null}
                                    onChange={(newValue) => {
                                        setFormValues((prevValues) => ({
                                            ...prevValues,
                                            dob: newValue,
                                        }));
                                    }}
                                    open={openDOBDate}
                                    onOpen={() => { setOpenDOBDate(true) }}
                                    onClose={() => { setOpenDOBDate(false) }}
                                    renderInput={(params) => {
                                        const { inputRef, inputProps, InputProps } = params;
                                        const { placeholder, classes, ...restinputProps } =
                                            inputProps;
                                        return (
                                            <InputBase
                                                ref={inputRef}
                                                {...restinputProps}
                                                placeholder={"Date of birth"}
                                                classes={formInputClasses}
                                                sx={{
                                                    ...formInputStyle,
                                                    borderColor: formErrors["startDate"]
                                                        ? "#d32f2f"
                                                        : "#EBEBEB",
                                                }}
                                                onClick={() => { setOpenDOBDate(!openDOBDate) }}
                                                startAdornment={
                                                    <InputAdornment position={"start"}>
                                                        <img className={"w-5"} src={calender_black} alt={""} />
                                                    </InputAdornment>
                                                }
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Stack>
                </div>
                <div className={"flex-none flex flex-row justify-between items-center pl-5"}>
                    <img className={"w-6 align-middle"} src={change_password} alt={""} onClick={() => { setShowChangePassword(true); }} style={{ display: "inline-block", position: "relative", top: (referenceElement?.offsetTop) ? referenceElement.scrollHeight + referenceElement.offsetHeight : 0 }} />
                </div>
            </div>
            <div className={"grid place-items-center"}>
                <div className={"flex flex-row items-center gap-2"}>
                    <Button
                        sx={{
                            color: "secondary.mainLight",
                            backgroundColor: "common.white",
                            borderColor: "secondary.mainLight",
                            border: 2,
                            boxShadow: "none",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "secondary.mainLight",
                                color: "common.white",
                                boxShadow: "none",
                            },
                            fontWeight: 600,
                            borderRadius: 3,
                            width: 231,
                            height: 52,
                            fontSize: 16,
                        }}
                        onClick={() => {
                            dispatch(dashboardActions.setCalendarSessionId(-1));
                            dispatch(dashboardActions.setCalendarOpen(false));
                            navigate("/home/dashboard");
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            color: "common.white",
                            backgroundColor: "primary.main",
                            boxShadow: "none",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "primary.main",
                                boxShadow: "none",
                            },
                            fontWeight: 600,
                            borderRadius: 3,
                            width: 231,
                            height: 52,
                            fontSize: 16,
                        }}
                        variant={"contained"}
                        disabled={isSubmitting || isThereChange}
                        onClick={handleSubmit}
                    >
                        Update
                    </Button>
                </div>
            </div>

        </div>
    );
}
