import { Box, Button, Stack } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import {
  addDays, format, getDay,
  getMinutes,
  isBefore, isSameDay, set, sub
} from "date-fns";
import moment from "moment";
import React from "react";
import { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { dateInArray, minutesToDuration } from "../../../../helpers";
import { useLocalStorage } from "../../../../hooks";

import AddScheduleStepper from "./AddScheduleStepper";
import SuccessScheduleModal from "./SuccessScheduleModal";

import * as dashboardActions from "../../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../../redux/reducers/dashboardReducer";

import EditSchedules from "./EditSchedules";
import { getUrlForClasses } from "../../../../utils/confighelpers";

export default function ScheduleTab({
  edit = false,
  duration,
  classObj,
  setLoading,
  formScheduleValues,
  handleFormValueChange,
  setFormScheduleValues,
  setTabCurrentState,
  defaultDataSaveProgress,
  dataSaveProgress,
  setDataSaveProgress,
  defaultScheduleData,
  setDefaultScheduleData,
  reloadScheduleDetails = () => null,
  isFromDetail = false
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userToken] = useLocalStorage("userToken");
  const dashboardReduxState = useSelector(dashboardState);
  const [displayDuration, setDisplayDuration] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = React.useState({
    radioValue: "One Time",
    radioValue2: "",
    startDate: "",
    endDate: "",
    startTime: "",
    location: {},
    daysInWeek: [],
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formResData, setFormResData] = React.useState({});
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [shareLink, setShareLink] = React.useState("");

  React.useEffect(() => {
    if (Object.keys(defaultScheduleData).length === 0) {
      setDefaultScheduleData(formValues);
    } else {
      setFormValues(defaultScheduleData);
    }
  }, []);


  React.useEffect(() => {
    setDefaultScheduleData(formValues);

    let time = duration;
    const { mins, hours } = minutesToDuration(time);
    const minutes = (mins) ? mins : 0;
    setDisplayDuration(`${moment((formValues.startTime) ? formValues.startTime : moment()).add({
      hour: hours,
      minute: minutes,
      seconds: 0,
    }).format("h:mm A")} (${hours} hr ${minutes} mins)`);
  }, [formValues]);

  const publishScheduledDetails = async (body, isPublished = true) => {
    setLoading(true)
    body.classId = classObj.classId;
    const config = {
      method: "post",
      url: `${getUrlForClasses()}scheduleTheClass`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    const request = await axios(config);
    setSubmitting(false);
    setLoading(false);
    if (request.data.statusCode !== 200) {
      cogoToast.error(request.data.message);
      return false;
    } else {
      if (isPublished) {
        body.isPublished = true;
        return await publishScheduledDetails(body, false);
      }
      cogoToast.success("Success!");
      return true;
    }
  }

  const handleNextStep = async () => {
    setSubmitting(true);
    const errors = {};
    if (formValues.radioValue2 !== "Custom" && !formValues.startDate) {
      errors.startDate = "Required!";
    }
    if (formValues.radioValue !== "One Time") {
      if (formValues.radioValue2 !== "Custom") {
        if (!formValues.endDate) {
          errors.endDate = "Required!";
        }
      }
    }
    if (formValues.radioValue !== "One Time" && formValues.radioValue2 !== "Custom") {
      if (formValues.endDate < formValues.startDate) {
        errors.endDate = "End Date has to be after Start Date";
      }
    }
    if (
      formValues.radioValue === "Recurrent" &&
      formValues.radioValue2 !== "Custom"
    ) {
      if (formValues.endDate < formValues.startDate) {
        errors.endDate = "End Date has to be after Start Date";
      }
    }
    if (!formValues.startTime) {
      errors.startTime = "Required!";
    }
    if (
      formValues.radioValue2 !== "Custom" &&
      isBefore(formValues.startDate, new Date())
    ) {
      errors.startDate = "Start Date must be in future";
    }
    if (!Object.keys(formValues.location).length) {
      errors.location = "Required!";
      cogoToast.error("Location is required!");
    }
    setFormErrors({ ...errors });

    if (Object.keys(errors).length > 0) {
      setLoading(false);
      cogoToast.error("Please fix errors below");
      console.log(errors);
      console.log(formValues);
      return;
    }

    let toDays = [];
    let sDays = [];
    let sortedDates = [];
    let dateWithTime = "";
    let startDateTime =
      formValues.radioValue2 !== "Custom" &&
      set(formValues.startTime, {
        year: Number(format(formValues.startDate, "yyyy")),
        month: Number(format(formValues.startDate, "M")),
        date: Number(format(formValues.startDate, "d")),
        // hours: Number(format(formValues.startTime, "k")),
        // minutes: Number(format(formValues.startTime, "m")),
        seconds: 0,
      });
    startDateTime =
      formValues.radioValue2 !== "Custom" && sub(startDateTime, { months: 1 });
    let endDateTime =
      formValues.radioValue !== "One Time" &&
      formValues.radioValue2 !== "Custom" &&
      set(formValues.startTime, {
        year: Number(format(formValues.endDate, "yyyy")),
        month: Number(format(formValues.endDate, "M")),
        date: Number(format(formValues.endDate, "d")),
        seconds: 0,
      });
    // endDateTime = addMinutes(endDateTime, duration);
    endDateTime =
      formValues.radioValue !== "One Time" &&
      formValues.radioValue2 !== "Custom" &&
      sub(endDateTime, { months: 1 });
    if (
      formValues.radioValue === "Recurrent" &&
      formValues.radioValue2 === "Daily"
    ) {
      let current = startDateTime;
      while (current <= endDateTime) {
        toDays.push(current.toISOString());
        current = addDays(current, 1);
      }
    }
    if (
      formValues.radioValue === "Recurrent" &&
      formValues.radioValue2 === "Custom"
    ) {
      sortedDates = [...formValues.daysInWeek].sort((a, b) => {
        return new Date(a) - new Date(b);
      });
      sortedDates.map((date) =>
        toDays.push(
          set(date, {
            hours: Number(format(formValues.startTime, "k")),
            minutes: Number(format(formValues.startTime, "m")),
            seconds: 0,
          })
        )
      );
    }
    if (
      formValues.radioValue === "Recurrent" &&
      formValues.radioValue2 === "Weekly"
    ) {
      dateWithTime =
        moment(formValues.startDate).format("MM/DD/YYYY") +
        "," +
        format(formValues.startTime, "K") +
        ":" +
        getMinutes(formValues.startTime) +
        " " +
        format(formValues.startTime, "aaa");
      console.log("dateWithTime", moment(dateWithTime));
      startDateTime = moment(formValues.startDate).set({
        hour: moment(formValues.startTime).hours(),
        minute: moment(formValues.startTime).minutes(),
        seconds: 0,
      });
      endDateTime = moment(formValues.endDate).set({
        hour: moment(formValues.startTime).hours(),
        minute: moment(formValues.startTime).minutes(),
        seconds: 0,
      });
      sDays = [...formValues.daysInWeek].map((day) => getDay(day));
      sDays = sDays.sort((a, b) => a - b);
    }

    const body = {
      isPublished: false,
      classId: classObj.id,
      className: classObj.className,
      startDate:
        formValues.radioValue === "Recurrent" &&
          formValues.radioValue2 === "Custom"
          ? toDays[0]
          : formValues.radioValue2 === "Weekly"
            ? moment(dateWithTime).toISOString()
            : format(formValues.startDate, "yyyy-MM-dd"),
      timing: format(formValues.startTime, "KK:mm aaa"),
      endAfter: "",
      meridiem: format(formValues.startTime, "a"),
      ...(formValues.radioValue === "Recurrent"
        ? { recurrenceType: formValues.radioValue2.toLowerCase() }
        : {}),
      endBy:
        formValues.radioValue === "Recurrent" &&
          formValues.radioValue2 === "Daily"
          ? endDateTime
          : formValues.radioValue2 === "Weekly"
            ? endDateTime
            : formValues.radioValue2 === "Custom"
              ? toDays[toDays.length - 1]
              : startDateTime,
      utcOffset: new Date().getTimezoneOffset(),
      ...(formValues.radioValue2 === "Weekly"
        ? { daysInWeek: [], selectedDays: sDays }
        : {
          daysInWeek:
            formValues.radioValue === "Recurrent" ? toDays : [startDateTime],
        }),
      classStartDateTime:
        formValues.radioValue === "Recurrent" &&
          formValues.radioValue2 === "Custom"
          ? toDays[0]
          : startDateTime,
      location: {
        locationId: formValues.location._id,
        address: formValues.location.address,
        addressName: formValues.location.addressName,
        houseNumber: formValues.location.houseNumber,
        city: formValues.location.city,
        zip: formValues.location.zip ? formValues.location.zip : "",
      },
    };
    setFormScheduleValues(body);
    if (edit) {
      return await publishScheduledDetails(body);
    } else {
      setDataSaveProgress(defaultDataSaveProgress);
      setTabCurrentState("6");
    }
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleRadioValueChange = (newValue) => {
    if (newValue === "Recurrent") {
      setFormValues((prevValues) => ({
        ...prevValues,
        radioValue2: "Daily",
      }));
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      radioValue: newValue,
      radioValue2: "Daily",
    }));
  };

  const handleRadioValueChange2 = (newValue) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      radioValue2: newValue,
    }));
  };

  const handleStartDateChange = (date) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      endDate: date,
    }));
  };

  const handleStartTimeChange = (newTime) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      startTime: newTime,
    }));
  };

  const handleLocationChange = (newLocation) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      location: newLocation,
    }));
  };

  const handleDaysInWeekChange = (date) => {
    let daysInWeekCopy = [...formValues.daysInWeek];
    if (dateInArray(date, daysInWeekCopy)) {
      daysInWeekCopy = daysInWeekCopy.filter((day) => !isSameDay(day, date));
      setFormValues((prevValues) => ({
        ...prevValues,
        daysInWeek: daysInWeekCopy,
      }));
    } else {
      daysInWeekCopy = [...daysInWeekCopy, date];
      setFormValues((prevValues) => ({
        ...prevValues,
        daysInWeek: daysInWeekCopy,
      }));
    }
  };

  const handleClearDaysInWeek = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      daysInWeek: [],
    }));
  };

  const handleCalendarDaysChange = (dates) => {
    const datesSelected = dates.map((date) => {
      let transformedDate;
      if (date instanceof DateObject) {
        transformedDate = date.toDate();
      }
      return transformedDate;
    });
    setFormValues((prevValues) => ({
      ...prevValues,
      daysInWeek: datesSelected,
    }));
  };

  const handleSubmit = async () => {
    cogoToast.loading("Loading...");
    setLoading(true);
    setSubmitting(true);
    let toDays = [];
    let sDays = [];
    let sortedDates = [];
    let dateWithTime = "";
    let startDateTime =
      formValues.radioValue2 !== "Custom" &&
      set(formValues.startTime, {
        year: Number(format(formValues.startDate, "yyyy")),
        month: Number(format(formValues.startDate, "M")),
        date: Number(format(formValues.startDate, "d")),
        seconds: 0,
      });
    startDateTime =
      formValues.radioValue2 !== "Custom" && sub(startDateTime, { months: 1 });
    let endDateTime =
      formValues.radioValue !== "One Time" &&
      formValues.radioValue2 !== "Custom" &&
      set(formValues.startTime, {
        year: Number(format(formValues.endDate, "yyyy")),
        month: Number(format(formValues.endDate, "M")),
        date: Number(format(formValues.endDate, "d")),
        seconds: 0,
      });
    endDateTime =
      formValues.radioValue !== "One Time" &&
      formValues.radioValue2 !== "Custom" &&
      sub(endDateTime, { months: 1 });
    if (
      formValues.radioValue === "Recurrent" &&
      formValues.radioValue2 === "Daily"
    ) {
      let current = startDateTime;
      while (current <= endDateTime) {
        toDays.push(current.toISOString());
        current = addDays(current, 1);
      }
    }
    if (
      formValues.radioValue === "Recurrent" &&
      formValues.radioValue2 === "Custom"
    ) {
      sortedDates = [...formValues.daysInWeek].sort((a, b) => {
        return new Date(a) - new Date(b);
      });
      sortedDates.map((date) =>
        toDays.push(
          set(date, {
            hours: Number(format(formValues.startTime, "k")),
            minutes: Number(format(formValues.startTime, "m")),
            seconds: 0,
          })
        )
      );
    }
    if (
      formValues.radioValue === "Recurrent" &&
      formValues.radioValue2 === "Weekly"
    ) {
      dateWithTime =
        moment(formValues.startDate).format("MM/DD/YYYY") +
        "," +
        format(formValues.startTime, "K") +
        ":" +
        getMinutes(formValues.startTime) +
        " " +
        format(formValues.startTime, "aaa");
      console.log("dateWithTime", moment(dateWithTime));
      startDateTime = moment(formValues.startDate).set({
        hour: moment(formValues.startTime).hours(),
        minute: moment(formValues.startTime).minutes(),
        seconds: 0,
      });
      endDateTime = moment(formValues.endDate).set({
        hour: moment(formValues.startTime).hours(),
        minute: moment(formValues.startTime).minutes(),
        seconds: 0,
      });
      sDays = [...formValues.daysInWeek].map((day) => getDay(day));
      sDays = sDays.sort((a, b) => a - b);
    }

    const body = {
      isPublished: true,
      // classId: classObj.id,
      className: classObj.className,
      startDate:
        formValues.radioValue === "Recurrent" &&
          formValues.radioValue2 === "Custom"
          ? toDays[0]
          : formValues.radioValue2 === "Weekly"
            ? moment(dateWithTime).toISOString()
            : format(formValues.startDate, "yyyy-MM-dd"),
      timing: format(formValues.startTime, "KK:mm aaa"),
      endAfter: "",
      meridiem: format(formValues.startTime, "a"),
      ...(formValues.radioValue === "Recurrent"
        ? { recurrenceType: formValues.radioValue2.toLowerCase() }
        : {}),
      endBy:
        formValues.radioValue === "Recurrent" &&
          formValues.radioValue2 === "Daily"
          ? endDateTime
          : formValues.radioValue2 === "Weekly"
            ? endDateTime
            : formValues.radioValue2 === "Custom"
              ? toDays[toDays.length - 1]
              : startDateTime,
      utcOffset: new Date().getTimezoneOffset(),
      ...(formValues.radioValue2 === "Weekly"
        ? { daysInWeek: [], selectedDays: sDays }
        : {
          daysInWeek:
            formValues.radioValue === "Recurrent" ? toDays : [startDateTime],
        }),
      classStartDateTime:
        formValues.radioValue === "Recurrent" &&
          formValues.radioValue2 === "Custom"
          ? toDays[0]
          : startDateTime,
      location: {
        locationId: formValues.location._id,
        address: formValues.location.address,
        addressName: formValues.location.addressName,
        houseNumber: formValues.location.houseNumber,
        city: formValues.location.city,
        zip: formValues.location.zip ? formValues.location.zip : "",
      },
    };

    const config = {
      method: "post",
      url: `${getUrlForClasses()}scheduleTheClass`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    const request = await axios(config);
    setLoading(false);
    if (request.status === 200) {
      if (request.data.statusCode !== 200) {
        cogoToast.error(request.data.message).then(() => {
          setActiveStep(0);
        });
      } else {
        cogoToast.success("Success!");
        dispatch(
          dashboardActions.setUpdateSessionComp(
            dashboardReduxState.updateSessionComp + 1
          )
        );
        setOpenSuccessModal(true);
      }
    }
    setSubmitting(false);
  };

  const clearFormValues = () => {
    setFormValues({
      radioValue: "One Time",
      radioValue2: "",
      startDate: "",
      endDate: "",
      startTime: "",
      location: {},
      daysInWeek: [],
    });
  };

  return (
    <>
      <Stack direction={"column"} className={`${isFromDetail === false ? "py-6 px-8" : "px-0"}`} spacing={2}>
        {!edit ? <Box>
          {/* <Box
            className="flex flex-row font-semibold"
            sx={{
              py: 2,
              px: 3,
              backgroundColor: "primary.inputBackground",
              border: 1,
              borderRadius: 3,
              borderColor: "primary.inputBackground",
              minWidth: 340,
              maxWidth: 340,
              // minHeight: 56.2,
            }}>
            <img className={"w-5 mr-2"} src={timerclock} alt={""} />
            Duration: {classObj.durationstring}
          </Box> */}
          <AddScheduleStepper
            activeStep={activeStep}
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            formValues={formValues}
            formErrors={formErrors}
            handleRadioValueChange={handleRadioValueChange}
            handleRadioValueChange2={handleRadioValueChange2}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            handleStartTimeChange={handleStartTimeChange}
            handleLocationChange={handleLocationChange}
            handleDaysInWeekChange={handleDaysInWeekChange}
            sessionTimings={displayDuration}
            handleSubmit={handleSubmit}
            formResData={formResData}
            whatToCarry={classObj.whatToCarry}
            cancellationPolicy={
              classObj.classCancelationPolicy
                ? classObj.classCancelationPolicy
                : { isEnabled: false }
            }
            disableButton={isSubmitting}
            clearFormValues={clearFormValues}
            handleClearDaysInWeek={handleClearDaysInWeek}
            handleCalendarDaysChange={handleCalendarDaysChange}
            setLoading={setLoading}
          />
          <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}>
            <Button
              sx={{
                color: "secondary.mainLight",
                backgroundColor: "common.white",
                borderColor: "secondary.mainLight",
                border: 2,
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "secondary.mainLight",
                  color: "common.white",
                  boxShadow: "none",
                },
                fontWeight: 600,
                borderRadius: 3,
                width: 231,
                height: 52,
                fontSize: 16,
              }}
              onClick={() => {
                setTabCurrentState("5");
              }}
            >
              Back
            </Button>
            <Button
              sx={{
                color: "common.white",
                backgroundColor: "primary.main",
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "primary.main",
                  boxShadow: "none",
                },
                fontWeight: 600,
                borderRadius: 3,
                width: 231,
                height: 52,
                fontSize: 16,
              }}
              variant={"contained"}
              // disabled={isSubmitting}
              onClick={(e) => {
                handleNextStep();
                // setTabCurrentState("6");
              }}
            >
              Coninue
            </Button>
          </Stack>
        </Box>
          : <Box>
            <EditSchedules
              edit={true}
              duration={formValues.duration}
              classObj={formValues}
              setLoading={setLoading}
              formScheduleValues={formScheduleValues}
              setFormScheduleValues={setFormScheduleValues}
              reloadScheduleDetails={reloadScheduleDetails}

              handleRadioValueChange={handleRadioValueChange}
              handleRadioValueChange2={handleRadioValueChange2}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              handleStartTimeChange={handleStartTimeChange}
              handleLocationChange={handleLocationChange}
              handleDaysInWeekChange={handleDaysInWeekChange}
              handleClearDaysInWeek={handleClearDaysInWeek}
              handleCalendarDaysChange={handleCalendarDaysChange}
              formValues={formValues}
              formErrors={formErrors}
              handleSubmit={handleNextStep}
              clearFormValues={clearFormValues}
              isFromDetail={isFromDetail}
            />
          </Box>}
      </Stack>
      <SuccessScheduleModal
        open={openSuccessModal}
        handleAddNew={() => {
          clearFormValues();
          setActiveStep(0);
          setOpenSuccessModal(false);
        }}
        handleClose={() => {
          setOpenSuccessModal(false);
          dispatch(dashboardActions.setCalendarSessionId(-1));
          dispatch(dashboardActions.setCalendarOpen(false));
          navigate("/home/dashboard");
        }}
        bookingLink={shareLink}
      />
    </>
  );
}
