import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";

import location_png from "../assets/location.svg";
import upcoming_calendar from "../assets/upcoming_calendar.svg";

export default function SessionTile({
  imgSrc,
  title,
  time,
  schedule,
  venue,
  totalSeats,
  seatsBooked,
  seatsBookedPerc,
  bookedPeople,
  popover,
  popoverLine,
  selectedForSessionDisplay,
  insideDetailView,
}) {
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");

  return (
    <div
      className={`${
        !popover
          // ? "min-h-[141px] max-h-[141px] w-full sm:min-w-[330px] sm:max-w-[330px]"
          ? "min-h-[141px] max-h-[141px]"
          : "w-[24.5vw] sm:min-w-[330px] sm:max-w-[330px] h-[141px] my-1"
      } px-4 py-4 flex flex-row items-center gap-4 ${
        insideDetailView ? "cursor-pointer" : ""
      } ${
        selectedForSessionDisplay ? "bg-[#413131]" : "bg-white hover:bg-[#ddddddc7]"
      } border border-bright-gray rounded-2xl`}
    >
      <div
        className={`${
          popoverLine && "border-l-4 border-light-brown-coffee pl-1"
        }`}
      >
        <img
          className={`${
            !popover
              // ? "w-[140px] h-[111px] sm:w-28 md:w-24 lg:w-24 sm:h-28 md:h-32 lg:h-20 rounded-xl"
              ? "w-[300px] h-[111px] rounded-xl"
              : "w-[94px] h-[111px] my-auto sm:w-28 sm:h-28 md:w-24 md:h-32 lg:w-24 lg:h-20 rounded-xl"
          } object-cover`}
          src={imgSrc}
          alt={"session img"}
        />
      </div>
      <div
        className={`flex flex-col item-center ${
          !popover ? "gap-1" : ""
        } w-full`}
      >
        <h3
          className={`${
            selectedForSessionDisplay ? "text-white" : "text-light-coffee-brown"
          } font-bold text-base sm:text-sm md:text-sm`}
        >
          {title}
        </h3>
        <div className={"flex flex-row items-center gap-2"}>
          <p
            className={`${
              selectedForSessionDisplay ? "text-white" : "text-granite-gray"
            } text-xs`}
          >
            {(false && schedule !== "") ? (<h4 className={"font-bold text-[20px] text-[#481B3A]"}>{schedule}</h4>) : ""}
          </p>
        </div>
        <div className={"flex flex-row items-center gap-2"}>
          <img className={"w-3 h-3"} src={upcoming_calendar} alt={""} />
          <p
            className={`${
              selectedForSessionDisplay ? "text-white" : "text-granite-gray"
            } text-xs`}
          >
            {time}
          </p>
        </div>
        <div className={"flex flex-row items-center gap-2"}>
          <img className={"w-3 h-3"} src={location_png} alt={""} />
          <p
            className={`${
              selectedForSessionDisplay ? "text-white" : "text-granite-gray"
            } text-xs`}
          >
            {venue}
          </p>
        </div>
        <div className={"flex flex-row flex-wrap items-center"}>
          {Array.isArray(bookedPeople) && bookedPeople.length > 3 ? (
            <div
              className={`flex flex-row items-center ${
                !popover ? "mt-4 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-2" : "mt-4"
              } -space-x-2 overflow-hidden`}
            >
              {bookedPeople.slice(0, 3).map((person, i) => (
                <div className='avatar' key={i}>
                  <div className='rounded-full w-[27px] sm:w-6 md:w-6 lg:w-6'>
                    <Tooltip title={person.fullName}>
                      <img src={person.profilePic} alt={person.fullName} />
                    </Tooltip>
                  </div>
                </div>
              ))}
              <div className='avatar placeholder'>
                <div
                  className={`${
                    selectedForSessionDisplay
                      ? "text-[#413131] bg-white"
                      : "text-white bg-light-brown-coffee"
                  } rounded-full font-semibold text-sm sm:text-xs md:text-xs lg:text-xs w-7 sm:w-4 md:w-4 lg:w-4`}
                >
                  <span>
                    +{bookedPeople.slice(bookedPeople.length - 2).length}
                  </span>
                </div>
              </div>
            </div>
          ) : bookedPeople.length < 4 ? (
            <div
              className={`flex flex-row items-center ${
                !popover ? "mt-4 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-2" : "mt-4"
              } -space-x-2 overflow-hidden`}
            >
              {bookedPeople.map((person,i) => (
                <div className='avatar' key={i}>
                  <div className='rounded-full w-[27px] sm:w-6 md:w-6 lg:w-6'>
                    <Tooltip title={person.fullName}>
                      <img src={person.profilePic} alt={person.fullName} />
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}
          {popover && (
            <div
              className={`${
                selectedForSessionDisplay ? "bg-white" : "bg-brown-coffee"
              } mt-4 ml-auto rounded-lg px-2 py-1`}
            >
              <span
                className={`${
                  selectedForSessionDisplay ? "text-[#413131]" : "text-white"
                } font-semibold text-sm lg:text-xs`}
              >
                {seatsBooked}/{totalSeats} Booked
              </span>
            </div>
          )}
        </div>
      </div>
      {!popover && (
        <div className={"flex flex-col items-center ml-auto"}>
          <div
            className={"radial-progress text-light-brown-coffee after:bg-transparent"}
            style={{
              "--value": Number(seatsBookedPerc),
              "--size": isMobile
                ? "3rem"
                : isTablet
                ? "3rem"
                : isLaptopL
                ? "3rem"
                : "3.5rem",
              "--thickness": "1.5px",
            }}
          >
            <div
              className={
                "w-[46px] h-[46px] sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-10 lg:h-10 flex justify-center items-center bg-light-brown-coffee rounded-full border-2 border-white"
              }
            >
              <p className={"font-bold text-white text-[10px]"}>
                {Number(seatsBookedPerc).toFixed(1)}%
              </p>
            </div>
          </div>
          <h3
            className={
              "text-light-brown-coffee font-bold text-base sm:text-sm md:text-sm lg:text-sm"
            }
          >
            {seatsBooked}/{totalSeats}
          </h3>
          <p className={"text-granite-gray text-xs text-center"}>
            Seats Booked
          </p>
        </div>
      )}
    </div>
  );
}
