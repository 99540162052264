import {
  AppBar, Backdrop, Box, Button, Fade, IconButton, Modal, Stack, useMediaQuery
} from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import { addMinutes, format, parse, parseISO } from "date-fns";
import { DateTime } from "luxon";
import React from "react";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Scroll from "react-scroll";

import { useLocalStorage } from "../hooks";
import * as dashboardActions from "../redux/reducers/dashboardReducer";
import { dashboardState } from "../redux/reducers/dashboardReducer";

import CalendarView from "./CalendarView";
import Notifications from "./Notifications";
import SessionTile from "./SessionTile";

import calendar_png from "../assets/calender.png";
import charetright_png from "../assets/charetright.png";
import filter_png from "../assets/Filter.png";

import IconTint from "react-icon-tint";
import HeaderUserMenu from "./HeaderUserMenu";
import { clearUserData, userState } from "../redux/reducers/userReducer";
import { Link } from "@mui/icons-material";
import ServiceBookingLinkModal from "../modals/venueAndServices/services/ServiceBookingLinkModal";
import { clearSchoolData } from "../redux/reducers/schoolReducer";
import { clearDashboardData } from "../redux/reducers/dashboardReducer";
import { getUrlForClasses } from "../utils/confighelpers";

function NewNavBar({ titleComp, handleDrawer, showCalendarIcon, setCalendarDrawer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dashboardReduxState = useSelector(dashboardState);
  const userReduxState = useSelector(userState);
  const [query, setQuery] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [events, setEvents] = React.useState();
  const [eventDates, setEventDates] = React.useState();
  const [eventDatesRange, setEventDatesRange] = React.useState();
  const [sessions, setSessions] = React.useState({});
  const [userToken, setUserToken] = useLocalStorage("userToken");
  const [referenceElement, setReferenceElement] = React.useState();
  const [popperElement, setPopperElement] = React.useState();
  const [showServiceBookingLink, setShowServiceBookingLink] = React.useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");

  React.useEffect(() => {
    const { Events, scrollSpy } = Scroll;

    Events.scrollEvent.register("begin", () => {
      console.log("scroll register begin", arguments);
    });

    Events.scrollEvent.register("end", () => {
      console.log("scroll register end", arguments);
    });

    scrollSpy.update();

    axios.interceptors.response.use(async response => {
      // console.log("interceptors response: ", response.data);
      if (response.data.statusCode === 403 && typeof response.data.message == "object" && response.data.message.name === "TokenExpiredError") {
        // console.log("interceptors response: ", "logout");
        localStorage.removeItem("userToken");
        localStorage.removeItem("persist:root");
        dispatch(clearUserData());
        dispatch(clearSchoolData());
        dispatch(clearDashboardData());
        await setUserToken("");
        // navigate("/", { replace: true });
        window.location.href = "/";
      }
      // Edit response config
      return response;
  }, async error => {
      console.log("interceptors error: ", error.response);
      if (error?.response && error.response.status === 502) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("persist:root");
        dispatch(clearUserData());
        dispatch(clearSchoolData());
        dispatch(clearDashboardData());
        await setUserToken("");
        window.location.href = "/";
      }
      return Promise.reject(error);
  });

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  React.useEffect(() => {
    (async () => {
      /** this is call for claender data listing which is now not in use **/
      // const date = new Date(selectedDate).toISOString();
      // const config = {
      //   method: "get",
      //   url: `${getUrlForClasses()}groupScheduledClass?searchDate=${date}&classId=`,
      //   headers: {
      //     "x-access-token": userToken.toString(),
      //   },
      // };
      // const request = await axios(config);
      // if (request.status === 200) {
      //   const allEvents = { ...request.data.data };
      //   let allEventDates = [];
      //   const dateFormat = "yyyy-MM-dd";
      //   const eventDatesRange = { from: "", to: "" };
      //   const eventKeys = Object.keys(allEvents);
      //   const startDate = parse(eventKeys[0], dateFormat, new Date());
      //   const endDate = parse(
      //     eventKeys[eventKeys.length - 1],
      //     dateFormat,
      //     new Date()
      //   );
      //   eventDatesRange.from = startDate;
      //   eventDatesRange.to = endDate;
      //   eventKeys.forEach((key) => {
      //     if (allEvents[key].length > 0) {
      //       const parsedDate = parse(key, dateFormat, new Date());
      //       allEventDates.push(parsedDate);
      //     }
      //   });
      //   setEventDatesRange({ ...eventDatesRange });
      //   setEventDates([...allEventDates]);
      //   setEvents({ ...allEvents });

      //   let initialSessionsData = { ...allEvents };
      //   const todayFormated = format(new Date(selectedDate), dateFormat);
      //   const idxOfTodayInEvents =
      //     Object.keys(initialSessionsData).indexOf(todayFormated);
      //   initialSessionsData = Object.fromEntries(
      //     Object.entries(initialSessionsData).slice(idxOfTodayInEvents)
      //   );

      //   // initialSessionsData = filterObject(
      //   //   initialSessionsData,
      //   //   (data) => Array.isArray(data) && data.length > 0
      //   // );

      //   setSessions({ ...initialSessionsData });
      // } else if (request.status === 502) {
      //   console.log("logout");
      // }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, dashboardReduxState.updateSessionComp]);

  const onDateClickHandle = async (day) => {
    const dateFormat = "yyyy-MM-dd";
    const newDateFormated = format(new Date(day), dateFormat);
    setSelectedDate(day);
    const newDate = new Date(day).toISOString();
    const config = {
      method: "get",
      url: `${getUrlForClasses()}groupScheduledClass?searchDate=${newDate}&classId=`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    let selectedDateSessions;
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        selectedDateSessions = request.data.data;
        if (!Object.keys(selectedDateSessions).length) {
          const formattedDate = format(parseISO(newDate), dateFormat);
          selectedDateSessions = { [formattedDate]: [] };
        } else {
          const idxOfNewDateInEvents = Object.keys(request.data.data).indexOf(
            newDateFormated
          );
          selectedDateSessions = Object.fromEntries(
            Object.entries(request.data.data).slice(idxOfNewDateInEvents)
          );
          // selectedDateSessions = filterObject(
          //   selectedDateSessions,
          //   (data) => Array.isArray(data) && data.length > 0
          // );
        }
      }
    }

    setSessions({ ...selectedDateSessions });

    const { scroller } = Scroll;
    scroller.scrollTo(newDateFormated, {
      smooth: true,
      containerId: "sessionsPopover",
      delay: 2000,
    });
  };

  const handleModalOpen = () => {
    setCalendarDrawer(true);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSessionClick = async (id, classData) => {
    if (dashboardReduxState.calendarSessionId === id) {
      dispatch(dashboardActions.setCalendarSessionId(-1));
      dispatch(dashboardActions.setCalendarOpen(false));
      navigate("/home/dashboard");
      return;
    }
    cogoToast.loading("Loading session details");
    const isPckg = classData.isPackage;
    const config = {
      method: "get",
      url: `${getUrlForClasses()}getScheduleDetail?scheduleclassId=${id}&isPackage=${isPckg}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        dispatch(dashboardActions.setCalendarClassData(classData));
        dispatch(dashboardActions.setCalendarSessionId(id));
        dispatch(dashboardActions.setCalendarSessionData(request.data.data));
        dispatch(dashboardActions.setViewSessionPage(true));
        navigate("/dashboard/ViewSessionDetails");
      }
    }
  };

  const renderSessions = (day, data) => {
    const dateFormat = "yyyy-MM-dd";
    const parsedDay = parse(day, dateFormat, new Date());
    const displayDateFormat = "EEEE, LLLL d";
    return (
      <Scroll.Element name={dateFormat} id={"sessionsPopover"} key={day}>
        <Scroll.Element name={day}>
          <p
            className={
              "font-bold text-black text-sm block self-start mt-5 mb-1"
            }
          >
            {format(parsedDay, displayDateFormat)}
          </p>
        </Scroll.Element>
        {data.length > 0 ? (
          data.map((item, idx) => {
            const dtStart = DateTime.fromISO(item.classDate);
            const dtEnd = format(
              addMinutes(dtStart.ts, item.duration),
              "hh:mma"
            );
            const modifiedData = {
              id: item.instanceId,
              imgSrc: item.classPhoto,
              title: item.className,
              time: `${dtStart.toFormat("dd LLL, ccc, hh':'mma")} - ${dtEnd}`,
              venue: `${item.location.houseNumber}, ${item.location.city}`,
              totalSeats: item.capacity,
              seatsBooked: item.participants.length,
              seatsBookedPerc: (item.participants.length / item.capacity) * 100,
              bookedPeople: item.participants,
              popover: true,
              popoverLine: item.isPackage,
              insideDetailView: true,
            };
            return (
              <div
                className={"cursor-pointer hover:bg-gray-400"}
                onClick={() => handleSessionClick(modifiedData.id, item)}
              >
                <SessionTile
                  {...modifiedData}
                  key={idx}
                  selectedForSessionDisplay={
                    dashboardReduxState.calendarSessionId === item.instanceId
                  }
                />
              </div>
            );
          })
        ) : (
          <div className={"text-black"}>No Sessions for this day</div>
        )}
      </Scroll.Element>
    );
  };

  return (
    <div
      className={
        "w-full py-6 lg:py-4 sm:py-1 md:py-2 flex flex-row justify-between items-center sm:grid sm:grid-row-2 sm:grid-cols-2 sm:gap-2"
      }
    >
      <div
        className={
          "sm:row-start-1 sm:col-span-2 flex flex-row items-center gap-6 sm:gap-2 md:gap-2"
        }
      >
        {/* <IconButton
          sx={{ display: isMobile || isTablet ? "block" : "none" }}
          onClick={handleDrawer}
        >
          <MenuIcon />
        </IconButton> */}
        {titleComp}
      </div>
      <div
        className={
          "sm:row-start-2 sm:col-span-2 sm:justify-end flex flex-row items-center gap-6 sm:gap-2 lg:gap-4"
        }
      >
        {/* <TextField
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={"Search"}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <img
                  className={"lg:w-[18px] w-[18px]"}
                  src={search_png}
                  alt={""}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              borderColor: "#EBEBEB",
              fontSize: isLaptopL ? 13 : 14,
            },
            width: isLaptopL ? 200 : isTablet ? 170 : isMobile ? 140 : 305,
          }}
          size={isMobile ? "small" : isTablet ? "small" : undefined}
        /> */}
        {(userReduxState.businessType === "service" && userReduxState.userData && userReduxState.userData?.myBookingLink) ?
          <>
            <IconButton
              sx={{
                color: "common.white",
                backgroundColor: "primary.main",
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "primary.main",
                  boxShadow: "none",
                },
                borderRadius: 4,
                fontSize: "20px",
                px: 2,
              }}
              title="Share Booking Link"
              variant={"contained"}
              onClick={() => setShowServiceBookingLink(true)}>
              <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.1942 17.1955L12.5471 18.8426C11.455 19.9347 9.9186 20.5841 8.27584 20.648C6.63308 20.7119 5.01856 20.185 3.78744 19.1833C2.55632 18.1816 1.80946 16.787 1.71116 15.3065C1.61285 13.8259 2.17115 12.3806 3.26325 11.2885L4.91034 9.64142M9.85163 4.70013L11.4987 3.05304C12.5908 1.96094 14.1272 1.31152 15.77 1.24763C17.4127 1.18374 19.0273 1.71062 20.2584 2.71235C21.4895 3.71409 22.2364 5.10863 22.3347 6.58919C22.433 8.06975 21.8747 9.51505 20.7826 10.6071L19.1355 12.2542M8.72873 14.242L15.3171 7.65364" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              &nbsp;&nbsp;Share Booking Link
            </IconButton>
            {showServiceBookingLink ? (<ServiceBookingLinkModal
              title="Booking Link for Service"
              subtitle="Booking Link Generated"
              open={showServiceBookingLink}
              handleClose={() => {
                setShowServiceBookingLink(false);
              }}
              bookingLink={(userReduxState.userData?.appUrl ? userReduxState.userData?.appUrl : process.env.REACT_APP_WEBAPP_URL) + userReduxState.userData?.myBookingLink}
            />) : null}
          </>
          : null}
        <Notifications />
        <HeaderUserMenu />
        {false && showCalendarIcon ? (
          /* isMobile || isTablet */ false ? (
            <>
              <IconButton onClick={handleModalOpen}>
                <img
                  className={"w-6 sm:w-5 md:w-5 sm:mx-1 translate-y-0.5"}
                  src={calendar_png}
                  alt={""}
                />
              </IconButton>
              <Modal
                keepMounted
                open={modalOpen}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                sx={{
                  overflow: "scroll",
                }}
              >
                <Fade in={modalOpen}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "95%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: isTablet ? 450 : 350,
                      bgcolor: "background.paper",
                      borderRadius: 3,
                      boxShadow: 24,
                      p: 4,
                      maxHeight: "100vh",
                    }}
                  >
                    <img
                      className={"block ml-auto lg:w-2"}
                      src={charetright_png}
                      alt={""}
                    />
                    <div
                      className={"flex flex-col justify-center items-center"}
                    >
                      <AppBar position={"sticky"} elevation={0}>
                        <Stack direction={"column"}>
                          <button
                            className={
                              "rounded-xl border border-lilac bg-white flex flex-row justify-between items-center w-full h-12 p-4 my-4 mx-2 active:opacity-60"
                            }
                          >
                            <p
                              className={
                                "text-light-brown-coffee text-base sm:text-xs lg:text-sm"
                              }
                            >
                              All
                            </p>
                            <img
                              className={"sm:w-3"}
                              src={filter_png}
                              alt={""}
                            />
                          </button>
                          <CalendarView
                            selectedDate={selectedDate}
                            onDateClickHandle={onDateClickHandle}
                            eventDatesRange={eventDatesRange}
                            eventDates={eventDates}
                          />
                        </Stack>
                      </AppBar>
                      <div className={`self-start`}>
                        {Object.entries(sessions).map(([key, value]) =>
                          renderSessions(key, value)
                        )}
                      </div>
                    </div>
                  </Box>
                </Fade>
              </Modal>
            </>
          ) : (
            <button onClick={handleModalOpen}>
              <IconTint
                maxWidth={24}
                maxHeight={24}
                src={calendar_png}
              />
            </button>
            // <EventsCalendar />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

NewNavBar.defaultProps = {
  showCalendarIcon: true,
};

export default NewNavBar;
