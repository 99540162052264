import {
  Button, FormControl, FormControlLabel,
  FormHelperText, Radio, RadioGroup, Stack
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IOSSwitch from "../../../../components/IOSSwitch";
import { useLocalStorage } from "../../../../hooks";
import { userState } from "../../../../redux/reducers/userReducer";
import { defaultAvailablePaymentMethods } from "../../../../utils/constants";
import axios from "axios";
import { getUrlForBookings } from "../../../../utils/confighelpers";

export default function PoliciesTab({
  formErrors,
  cancel_img,
  info_i,
  formValues,
  handleFormValueChange,
  setFormValues,
  payment,
  invite_img,
  setTabCurrentState,
  edit = false,
  setLoading = null,
  handleUpdate,
  handleCancel,
  isFromDetail = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userToken] = useLocalStorage("userToken");const userReduxState = useSelector(userState);
  const [availablePaymentMethods, setAvailablePaymentMethods] = React.useState(defaultAvailablePaymentMethods);

  const getAvailablePaymentMethods = async () => {
    const requestConfig = {
      method: "get",
      url: `${getUrlForBookings()}payments/getAvailablePaymentMethods?teacherID=${userReduxState.userData?._id}&paymentMethodsFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {
        console.log("data", response.data.data);
        if (response.data.data) {
          setAvailablePaymentMethods(response.data.data)
        }
      }
    }
  };

  React.useEffect(() => {
    getAvailablePaymentMethods();
  }, []);

  return (
    <Stack direction={"column"} className={"py-6 px-8"} spacing={2}>
      <div
        className={`${isFromDetail === false ? "/*w-3/5*/ w-[70%] " : ""}min-h-[114px] bg-white border ${formErrors["cancellationPeriod"]
          ? "border-[#d32f2f]"
          : "border-brown-coffee"
          } px-6 py-6 grid grid-cols-[1fr_16fr_2fr] rounded-2xl`}
      >
        <img className={"w-17 mt-1.5"} src={cancel_img} alt={""} />
        <div className={"flex flex-col content-start"}>
          <div className={"flex flex-row items-center gap-3"}>
            <h4 className={"text-lg text-brown-coffee font-bold"}>
              Allow Free Cancellation
            </h4>
            {/* <Tooltip title={"Allowing free cancellation recommended"} placement="right">
              <img className={"w-3 h-3"} src={info_i} alt={""} />
            </Tooltip> */}
            <p className={"text-sm text-[#676767] mt-1"}>
              <img className={"w-4 h-4 inline mr-2 align-text-top"} src={info_i} alt={""} /> Allowing free cancellation recommended
            </p>
          </div>
          <p className={"text-sm text-[#676767]"}>
            Period before free cancellation is allowed:
          </p>
          <FormControl error={formErrors["cancellationPeriod"] ? true : false}>
            <RadioGroup
              name={"cancellationPeriod"}
              value={formValues.cancellationPeriod}
              onChange={handleFormValueChange}
              row
            >
              <FormControlLabel
                value={"1hr"}
                control={
                  <Radio
                    disabled={!formValues.freeCancellation}
                    sx={{
                      "& .MuiSvgIcon-root": { width: 13, height: 13 },
                    }}
                  />
                }
                label={"1hr"}
                disabled={!formValues.freeCancellation}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: 12,
                    color: "#676767",
                  },
                }}
              />
              <FormControlLabel
                value={"24hrs"}
                control={
                  <Radio
                    disabled={!formValues.freeCancellation}
                    sx={{
                      "& .MuiSvgIcon-root": { width: 13, height: 13 },
                    }}
                  />
                }
                label={"24hrs"}
                disabled={!formValues.freeCancellation}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: 12,
                    color: "#676767",
                  },
                }}
              />
              <FormControlLabel
                value={"48hrs"}
                control={
                  <Radio
                    disabled={!formValues.freeCancellation}
                    sx={{
                      "& .MuiSvgIcon-root": { width: 13, height: 13 },
                    }}
                  />
                }
                label={"48hrs"}
                disabled={!formValues.freeCancellation}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: 12,
                    color: "#676767",
                  },
                }}
              />
              <FormControlLabel
                value={"1 week"}
                control={
                  <Radio
                    disabled={!formValues.freeCancellation}
                    sx={{
                      "& .MuiSvgIcon-root": { width: 13, height: 13 },
                    }}
                  />
                }
                label={"1 week"}
                disabled={!formValues.freeCancellation}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: 12,
                    color: "#676767",
                  },
                }}
              />
            </RadioGroup>
            {formErrors["cancellationPeriod"] && (
              <FormHelperText>
                {formErrors["cancellationPeriod"]}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className={"grid justify-items-end content-start"}>
          <IOSSwitch
            className={"place-self-end"}
            name={"freeCancellation"}
            type={"checkbox"}
            checked={formValues.freeCancellation}
            onChange={() => {
              setFormValues((prevValues) => ({
                ...prevValues,
                freeCancellation: !formValues.freeCancellation,
              }));
            }}
          />
        </div>
      </div>
      <div
        className={
          `${isFromDetail === false ? "/*w-3/5*/ w-[70%] " : ""}px-6 py-6 grid grid-cols-[1fr_16fr_2fr] bg-white border border-brown-coffee rounded-2xl ${availablePaymentMethods.payAtVenue.enable == false ? "bg-[#CCCCCC]" : ""}`
        }
      >
        <img className={"w-17 mt-1.5"} src={payment} alt={""} />
        <div className={"flex flex-col content-start"}>
          <div className={"flex flex-row items-center gap-3"}>
            <h4 className={"text-lg text-brown-coffee font-bold"}>
              Allow Payment at Venue
            </h4>
            {/* <Tooltip title={ "Allowing offline payments" }>
              <img className={"w-3 h-3"} src={info_i} alt={""} />
            </Tooltip> */}
          </div>
          <p className={"text-sm text-[#676767] mt-1"}>
            You can disable this if you have enabled your payment details
          </p>
          <p className={"text-sm text-[#676767] mt-1"}>
            <img className={"w-4 h-4 inline mr-2 align-text-top"} src={info_i} alt={""} /> Allowing offline payments
          </p>
          {availablePaymentMethods.payAtVenue.enable == false && (<p className={"text-sm text-[#F64E60] mt-1"}>
            <img className={"w-4 h-4 inline mr-2 align-text-top"} src={info_i} alt={""} /> Payment at Venue is not available on free subscription.
          </p>)}
        </div>
        <div className={"grid justify-items-end content-start"}>
          <IOSSwitch
            className={"self-start justify-self-end"}
            name={"paymentAtVenue"}
            type={"checkbox"}
            checked={formValues.paymentAtVenue}
            disabled={availablePaymentMethods.payAtVenue.enable === false}
            onChange={() =>
              setFormValues((prevValues) => ({
                ...prevValues,
                paymentAtVenue: !formValues.paymentAtVenue,
              }))
            }
          />
        </div>
      </div>
      <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}>
        <Button
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            if (edit) {
              handleCancel()
            } else {
              setTabCurrentState("2");
            }
          }}
        >
          {edit ? "Cancel" : "Back"}
        </Button>
        <Button
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          variant={"contained"}
          onClick={() => {
            if (edit) {
              handleUpdate()
            } else {
              setTabCurrentState("4");
            }
          }}
        >
          {edit ? "Update" : "Coninue"}
        </Button>
      </Stack>
    </Stack>
  );
}
