import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";

import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InitialsAvatar from 'react-initials-avatar';
// import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import * as dashboardActions from "../redux/reducers/dashboardReducer";
import { dashboardState } from "../redux/reducers/dashboardReducer";
import { schoolState } from "../redux/reducers/schoolReducer";
import * as userActions from "../redux/reducers/userReducer";
import { userState } from "../redux/reducers/userReducer";
import profile_img from "../assets/avatar_placeholder.png";
import { useLocalStorage } from "../hooks";
import { clearDashboardData } from "../redux/reducers/dashboardReducer";
import { clearSchoolData } from "../redux/reducers/schoolReducer";
import { clearUserData } from "../redux/reducers/userReducer";
import { getUrlForUsers } from "../utils/confighelpers";

export default function HeaderUserMenu() {

     const settings = [
          {
               label: "My Profile",
               navigate: { to: "/myprofile" },
          },
          {
               label: "Business Profile",
               navigate: { to: "/businessprofile/view" },
          },
          {
               label: "Logout",
          }];
     const [userToken, setUserToken] = useLocalStorage("userToken");
     const userReduxState = useSelector(userState);
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const dashboardReduxState = useSelector(dashboardState);
     const schoolReduxState = useSelector(schoolState);

     const [anchorElUser, setAnchorElUser] = React.useState(null);

     const handleOpenUserMenu = (event) => {
          setAnchorElUser(event.currentTarget);
     };

     const handleCloseUserMenu = () => {
          setAnchorElUser(null);
     };

     const Logout = () => {
          cogoToast.loading("Logging out...").then(async () => {
               try {
                    await axios.put(
                         `${getUrlForUsers()}logoutUser`,
                         {},
                         {
                              headers: {
                                   "Content-Type": "application/json",
                                   "x-access-token": userToken,
                              },
                         }
                    );
               } catch (err) {
                    console.log("error", err);
               } finally {
                    localStorage.removeItem("userToken");
                    localStorage.removeItem("persist:root");
                    dispatch(clearUserData());
                    dispatch(clearSchoolData());
                    dispatch(clearDashboardData());
                    await setUserToken("");
                    // navigate("/", { replace: true });
                    window.location.href = "/";
               }
          });
     };

     const updateUserProfile = async (businessType) => {
          cogoToast.loading("Loading...");
          const schoolUpdateBody = {
               businessType: businessType,
          };
          const userUpdateConfig = {
               method: "put",
               url: `${getUrlForUsers()}updateProfile`,
               headers: {
                    "x-access-token": userToken,
               },
               data: schoolUpdateBody,
          };
          const userUpdateRequest = await axios(userUpdateConfig);
          if (userUpdateRequest.status === 200) {
               if (userUpdateRequest.data.statusCode === 200) {
                    dispatch(userActions.setBusinessType(businessType));
               }
          }
     };

     return (
          <Box sx={{ flexGrow: 0 }}>
               <Button
                    onClick={handleOpenUserMenu}
                    title={userReduxState.userData?.fullName}
                    sx={{
                         border: "1px solid #00000099",
                         borderRadius: 3,
                         boxShadow: "none",
                         "&:hover": {
                              //  backgroundColor: "secondary.mainLight",
                              //  color: "common.white",
                              //  boxShadow: "none",
                         },
                    }}>

                    {userReduxState.userData?.profilePic ? (<Avatar
                         className={"mask mask-circle w-9 h-9"}
                         alt="" src={userReduxState.userData?.profilePic ?? profile_img} />) : (
                         // <InitialsAvatar name={userReduxState.userData?.fullName} className={"w-9 h-9 bg-brown-coffee initials-avatar"} />
                         <InitialsAvatar name={userReduxState.userData?.fullName || ""} className={"initials-avatar w-9 h-9 bg-brown-coffee"} />
                    )}
                    &nbsp;{userReduxState.userData?.fullName || ""}
               </Button>
               <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
               >
                    {process.env?.REACT_APP_APP_ENV && process.env?.REACT_APP_APP_ENV === "dev" && (<MenuItem
                         onClick={async () => {
                              handleCloseUserMenu();
                              await dispatch(userActions.setBusinessType("class"));
                              await updateUserProfile("class");
                              // navigate("/home/dashboard", { replace: true });
                              window.location.href = "/home/dashboard";
                         }}>
                         <Typography textAlign="center">Bookzy Classes</Typography>
                    </MenuItem>)}
                    {process.env?.REACT_APP_APP_ENV && process.env?.REACT_APP_APP_ENV === "dev" && (<MenuItem
                         onClick={async () => {
                              handleCloseUserMenu();
                              await dispatch(userActions.setBusinessType("event"));
                              await updateUserProfile("event");
                              // navigate("/home/dashboard", { replace: true });
                              window.location.href = "/home/dashboard";
                         }}>
                         <Typography textAlign="center">Bookzy Events</Typography>
                    </MenuItem>)}
                    {process.env?.REACT_APP_APP_ENV && process.env?.REACT_APP_APP_ENV === "dev" && (<MenuItem
                         onClick={async () => {
                              handleCloseUserMenu();
                              await dispatch(userActions.setBusinessType("service"));
                              await updateUserProfile("service");
                              // navigate("/home/dashboard", { replace: true });
                              window.location.href = "/home/dashboard";
                         }}>
                         <Typography textAlign="center">Bookzy Services</Typography>
                    </MenuItem>)}
                    {settings.map((setting, i) => (
                         <MenuItem key={i}
                              onClick={async () => {
                                   handleCloseUserMenu();
                                   if (setting.label === "Logout") {
                                        return await Logout();
                                   } else {
                                        navigate(setting.navigate.to, {
                                             state: setting.navigate.state,
                                        });
                                   }
                              }}>
                              <Typography textAlign="center">{setting.label}</Typography>
                         </MenuItem>
                    ))}
               </Menu>
          </Box>
     );
}
