import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Stack, Tab, useMediaQuery } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";

import NeewSidebar from "../../../components/NewSideBar";
import {
  LoadingBackdrop
} from "../../../layouts";
import GeneralTab from "./GeneralTab";
import PoliciesTab from "./PoliciesTab";
import PricingTab from "./PricingTab";
import ReviewPublishTab from "./ReviewPublishTab";
import ScheduleTab from "./schedule/ScheduleTab";

import { minutesToDuration } from "../../../helpers";
import { schoolState } from "../../../redux/reducers/schoolReducer";


import cancel_img from "../../../assets/Cancel Pay.svg";
import info_i from "../../../assets/i.svg";
import invite_img from "../../../assets/Invite Code.svg";
import payment from "../../../assets/Payment.svg";
import { userState } from "../../../redux/reducers/userReducer";
import PassesTab from "./PassesTab";
import { getUrlForClasses, getUrlForStage, getUrlForUsers } from "../../../utils/confighelpers";

const popperModifiers = { placement: "bottom" };

export default function AddNewClass({ navigation, profileFor }) {
  const [userToken] = useLocalStorage("userToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dashboardReduxState = useSelector(dashboardState);
  const userReduxState = useSelector(userState);
  const [loading, setLoading] = React.useState(false);
  const [successMgModalOpen, setSuccessMgModalOpen] = React.useState(false);
  const [tabCurrentState, setTabCurrentState] = React.useState('1');
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  // const title = " Add New " + (location.state ? location.state.title : "")
  const title = " Add New " + profileFor.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  })
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabCurrentState(newValue);
  };

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const edit = null;
  const editData = {};
  const durationObj = edit && minutesToDuration(editData.duration ? editData.duration : 15);
  const classBodyData = {
    photo: edit ? editData.classPhoto : "",
    eventCategory: edit
      ? { _id: editData.catId, name: editData.classType }
      : {},
    categories: edit
      ? { catId: editData.catId, catName: editData.classType }
      : {},
    className: edit ? editData.className : "",
    tagLine: edit ? editData.tagLines : "",
    classDescription: edit ? editData.description : "",
    price: edit ? editData.cost : 0,
    seats: edit ? editData.capacity : 0,
    duration: durationObj,
    durationstring: edit ? `${durationObj.hours} hr ${durationObj.mins} min` : "",
    sessions: edit ? editData.sessionInPackage : "",
    whatToCarry: edit ? editData.whatToCarry : "",
    freeCancellation: edit
      ? editData.classCancelationPolicy?.isEnabled
        ? true
        : false
      : true,
    cancellationPeriod: edit
      ? editData.classCancelationPolicy?.minutes === 60
        ? "1hr"
        : editData.classCancelationPolicy?.minutes === 1440
          ? "24hrs"
          : editData.classCancelationPolicy?.minutes === 2880
            ? "48hrs"
            : editData.classCancelationPolicy?.minutes === 10080
              ? "1 week"
              : ""
      : "24hrs",
    inviteCodeOnlyBooking: edit ? (editData.isPrivate ? true : false) : false,
    paymentAtVenue: false,
    isEvent: false,
    eventPricingList: [],
    multiSessionPassList: [],
  };
  const [formValues, setFormValues] = React.useState(classBodyData);
  const [formScheduleValues, setFormScheduleValues] = React.useState({});
  const [defaultScheduleData, setDefaultScheduleData] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [catRefEl, setCatRefEl] = React.useState();
  const [catPopperEl, setCatPopperEl] = React.useState();
  const catPopper = usePopper(catRefEl, catPopperEl, popperModifiers);
  const [sesRefEl, setSesRefEl] = React.useState();
  const [sesPopperEl, setSesPopperEl] = React.useState();
  const sesPopper = usePopper(sesRefEl, sesPopperEl, popperModifiers);
  const [durRefEl, setDurRefEl] = React.useState();
  const [durPopperEl, setDurPopperEl] = React.useState();
  const durPopper = usePopper(durRefEl, durPopperEl, popperModifiers);
  const [seatsRefEl, setSeatsRefEl] = React.useState();
  const [seatsPopperEl, setSeatsPopperEl] = React.useState();
  const seatsPopper = usePopper(seatsRefEl, seatsPopperEl, popperModifiers);
  const schoolReduxState = useSelector(schoolState);

  const defaultDataSaveProgress = {
    isStartSubmiting: false,
    isButtonShow: true,
    createClass: false,
    createPricing: false,
    createSchedule: false,
    changePublishStatus: false,
    creatingBookingLink: false,
    bookingLink: "https://Bookzyme.com/",
  };
  const [dataSaveProgress, setDataSaveProgress] = React.useState(defaultDataSaveProgress);

  const saveClass = async (isPublish) => {
    setSubmitting(true);
    cogoToast.loading("Loading...");
    setLoading(true);
    const errors = {};
    if (!formValues.photo) {
      errors.photo = "Required!";
    }
    if (!Object.keys(formValues.eventCategory).length) {
      errors.eventCategory = "Required!";
    }
    if (!Object.keys(formValues.categories).length) {
      errors.categories = "Required!";
    }
    if (!formValues.className) {
      errors.className = "Required!";
    }
    if (!formValues.classDescription) {
      errors.classDescription = "Required!";
    } else if (formValues.classDescription.length < 5) {
      errors.classDescription = `Enter at least 5 letters to explain your ${title}`;
    }
    if (!formValues.duration) {
      errors.duration = "Required";
    }
    /* if (title === "Course" && !formValues.sessions) {
      errors.sessions = "Required!";
    } */
    if (formValues.freeCancellation) {
      if (!formValues.cancellationPeriod) {
        errors.cancellationPeriod = "Required!";
      }
    }
    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      setSubmitting(false);
      cogoToast.error("Please fix errors", { hideAfter: 3 });
      return;
    }

    const durationSplit = formValues.durationstring.split(" ");
    const durationInNum =
      Number(durationSplit[0]) * 60 + Number(durationSplit[2]);

    const cancelationPolicy =
      formValues.cancellationPeriod === "1hr"
        ? { isEnabled: true, text: "1 hour", minutes: 60 }
        : formValues.cancellationPeriod === "24hrs"
          ? { isEnabled: true, text: "24 hours", minutes: 1440 }
          : formValues.cancellationPeriod === "48hrs"
            ? { isEnabled: true, text: "48 hours", minutes: 2880 }
            : formValues.cancellationPeriod === "1 week"
              ? { isEnabled: true, text: "1 week", minutes: 10080 }
              : null;

    let body = {
      dataFor: userReduxState.businessType,
      isPackage: profileFor === "course" ? true : false,
      eventCategory: formValues.eventCategory._id,
      catId: formValues.categories._id,
      schoolId: schoolReduxState.schoolId,
      classType: formValues.categories.name,
      className: formValues.className,
      description: formValues.classDescription,
      cost: formValues.price,
      tagLines: formValues.tagLine,
      capacity: formValues.seats,
      duration: durationInNum,
      whatToCarry: formValues.whatToCarry,
      classPhoto: formValues.photo,
      ...(profileFor === "course" && { sessionInPackage: formValues.sessions }),
      isPrivate: formValues.inviteCodeOnlyBooking,
      classCancelationPolicy: formValues.freeCancellation
        ? cancelationPolicy
        : { isEnabled: false },
      paymentAtVenue: formValues.paymentAtVenue,
      isEvent: formValues.isEvent,
      eventPricingList: formValues.eventPricingList,
      multiSessionPassList: formValues.multiSessionPassList,
      isPublic: isPublish,
      isClassPublic: isPublish,
    };

    if (!edit) {
      //add
    } else {
      body = {
        classId: editData._id,
      };
    }
    let config = {};
    if (!edit) {
      config = {
        method: "post",
        url: `${getUrlForClasses()}setClass`,
        headers: {
          "x-access-token": userToken,
        },
        data: body,
      };
    } else {
      config = {
        method: "put",
        url: `${getUrlForClasses()}updateSetClass`,
        headers: {
          "x-access-token": userToken,
        },
        data: body,
      };
    }
    console.log("saveClass", config);
    setLoading(false);
    /* {
      isStartSubmiting: 0,
      createClass: 0,
      createPricing: 0,
      createSchedule: 0,
      changePublishStatus: 0,
      creatingBookingLink: 0,
    } */
    setDataSaveProgress((prevValues) => ({
      ...prevValues,
      isStartSubmiting: true,
      isButtonShow: false,
    }));
    const request = await axios(config);

    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        let classData = request.data.data;
        if (profileFor === "course") {
          dispatch(dashboardActions.setNewCourseDetails(formValues));
          dispatch(dashboardActions.setNewCourseRes(request.data.data));
          dispatch(dashboardActions.setCourseToSchedule(request.data.data));
        } else {
          dispatch(dashboardActions.setNewClassDetails(formValues));
          dispatch(dashboardActions.setNewClassRes(request.data.data));
          dispatch(dashboardActions.setClassToSchedule(request.data.data));
        }
        setSubmitting(false);
        setLoading(false);
        setDataSaveProgress((prevValues) => ({
          ...prevValues,
          createClass: true,
          createPricing: true,
        }));

        /** **/

        console.log("formScheduleValues", formScheduleValues);
        let scheduleBody = { ...formScheduleValues }
        scheduleBody.dataFor = userReduxState.businessType;
        scheduleBody.classId = classData._id;
        scheduleBody.isPublished = true;
        console.log("scheduleBody", scheduleBody);
        const scheduleConfig = {
          method: "post",
          url: `${getUrlForClasses()}scheduleTheClass`,
          headers: {
            "x-access-token": userToken,
          },
          data: scheduleBody,
        };
        const scheduleRequest = await axios(scheduleConfig);
        console.log(scheduleRequest);
        setLoading(false);
        if (scheduleRequest.data.statusCode !== 200) {
          cogoToast.error(scheduleRequest.data.message);
          setSubmitting(false);
          setDataSaveProgress((prevValues) => ({
            ...prevValues,
            isButtonShow: true,
          }));
          return;
        }
        // cogoToast.success("Success!");
        // setFormResData({ ...request.data.data });
        // setSubmitting(false);
        if (scheduleRequest.data.statusCode === 200) {
          setDataSaveProgress((prevValues) => ({
            ...prevValues,
            createSchedule: true,
            changePublishStatus: true,
          }));

          if (classData?.bookingLink && classData?.referenceId) {
            let bookingLink = `${classData.appUrl}${classData.bookingLink}`;
            console.log(bookingLink);
            setDataSaveProgress((prevValues) => ({
              ...prevValues,
              creatingBookingLink: true,
              bookingLink: bookingLink,
            }));
          } else {
            /** this change with uniqueClassName/newReferenceId **/
            let bookingLinkBody = {
              classId: classData._id,
              isPackage: false
            }
            console.log("bookingLinkBody", bookingLinkBody);
            const bookingLinkConfig = {
              method: "post",
              url: `${getUrlForClasses(`users`)}generateBookingViaClassLink`,
              headers: {
                "x-access-token": userToken,
              },
              data: bookingLinkBody,
            };
            const bookingLinkRequest = await axios(bookingLinkConfig);
            console.log(bookingLinkRequest);
            setLoading(false);
            if (bookingLinkRequest.data.statusCode !== 200) {
              cogoToast.error(bookingLinkRequest.data.message);
              setSubmitting(false);
              setDataSaveProgress((prevValues) => ({
                ...prevValues,
                isButtonShow: true,
              }));
              return;
            }
            // cogoToast.success("Success!");
            if (bookingLinkRequest.data.statusCode === 200) {
              let bookingLinkData = bookingLinkRequest.data.data;
              console.log(bookingLinkData);
              setDataSaveProgress((prevValues) => ({
                ...prevValues,
                creatingBookingLink: true,
                bookingLink: bookingLinkData.generatedUrl,
              }));
            }
          }

        }

        // if (edit) {
        //   // clearFormValues();
        //   dispatch(dashboardActions.setCalendarSessionId(-1));
        //   dispatch(dashboardActions.setCalendarOpen(false));
        //   navigate("/home/dashboard");
        //   return;
        // }
        // clearFormValues();
        // openSuccessModal();
        return;
      } else if (request.data.statusCode === 403) {
        cogoToast.error(request.data.message);
        setLoading(false);
        setSubmitting(false);
        setDataSaveProgress((prevValues) => ({
          ...prevValues,
          isButtonShow: true,
        }));
      }
    } else {
      setDataSaveProgress((prevValues) => ({
        ...prevValues,
        isButtonShow: true,
      }));
    }
  };
  const clearFormValues = () => {
    setFormValues(classBodyData);
    setFormScheduleValues({});
  };

  React.useEffect(() => {
    setDataSaveProgress(defaultDataSaveProgress);
  }, []);

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          {title}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"
        >
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabCurrentState}>
              {dataSaveProgress.isButtonShow === true ? (<Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={{
                  fontSize: 18
                }}>
                  <Tab label="General" value="1" />
                  <Tab label="Pricing" value="2" />
                  <Tab label="Passes" value="3" />
                  <Tab label="Policies" value="4" />
                  <Tab label="Schedule" value="5" />
                  <Tab label="Review & Publish" value="6" />
                </TabList>
              </Box>) : ""}
              <TabPanel value="1">
                <GeneralTab
                  profileFor={profileFor}
                  title={title}
                  openSuccessModal={() => setSuccessMgModalOpen(true)}
                  edit={false}
                  editData={null}
                  setLoading={setLoading}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  setTabCurrentState={setTabCurrentState}
                  handleCancel={() => {
                    dispatch(dashboardActions.setCalendarSessionId(-1));
                    dispatch(dashboardActions.setCalendarOpen(false));
                    navigate("/home/dashboard");
                  }}
                />
              </TabPanel>
              <TabPanel value="2">
                <PricingTab
                  profileFor={profileFor}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  setTabCurrentState={setTabCurrentState}
                />
              </TabPanel>
              <TabPanel value="3">
                <PassesTab
                  profileFor={profileFor}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  setTabCurrentState={setTabCurrentState}
                />
              </TabPanel>
              <TabPanel value="4">
                <PoliciesTab
                  formErrors={formErrors}
                  cancel_img={cancel_img}
                  info_i={info_i}
                  formValues={formValues}
                  handleFormValueChange={handleFormValueChange}
                  setFormValues={setFormValues}
                  setTabCurrentState={setTabCurrentState}
                  payment={payment}
                  invite_img={invite_img}
                  handleCancel={() => {
                    setTabCurrentState("3");
                  }}
                />
              </TabPanel>
              <TabPanel value="5">
                <ScheduleTab
                  duration={formValues.duration}
                  classObj={formValues}
                  setLoading={setLoading}
                  formErrors={formErrors}
                  formScheduleValues={formScheduleValues}
                  // handleFormValueChange={handleFormValueChange}
                  setFormScheduleValues={setFormScheduleValues}
                  setTabCurrentState={setTabCurrentState}
                  defaultDataSaveProgress={defaultDataSaveProgress}
                  dataSaveProgress={dataSaveProgress}
                  setDataSaveProgress={setDataSaveProgress}
                  defaultScheduleData={defaultScheduleData}
                  setDefaultScheduleData={setDefaultScheduleData}
                />
              </TabPanel>
              <TabPanel value="6">
                <ReviewPublishTab
                  formValues={formValues}
                  formScheduleValues={formScheduleValues}
                  handleFormValueChange={handleFormValueChange}
                  setTabCurrentState={setTabCurrentState}
                  defaultDataSaveProgress={defaultDataSaveProgress}
                  dataSaveProgress={dataSaveProgress}
                  setDataSaveProgress={setDataSaveProgress}
                />
                {dataSaveProgress.isButtonShow === true ? (
                  <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}>
                    <Button
                      sx={{
                        color: "secondary.mainLight",
                        backgroundColor: "common.white",
                        borderColor: "secondary.mainLight",
                        border: 2,
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "secondary.mainLight",
                          color: "common.white",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 3,
                        width: 231,
                        height: 52,
                        fontSize: 16,
                      }}
                      onClick={() => {
                        setTabCurrentState("5");
                      }}
                    >
                      Back
                    </Button>
                    {/* <Button
                      sx={{
                        color: "common.white",
                        backgroundColor: "secondary.mainLight",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "secondary.mainLight",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 3,
                        width: 231,
                        height: 52,
                        fontSize: 16,
                      }}
                      variant={"contained"}
                      // disabled={isSubmitting}
                      onClick={() => {
                        saveClass(false);
                      }}
                    >
                      Save as Draft
                    </Button> */}
                    <Button
                      sx={{
                        color: "common.white",
                        backgroundColor: "primary.main",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "primary.main",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 3,
                        width: 231,
                        height: 52,
                        fontSize: 16,
                      }}
                      variant={"contained"}
                      // disabled={isSubmitting}
                      onClick={() => {
                        saveClass(true);
                      }}
                    >
                      Create {profileFor.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                        return letter.toUpperCase();
                      })}
                    </Button>
                  </Stack>
                ) : ""}
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </LoadingBackdrop>} />
  );
}
