import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slide,
  Button,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function WarningDialog({ open, text, onClose, onSubmit }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 5,
        },
      }}
    >
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          sx={{ textTransform: "none", borderRadius: 2.5 }}
          onClick={onClose}
        >
          No
        </Button>
        <Button
          variant={"contained"}
          color={"secondary"}
          sx={{ textTransform: "none", borderRadius: 2.5 }}
          onClick={onSubmit}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
