import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

import Container from "../../components/Container";
import Logo from "../../components/Logo";

import undraw_vector from "../../assets/celebration_re_kc9k 1.png";
import { LoadingBackdrop } from "../../layouts";
import axios from "axios";
import { useLocalStorage } from "../../hooks";
import { useSelector } from "react-redux";
import { userState } from "../../redux/reducers/userReducer";
import { useLocation } from "react-router-dom";
import { getUrlForUsers } from "../../utils/confighelpers";

export default function BusinessProfileUpSubscription({ navigation }) {
  const theme = useTheme();
  const location = useLocation();
  const [userToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [loading, setLoading] = React.useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = React.useState([]);


  const getSubscriptionPlan = async () => {
    setLoading(true)
    const requestConfig = {
      method: "get",
      url: `${getUrlForUsers()}getSubscriptionPlans?listFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {
        console.log("data", response.data.data);
        if (response.data.data) {
          setSubscriptionPlan(response.data.data)
          console.log("userReduxState.businessType", userReduxState.businessType);
          if (((location?.state && !location.state.hasOwnProperty("origin")) || location.state?.origin !== "paymentprofile") && userReduxState.businessType === "event") {
            await Promise.all(response.data.data.map(async (d) => {
              if (d.typeOfSubscription === "free") {
                await updateUserSubscription(d._id);
              }
              return d;
            }));
          }
        }
      }
    }
    setLoading(false)
  };

  const updateUserSubscription = async (sid) => {
    setLoading(true)
    const requestConfig = {
      method: "post",
      url: `${getUrlForUsers()}updateUserSubscription?`,
      headers: {
        "x-access-token": userToken,
      },
      data: {
        subscriptionId: sid,
        businessId: userReduxState.userData?._id
      },
    };
    console.log("requestConfig", requestConfig);
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {
        console.log("data", response.data.data);
        navigation("/businessprofilesetup/done");
      }
    }
    setLoading(false)
  };

  React.useEffect(() => {
    getSubscriptionPlan();
  }, []);
  
  // React.useEffect(() => {
  //   if (window && document) {
  //     const script = document.createElement('script')
  //     const body = document.getElementsByTagName('body')[0]
  //     script.src = 'https://js.stripe.com/v3/pricing-table.js'
  //     script.async = true
      
  //     const scriptpricing = document.createElement('stripe-pricing-table')
  //     scriptpricing.setAttribute('pricing-table-id', 'prctbl_1PkNfRDx1lYwRllZPc6xn0SW');
  //     scriptpricing.setAttribute('publishable-key', 'pk_live_51JzKMtDx1lYwRllZ7SopvqoyzlVjtwOrFnal03xTL4NHxRG87ZCZ15Si7hpCCjYlaKvrygZy3Zm94XZ7ALItOvZH00Kxb1Fpni');
  //     // scriptpricing['pricing-table-id'] = 'prctbl_1PkNfRDx1lYwRllZPc6xn0SW'
  //     // scriptpricing['publishable-key'] = 'pk_live_51JzKMtDx1lYwRllZ7SopvqoyzlVjtwOrFnal03xTL4NHxRG87ZCZ15Si7hpCCjYlaKvrygZy3Zm94XZ7ALItOvZH00Kxb1Fpni'
  //     // body.appendChild(script)
  //     // body.appendChild(scriptpricing)
  //   }
  // }, [])

  return (
    <LoadingBackdrop loading={loading}>
      {((location?.state && location.state.hasOwnProperty("origin") && location.state?.origin === "paymentprofile") || userReduxState.businessType !== "event") && (<Box className="min-w-[1024px]">
        <div
          className={
            "h-16 md:h-12 shadow-sm shadow-neutral-200 sm:flex sm:flex-row sm:justify-center sm:items-center"
          }
        >
          <h4
            className={
              "my-8 sm:my-0 md:my-6 mx-32 sm:mx-0 md:mx-16 font-bold text-4xl sm:text-2xl md:text-2xl"
            }
            sx={{ color: "#481B3A" }}
          >
            Choose your plan
          </h4>
        </div>
        <Container className={"mx-32 lg:mx-28 sm:mx-0 md:mx-4"}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "start",
            pt: 5,
            gap: 5,
          }}>

            {subscriptionPlan && subscriptionPlan.map((d, i) => (<Box key={i} className="flex flex-col bg-brown-coffee text-white rounded-3xl p-5 w-80 gap-2">
              <h1 className="font-extrabold text-2xl">{d.title}</h1>
              <p className="text-2xl"><span className="font-bold text-4xl">€{d.price}</span>/mo</p>
              <p className="text-xl text-[#FFFFFF99]">{d.description} </p>
              {d.subscriptionDetail.length && d.subscriptionDetail.map((sd, sdi) => (
                <p className="flex flex-row items-start text-lg gap-3" key={sdi}>
                  <svg className="mt-1 w-5" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="white" />
                  </svg>
                  <p className="flex-1" dangerouslySetInnerHTML={{ __html: sd }}></p>
                </p>
              ))}
              <Button
                sx={{
                  color: "common.white",
                  backgroundColor: "secondary.mainLight",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  fontSize: 16,
                  mt: 3,
                }}
                variant={"contained"}
                onClick={() => {
                  updateUserSubscription(d._id);
                }}>
                {(i > 0) ? "Try for Free" : "Start Free "}
              </Button>
            </Box>))}

          </Box>
        </Container>
      </Box>)}
    </LoadingBackdrop>
  );
}
