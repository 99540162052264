import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import OtpInput from "react-otp-input";
import { useLocation } from "react-router-dom";
import cogoToast from "cogo-toast";

import { useLocalStorage } from "../../hooks";
import * as schoolActions from "../../redux/reducers/schoolReducer";
import { userState } from "../../redux/reducers/userReducer";
import * as userActions from "../../redux/reducers/userReducer";

import Logo from "../../components/Logo";
import { LoadingBackdrop } from "../../layouts";

import undraw_vector from "../../assets/undraw_login_re_4vu2 1.png";
import { getUrlForClasses, getUrlForUsers } from "../../utils/confighelpers";

export default function VerifyEmail({ navigation }) {
  const location = useLocation();
  const userReduxState = useSelector(userState);
  const [userToken] = useLocalStorage("userToken");
  const [otp, setOtp] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [resendEmailSubmitting, setResendEmailSubmitting] =
    React.useState(false);
  const [error, setError] = React.useState("");
  const [emailToken, setEmailToken] = React.useState(
    location?.state?.response?.data?.token
  );
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const dispatch = useDispatch();

  const handleChange = (otp) => setOtp(otp);

  const handleWrongEmail = () => {
    // navigation("/schoolprofilesetup", {
    navigation("/businessprofilesetup", {
      state: {
        ...location.state,
        // businessTypes: location.state.businessTypes,
        // businessProfileType: location.state.businessProfileType,
        // logo: location.state.logo,
        // coverPhoto: location.state.coverPhoto,
        // categories: location.state.categories,
        // instituteName: location.state.name,
        // address: location.state.address,
        // contactEmail: location.state.email,
        // website: location.state.website,
        // aboutInstitute: location.state.about,
        // socialLink: location.state.socialLink,
      },
    });
  };

  const handleResendEmail = () => {
    setResendEmailSubmitting(true);
    cogoToast.loading("Loading...").then(async () => {
      const body1 = {
        selectedCategories: location.state.categories,
      };
      const config1 = {
        method: "put",
        url: `${getUrlForUsers()}updateProfile`,
        headers: {
          "x-access-token": userToken.toString(),
        },
        data: body1,
      };
      const requestCategories = await axios(config1);
      if (requestCategories.status === 200) {
        // const body2 = {
        //   email: location.state.email,
        // };
        const body2 = location.state !== null && location.state?.socialType && location.state.socialType !== "normal" ? {
          countryCode: location.state.countryCode,
          phoneNumber: location.state.phone,
          // phoneNumber: values.contactNumber,
        } : {
          email: location.state.email,
        };
        const config2 = {
          method: "put",
          url: `${getUrlForUsers()}updateProfile`,
          headers: {
            "x-access-token": userToken.toString(),
          },
          data: body2,
        };
        const requestEmail = await axios(config2);
        if (requestEmail.status === 200) {
          if (requestEmail.data.statusCode === 403) {
            cogoToast.error(requestEmail.data.message);
            setResendEmailSubmitting(false);
            return;
          } else if (requestEmail.data.statusCode !== 200) {
            cogoToast.error(requestEmail.data.message);
            setResendEmailSubmitting(false);
            return;
          }
          cogoToast.success("Success!");
          setEmailToken(requestEmail.data.data.token);
          setResendEmailSubmitting(false);
        } else {
          setResendEmailSubmitting(false);
          console.log(requestEmail);
        }
      } else {
        setResendEmailSubmitting(false);
        console.log(requestCategories);
      }
    });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    setError(false);
    setLoading(true);
    cogoToast.loading("Loading...").then(async () => {
      const body1 = {
        code: otp,
        token: emailToken,
      };
      const config1 = {
        method: "put",
        url: `${getUrlForUsers()}verifyPrimaryFieldsOtpAndUpdate`,
        headers: {
          "x-access-token": userToken.toString(),
        },
        data: body1,
      };
      const requestEmailVerification = await axios(config1);
      if (requestEmailVerification.data?.statusCode === 500) {
        cogoToast.error(requestEmailVerification.data?.message);
        setSubmitting(false);
        setError(true);
        setLoading(false);
        return;
      } else if (requestEmailVerification.data.statusCode === 201) {
        cogoToast.error(requestEmailVerification.data?.message);
        setSubmitting(false);
        setError(true);
        setLoading(false);
        return;
      }
      const {
        logo,
        coverPhoto,
        businessTypes,
        businessProfileType,
        name,
        email,
        website,
        about,
        /* locationObj, */ postAddressConfig,
        socialLink,
      } = location.state;
      let locationObj = {};
      const addLocationRequest = await axios(postAddressConfig);
      if (addLocationRequest.data.statusCode !== 200) {
        // if (addLocationRequest.data.statusCode === 500) {
        //   cogoToast.error(addLocationRequest.data.message);
        //   console.log(addLocationRequest);
        //   setSubmitting(false);
        //   setLoading(false);
        //   return;
        // }
        // cogoToast.error("Error adding location.");
        // console.log(addLocationRequest);
        // setSubmitting(false);
        // setLoading(false);
        // return;
      } else {
        locationObj = addLocationRequest.data.data;
      }
      const body2 = {
        myBusinessProfile: {
          businessName: name,
          businessTypes: businessTypes,
          businessProfileType: businessProfileType,
          logo,
          images: [coverPhoto],
          about,
          locationObj,
          typeOfBusiness: "",
          workNumber: location.state?.formattedPhoneNumber || "",
          workEmail: email,
          workWebsite: website,
          selectedView: userReduxState.businessType,
          socialLink: socialLink,
          address: postAddressConfig.data,
        },
        onBoardingStep: 2
      };
      const requestAccountStepUp = await axios.put(
        `${getUrlForUsers()}updateProfile`,
        body2,
        { headers: { "x-access-token": userToken } }
      );
      setLoading(false);
      if (requestAccountStepUp.status === 200) {
        if (requestAccountStepUp.data.statusCode === 200) {
          dispatch(userActions.setUserData(requestAccountStepUp.data.data));
          dispatch(
            schoolActions.setName(body2.businessName)
          );
          dispatch(
            schoolActions.setLogo(body2.logo)
          );
          cogoToast.success("Success!").then(() => {
            // navigation("/schoolprofilesetup/done", {
            // let nextUrl = "/businessprofilesetup/done"
            let nextUrl = "/businessprofilesetup/subscription"
            if (userReduxState.businessType === "service") {
              nextUrl = "/myvenue/add"
            }
            navigation(nextUrl, {
              state: {
                from: "registration",
                sentFromVerifyEmail: true,
                address: locationObj
              },
            })
          }
          );
        }
      }

      // const body2 = {
      //   schoolName: name,
      //   businessTypes: businessTypes,
      //   businessProfileType: businessProfileType,
      //   logo,
      //   images: [coverPhoto],
      //   about,
      //   locationObj,
      //   typeOfBusiness: "",
      //   workNumber: location.state?.formattedPhoneNumber || "",
      //   workEmail: email,
      //   workWebsite: website,
      //   selectedView: userReduxState.businessType,
      //   socialLink: socialLink,
      //   // socialLink: {
      //   //   facebook: socialLink?.facebook || "",
      //   //   instagram: socialLink?.instagram || "",
      //   //   youtube: socialLink?.youtube || "",
      //   //   linkedIn: socialLink?.linkedIn || "",
      //   //   twitter: socialLink?.twitter || "",
      //   // },
      // };
      // const config2 = {
      //   method: "post",
      //   url: `${getUrlForClasses()}addSchoolInformaton`,
      //   headers: {
      //     "x-access-token": userToken.toString(),
      //   },
      //   data: body2,
      // };
      // const requestAddSchoolInfo = await axios(config2);
      // if (requestAddSchoolInfo.data.statusCode === 500) {
      //   cogoToast.error(requestAddSchoolInfo.data.message).then(() => {
      //     setSubmitting(false);
      //     setLoading(false);
      //     navigation(-1);
      //     return;
      //   });
      // } else if (requestAddSchoolInfo.data.statusCode === 200) {
      //   const requestAccountStepUp = await axios.put(
      //     `${getUrlForUsers()}updateProfile`,
      //     { onBoardingStep: 2 },
      //     { headers: { "x-access-token": userToken } }
      //   );
      //   setLoading(false);
      //   console.log("requestAccountStepUp", requestAccountStepUp);
      //   if (requestAccountStepUp.status === 200) {
      //     if (requestAccountStepUp.data.statusCode === 200) {
      //       dispatch(userActions.setUserData(requestAccountStepUp.data.data));
      //       dispatch(
      //         schoolActions.setName(requestAddSchoolInfo.data.data.schoolName)
      //       );
      //       dispatch(
      //         schoolActions.setLogo(requestAddSchoolInfo.data.data.logo)
      //       );
      //       cogoToast.success("Success!").then(() => {
      //         // navigation("/schoolprofilesetup/done", {
      //         // let nextUrl = "/businessprofilesetup/done"
      //         let nextUrl = "/businessprofilesetup/subscription"
      //         if (userReduxState.businessType === "service") {
      //           nextUrl = "/myvenue/add"
      //         }
      //         navigation(nextUrl, {
      //           state: {
      //             from: "registration",
      //             sentFromVerifyEmail: true,
      //             address: locationObj
      //           },
      //         })
      //       }
      //       );
      //     }
      //   }
      // }
    });
  };

  if (location.state === null) {
    navigation("/signup");
  }

  console.log("location.state VerifyEmail.js", location.state);
  return (
    <LoadingBackdrop loading={loading}>
      <Logo className={"md:mx-4"} />
      <Box className={"grid grid-cols-2 sm:grid-cols-1"}>
        <img
          className={"pt-16 sm:hidden md:w-[100%] md:h-[100%]"}
          src={undraw_vector}
          alt={"vector"}
        />
        <Box className={"grid grid-cols-1 /*grid-rows-8*/ place-items-center"}>
          <h4
            className={
              "text-4xl sm:text-2xl md:text-2xl pb-3 md:pb-1 font-bold"
            }
            style={{ color: "#481B3A" }}
          >
            Verify Email
          </h4>
          <Box className={"text-center"}>
            <p className={"sm:text-sm md:text-sm"}>
              Please enter the 4 digit code sent to <br />
              <span style={{ color: "#FF5733" }}>{location.state.email}</span>
            </p>
          </Box>
          {error && (
            <Typography
              sx={{ fontSize: isMobile ? 14 : isTablet ? 13 : 17, mt: "5%" }}
              color={"red"}
            >
              Incorrect Code, Please try again.
            </Typography>
          )}
          <OtpInput
            containerStyle={{
              marginTop: !error ? "15%" : "7.5%",
              marginBottom: "10%",
              marginRight: isMobile ? 0 : isTablet ? 0 : 25,
              marginLeft: isMobile ? 0 : isTablet ? 0 : 25,
            }}
            inputStyle={{
              width: isMobile ? 50 : isTablet ? 50 : 75,
              height: isMobile ? 50 : isTablet ? 50 : 75,
              borderRadius: 6.48248,
              borderWidth: 1.2965,
              borderColor: "#BFD1E5",
              borderStyle: "solid",
              fontSize: isMobile ? "1.5em" : isTablet ? "1em" : "2em",
            }}
            errorStyle={{
              borderColor: "red",
            }}
            value={otp}
            onChange={handleChange}
            numInputs={4}
            separator={<Box sx={{ mx: 1.5 }} />}
            hasErrored={error}
            shouldAutoFocus
            isInputNum
          />
          <p
            className={
              "flex flex-row justify-center items-center text-base sm:text-sm md:text-sm"
            }
          >
            Didn't receive a code?{" "}
            <Button
              onClick={handleResendEmail}
              disabled={resendEmailSubmitting}
              variant={"text"}
              sx={{ color: "#9800FF", textTransform: "none" }}
            >
              <p className={"text-base sm:text-sm md:text-sm"}>Resend Email</p>
            </Button>
          </p>
          <Box className={"-mt-2 mb-4"}>
            <Button
              onClick={handleWrongEmail}
              variant={"text"}
              sx={{ color: "black", textTransform: "none" }}
            >
              <p
                className={
                  "text-center text-base sm:text-sm md:text-sm text-[#ff5733]"
                }
              >
                Wrong Email
              </p>
            </Button>
          </Box>
          <Box className={"place-items-center"}>
            <Button
              variant={"contained"}
              sx={{
                backgroundColor: "#481B3A",
                textTransform: "none",
                borderRadius: 3,
                width: isMobile ? 278 : isTablet ? 278 : 478,
                height: isMobile ? 56 : isTablet ? 56 : 64,
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#481B3A",
                },
              }}
              disabled={submitting}
              onClick={handleSubmit}
            >
              <p className={"text-xl sm:text-base md:text-base"}>
                Verify Email
              </p>
            </Button>
          </Box>
          <p
            className={
              "text-center text-gray-500 mt-6 sm:text-sm sm:mx-2 md:text-sm"
            }
          >
            By continuing you declare that all information{!isMobile && <br />}{" "}
            provided by you is accurate
          </p>
        </Box>
      </Box>
    </LoadingBackdrop>
  );
}
