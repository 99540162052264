import { Box, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { userState } from "../../../redux/reducers/userReducer";


import { useLocation } from "react-router-dom";
import ScheduleTile from "./ScheduleTile";
import { getUrlForBookings, getUrlForServices } from "../../../utils/confighelpers";


export default function UpcomingServicesActivites({
    navigation,
    loading,
    setLoading
}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [userToken] = useLocalStorage("userToken");
    const userReduxState = useSelector(userState);

    const [upcomingActivitesList, setUpcomingActivitesList] = React.useState([]);

    const getUpcomingActivites = async () => {
        // setLoading(true);
        const date = new Date().toISOString();
        const config = {
            method: "get",
            url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&page=1&limit=2&isUpcomingActivites=true`,
            headers: {
                "x-access-token": userToken.toString(),
            },
        };
        const manageClassesRequest = await axios(config);
        if (manageClassesRequest.status === 200) {
            if (manageClassesRequest.data.data.data.length) {
                setUpcomingActivitesList(manageClassesRequest.data.data.data);
            }
        } else {
            setLoading(false);
        }
    };

    const handleSessionClick = async (id, serviceBookingData) => {

        // cogoToast.loading("Loading session details " + id);
        cogoToast.loading("Loading...");
        dispatch(dashboardActions.setServiceBookingData(serviceBookingData));
        // navigate("/myservices/schedule/detail");
        navigate("/myservices/schedule/detail", {
            state: {
                bookingId: id
            },
        })
    };

    React.useEffect(() => {
        getUpcomingActivites();
    }, []);


    return (
        <div
            className={`max-w-[1221px] px-5 py-2 pb-10 bg-white rounded-2xl mt-16`}
        >
            <div
                className={"flex flex-row justify-between items-center my-4"}
            >
                <h3
                    className={
                        "font-extrabold text-brown-coffee text-lg sm:text-base md:text-base"
                    }
                >
                    Upcoming Activites
                </h3>
                <p
                    className={
                        "font-extrabold text-portland-orange text-sm cursor-pointer"
                    }
                    onClick={(e) => {
                        navigate("/myschedule", { replace: true });
                    }}
                >
                    View all
                </p>
            </div>
            {loading ? (
                <div className={"h-28 w-[574px] grid place-items-center"}>
                    <CircularProgress color={"secondary"} />
                </div>
            ) : Array.isArray(upcomingActivitesList) &&
                upcomingActivitesList.length > 0 ? (
                <div
                    className={
                        // "grid grid-cols-2 sm:grid-cols-1 sm:place-items-center 3xl:grid-cols-3 gap-4"
                        // "grid grid-cols-[600px_600px] gap-4"
                        "grid grid-cols-2 gap-4"
                    }
                >
                    {upcomingActivitesList.map((item, idx) => {
                        const modifiedData = {
                            id: item._id,
                            item: item,
                        };
                        return (
                            <div
                                key={idx}
                                className={"cursor-pointer"}
                            // onClick={() => handleSessionClick(modifiedData.id, item)}
                            >
                                <ScheduleTile
                                    {...modifiedData}
                                    key={idx}
                                    handleSessionClick={() => {
                                        handleSessionClick(modifiedData.id, item);
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            ) : (
                <div className={`h-48 flex justify-center items-center`}>
                    {upcomingActivitesList.length ? (<p className={"text-2xl"}>
                        Your upcoming sessions will appear here
                    </p>) : (
                        <Box className="flex flex-col justify-center items-center w-full">
                            <Box className="text-2xl" sx={{
                                color: "#481B3A",
                                mt: 5,
                            }}>
                                No Upcoming Activites Configured
                            </Box>
                            <Button
                                sx={{
                                    color: "common.white",
                                    backgroundColor: "secondary.main",
                                    boxShadow: "none",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "secondary.main",
                                        boxShadow: "none",
                                    },
                                    fontWeight: 600,
                                    borderRadius: 3,
                                    width: 231,
                                    height: 52,
                                    fontSize: 16,
                                    mt: 2,
                                }}
                                variant={"contained"}
                                onClick={() => {
                                    navigate("/myservices/new");
                                }}
                            >
                                Add a new Service
                            </Button>
                        </Box>
                    )}
                </div>
            )}
        </div>
    );
}
