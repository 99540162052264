export const API_URL = {
     local: {
          stage: "http://localhost:4000",
          'dev/schools': "http://localhost:4000",
          'dev/event': "http://localhost:4000",
          'dev/class': "http://localhost:4000",
          evnts: "http://localhost:4001",
          users: "http://localhost:4002",
          services: "http://localhost:4003",
          bookings: "http://localhost:4004",
          foods: "http://localhost:4005",
     },
     dev: "https://bmcapi-dev.bookzy.app",
     prod: "https://bmcapi-prod.bookzy.app",
};

export const WEBSHOP_URL = {
     local: {
          event: "https://event-dev.bookzyme.com",
          services: "https://event-dev.bookzyme.com",
          class: "https://event-dev.bookzyme.com",
          foods: "https://event-dev.bookzyme.com",
     },
     dev: {
          event: "https://event-dev.bookzyme.com",
          services: "https://event-dev.bookzyme.com",
          class: "https://event-dev.bookzyme.com",
          foods: "https://event-dev.bookzyme.com",
     },
     prod: {
          event: "https://events.bookzyme.com",
          services: "https://webshop.bookzyme.com",
          class: "https://webshop.bookzyme.com",
          foods: "https://webshop.bookzyme.com",
     },
};

export const CURRENT_ENV = process.env?.REACT_APP_APP_ENV || "dev";

export const getUrlForModule = (modulePath, forwardSlash = true) => {
     if (CURRENT_ENV == 'local') {
          return `${API_URL[CURRENT_ENV][modulePath]}/dev${forwardSlash ? `/` : ``}`
     }
     return `${API_URL[CURRENT_ENV]}/${modulePath}${forwardSlash ? `/` : ``}`
}

export const getUrlForStage = () => {
     return getUrlForModule(CURRENT_ENV)
}

export const getUrlForEvent = () => {
     return getUrlForModule(`evnts`)
}

export const getUrlForUsers = () => {
     return getUrlForModule(`users`)
}

export const getUrlForClasses = (path = 'schools') => {
     return getUrlForModule(`${CURRENT_ENV || 'dev'}/${path}`)
}

export const getUrlForServices = () => {
     return getUrlForModule(`services`)
}

export const getUrlForBookings = () => {
     return getUrlForModule(`bookings`)
}

export const getUrlForFoods = () => {
     return getUrlForModule(`foods`)
}

export const getWebshopUrlForModule = (modulePath) => {
     return `${WEBSHOP_URL[CURRENT_ENV][modulePath]}/`
}

export const getWebshopUrlForEvent = () => {
     return getWebshopUrlForModule(`event`)
}