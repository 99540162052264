import { Remove } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, InputBase, Stack, ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import NeewSidebar from "../../../components/NewSideBar";
import { useLocalStorage } from "../../../hooks";
import {
  LoadingBackdrop
} from "../../../layouts";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";
import * as schoolActions from "../../../redux/reducers/schoolReducer";
import { schoolState } from "../../../redux/reducers/schoolReducer";
import BusinessProfileForm from "./BusinessProfileForm";
import BusinessProfileImgInput from "./BusinessProfileImgInput";

import facebook_img from "../../../assets/Facebook F.png";
import instagram_img from "../../../assets/Instagram.png";
import linkedIn_img from "../../../assets/LinkedIn 2.png";
import progressdone from "../../../assets/progressdone.svg";
import twitter_img from "../../../assets/Twitter.png";
import youtube_img from "../../../assets/YouTube.png";
import OtpModal from "../../../components/OtpModal";
import { getUrlForClasses, getUrlForUsers } from "../../../utils/confighelpers";

export default function EditBusinessProfile({ navigation }) {
  const navigate = useNavigate();
  const [showCalendar, setShowCalendar] = React.useState(false);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const dashboardReduxState = useSelector(dashboardState);
  const schoolReduxState = useSelector(schoolState);
  const [userToken] = useLocalStorage("userToken");
  const schoolInfo = dashboardReduxState.schoolData;
  const businessProfileInfo = {
    ...schoolInfo,
    socialLinksSelected: [],
  }

  const [contactAddress, setContactAddress] = React.useState(
    location.state !== null ? location.state.address : (schoolInfo?.address || {})
  );
  const [postAddressConfig, setPostAddressConfig] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [otpTitle, setOtpTitle] = React.useState("");
  const [otpSubTitle, setOtpSubTitle] = React.useState("");
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  // console.log("schoolInfo", schoolInfo);
  const formik = useFormik({
    initialValues: {
      ...businessProfileInfo
    },
    validate: (values) => {
      const errors = {};
      /* if (values.businessTypes && Object.keys(values.businessTypes).length === 0) {
        errors.businessTypes = "Required!";
      } */
      // if (!values.schoolName) {
      //   errors.schoolName = "Required!";
      // }
      if (!values.businessName) {
        errors.businessName = "Required!";
      }
      if (!values.address) {
        errors.address = "Required!";
      }
      if (!values.workEmail) {
        errors.workEmail = "Required!";
      }
      if (!values.about) {
        errors.about = "Required!";
      } else if (values.about.length < 5) {
        errors.about = `Enter at least 5 letters to explain your ${values.businessName}`;
      }
      /* if (!values.coverPhoto) {
        errors.coverPhoto = "Required!";
      } */
      /* if (Array.isArray(values.categories) && !values.categories?.length) {
        errors.categories = "Required!";
      } */
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      cogoToast.loading("Loading...").then(async () => {
        if (
          values.businessName !== dashboardReduxState.schoolData.businessName &&
          !isDialogShow
        ) {
          setLoading(false);
          setShowNameChangedModal(true);
          return;
        }
        console.log("workEmail", values.workEmail !== dashboardReduxState.schoolData.workEmail);
        console.log("workNumber", values.workNumber !== dashboardReduxState.schoolData.workNumber);
        if (values.workEmail !== dashboardReduxState.schoolData.workEmail || values.workNumber !== dashboardReduxState.schoolData.workNumber) {
          let updateProfileBody = {
            email: values.workEmail,
          };
          setOtpTitle("Verify Email");
          setOtpSubTitle(values.workEmail);
          if (values.workNumber !== dashboardReduxState.schoolData.workNumber) {
            setOtpTitle("Verify Number");
            setOtpSubTitle(values.workNumber);
            updateProfileBody = {
              countryCode: values.countryCode,
              phoneNumber: values.phone,
            }
          }
          const updateProfileConfig = {
            method: "put",
            url: `${getUrlForUsers()}updateProfile`,
            headers: {
              "x-access-token": userToken,
            },
            data: updateProfileBody,
          };
          const updateProfileRequest = await axios(updateProfileConfig);
          if (updateProfileRequest.data.statusCode === 200) {
            setOtpToken(updateProfileRequest.data.data.token);
            handleOpenOtpModal();
            return;
          }
          return;
        }
        await updateProfileForAll(values);
        return false; /** new user myBusinessProfile update **/

        if (false && locationChanged) {
          setLoading(true);
          
          const schoolUpdateBody = {
            // schoolId: values._id,
            // businessName: values.businessName,
            // logo: values.logo,
            // images: values.images,
            // about: values.about,
            // typeOfBusiness: "",
            // workNumber: values.workNumber,
            // workEmail: values.workEmail,
            // workWebsite: values.workWebsite,
            // locationObj: { ...updateLocationRequest.data.data },
            // socialLink: {
            //   ...values.socialLink,
            // },
            // businessTypes: values.businessTypes,
            // businessProfileType: values.businessProfileType,
            myBusinessProfile: {
              businessName: values.businessName,
              businessTypes: values.businessTypes,
              businessProfileType: values.businessProfileType,
              logo: values.logo,
              images: values.images,
              about: values.about,
              typeOfBusiness: "",
              workNumber: values.workNumber,
              workEmail: values.workEmail,
              workWebsite: values.workWebsite,
              socialLink: {
                ...values.socialLink,
              },
              address: { ...(updateLocationConfig?.data ? updateLocationConfig.data : (schoolInfo?.address || {})) },
            },
          };
          const schoolUpdateConfig = {
            method: "put",
            // url: `${getUrlForClasses()}updateSchool`,
            url: `${getUrlForUsers()}updateProfile`,
            headers: {
              "x-access-token": userToken,
            },
            data: schoolUpdateBody,
          };
          const schoolUpdateRequest = await axios(schoolUpdateConfig);
          setLoading(false);
          setIsThereChange(true);
          if (schoolUpdateRequest.status === 200) {
            if (schoolUpdateRequest.data.statusCode === 200) {
              dispatch(schoolActions.setName(values.businessName));
              dispatch(schoolActions.setLogo(values.logo));
              dispatch(schoolActions.setCover(values.images[0]));
              dispatch(
                dashboardActions.setSchoolData(
                  schoolUpdateRequest.data.data
                )
              );
              cogoToast.success("Updated Successfully");
              dispatch(dashboardActions.setCalendarSessionId(-1));
              dispatch(dashboardActions.setCalendarOpen(false));
              navigate("/businessprofile/view");
              return;
            } else {
              setLoading(false);
              cogoToast.error(schoolUpdateRequest.data.message);
              console.log(schoolUpdateRequest);
              return;
            }
          }
          // const updateLocationRequest = await axios(updateLocationConfig);
          // if (updateLocationRequest.status === 200) {
          //   if (updateLocationRequest.data.statusCode === 200) {
          //     const categoriesBody = {
          //       seletedCategories: categories,
          //     };
          //     const categoriesConfig = {
          //       method: "put",
          //       url: `${getUrlForUsers()}updateProfile`,
          //       headers: {
          //         "x-access-token": userToken.toString(),
          //         "Access-Control-Allow-Headers": "*",
          //       },
          //       data: categoriesBody,
          //     };
          //     const categoriesRequest = await axios(categoriesConfig);
          //     setIsThereChange(true);
          //     if (categoriesRequest.status === 200) {
          //       if (categoriesRequest.data.statusCode === 200) {
          //         dispatch(
          //           schoolActions.setCategories(
          //             categoriesRequest.data.data.seletedCategories
          //           )
          //         );
          //         const schoolUpdateBody = {
          //           // schoolId: values._id,
          //           // businessName: values.businessName,
          //           // logo: values.logo,
          //           // images: values.images,
          //           // about: values.about,
          //           // typeOfBusiness: "",
          //           // workNumber: values.workNumber,
          //           // workEmail: values.workEmail,
          //           // workWebsite: values.workWebsite,
          //           // locationObj: { ...updateLocationRequest.data.data },
          //           // socialLink: {
          //           //   ...values.socialLink,
          //           // },
          //           // businessTypes: values.businessTypes,
          //           // businessProfileType: values.businessProfileType,
          //           myBusinessProfile: {
          //             businessName: values.businessName,
          //             businessTypes: values.businessTypes,
          //             businessProfileType: values.businessProfileType,
          //             logo: values.logo,
          //             images: values.images,
          //             about: values.about,
          //             locationObj: { ...updateLocationRequest.data.data },
          //             typeOfBusiness: "",
          //             workNumber: values.workNumber,
          //             workEmail: values.workEmail,
          //             workWebsite: values.workWebsite,
          //             socialLink: {
          //               ...values.socialLink,
          //             },
          //             address: { ...updateLocationRequest.data.data },
          //           },
          //         };
          //         const schoolUpdateConfig = {
          //           method: "put",
          //           // url: `${getUrlForClasses()}updateSchool`,
          //           url: `${getUrlForUsers()}updateProfile`,
          //           headers: {
          //             "x-access-token": userToken,
          //           },
          //           data: schoolUpdateBody,
          //         };
          //         const schoolUpdateRequest = await axios(schoolUpdateConfig);
          //         setLoading(false);
          //         setIsThereChange(true);
          //         if (schoolUpdateRequest.status === 200) {
          //           if (schoolUpdateRequest.data.statusCode === 200) {
          //             dispatch(schoolActions.setName(values.businessName));
          //             dispatch(schoolActions.setLogo(values.logo));
          //             dispatch(schoolActions.setCover(values.images[0]));
          //             dispatch(
          //               dashboardActions.setSchoolData(
          //                 schoolUpdateRequest.data.data
          //               )
          //             );
          //             cogoToast.success("Updated Successfully");
          //             dispatch(dashboardActions.setCalendarSessionId(-1));
          //             dispatch(dashboardActions.setCalendarOpen(false));
          //             navigate("/businessprofile/view");
          //             return;
          //           } else {
          //             setLoading(false);
          //             cogoToast.error(schoolUpdateRequest.data.message);
          //             console.log(schoolUpdateRequest);
          //             return;
          //           }
          //         }
          //       } else {
          //         setLoading(false);
          //         cogoToast.error(categoriesRequest.data.message);
          //         console.log(categoriesRequest);
          //         return;
          //       }
          //     }
          //   } else {
          //     setLoading(false);
          //     cogoToast.error("Cannot update location, please select another");
          //     console.log(updateLocationRequest);
          //     return;
          //   }
          // }
        }
        setLoading(true);
        const schoolUpdateBody = {
          // schoolId: values._id,
          // businessName: values.businessName,
          // logo: values.logo,
          // images: values.images,
          // about: values.about,
          // typeOfBusiness: "",
          // workEmail: values.workEmail,
          // workWebsite: values.workWebsite,
          // locationObj: values.locationObj,
          // socialLink: {
          //   ...values.socialLink,
          // },
          // businessTypes: values.businessTypes,
          // businessProfileType: values.businessProfileType,
          myBusinessProfile: {
            businessName: values.businessName,
            businessTypes: values.businessTypes,
            businessProfileType: values.businessProfileType,
            logo: values.logo,
            images: values.images,
            about: values.about,
            locationObj: values.locationObj,
            typeOfBusiness: "",
            workNumber: values.workNumber,
            workEmail: values.workEmail,
            workWebsite: values.workWebsite,
            socialLink: {
              ...values.socialLink,
            },
            // address: values.locationObj,
            address: { ...(updateLocationConfig?.data ? updateLocationConfig.data : (schoolInfo?.address || {})) },
          },
        };
        console.log("schoolUpdateBody", schoolUpdateBody);
        const schoolUpdateConfig = {
          method: "put",
          // url: `${getUrlForClasses()}updateSchool`,
          url: `${getUrlForUsers()}updateProfile`,
          headers: {
            "x-access-token": userToken,
          },
          data: schoolUpdateBody,
        };
        const schoolUpdateRequest = await axios(schoolUpdateConfig);
        setLoading(false);
        setIsThereChange(true);
        if (schoolUpdateRequest.status === 200) {
          if (schoolUpdateRequest.data.statusCode === 200) {
            dispatch(schoolActions.setName(values.businessName));
            dispatch(schoolActions.setLogo(values.logo));
            dispatch(schoolActions.setCover(values.images[0]));
            dispatch(
              dashboardActions.setSchoolData(
                schoolUpdateRequest.data.data
              )
            );
            cogoToast.success("Updated Successfully");
            dispatch(dashboardActions.setCalendarSessionId(-1));
            dispatch(dashboardActions.setCalendarOpen(false));
            navigate("/businessprofile/view");
            return;
          } else {
            setLoading(false);
            cogoToast.error(schoolUpdateRequest.data.message);
            console.log(schoolUpdateRequest);
            return;
          }
        } else {
          setLoading(false);
          console.log(
            "ERROR SUBMIT FORM, SCHOOL UPDATE:",
            schoolUpdateRequest.data
          );
        }
      });
    },
  });
  const [defaultSocialLinks, setDefaultSocialLinks] = React.useState(
    [
      {
        name: "facebook",
        icon: facebook_img,
        placeholder: "facebook.com/mypage"
      },
      {
        name: "instagram",
        icon: instagram_img,
        placeholder: "instagram.com/mypage"
      },
      {
        name: "linkedIn",
        icon: linkedIn_img,
        placeholder: "linkedin.com/in/myprofile"
      },
      {
        name: "twitter",
        icon: twitter_img,
        placeholder: "twitter.com/mypage"
      },
      {
        name: "youtube",
        icon: youtube_img,
        placeholder: "youtube.com/mychannel"
      }]);
  const [socialLinksSelected, setSocialLinksSelected] = React.useState([]);
  const [isThereChange, setIsThereChange] = React.useState(true);
  const [openOtpModal, setOpenOtpModal] = React.useState(false);
  const [otpToken, setOtpToken] = React.useState("");
  const [showNameChangedModal, setShowNameChangedModal] = React.useState(false);
  const [isDialogShow, setDialogShow] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [updateLocationConfig, setUpdateLocationConfig] = React.useState({});
  const [locationChanged, setLocationChanged] = React.useState(false);

  React.useEffect(() => {
    const { facebook, instagram, linkedIn, twitter, youtube } =
      schoolInfo?.socialLink;
    const socialArray = [];
    if (facebook) {
      socialArray.push("facebook");
    }
    if (instagram) {
      socialArray.push("instagram");
    }
    if (linkedIn) {
      socialArray.push("linkedIn");
    }
    if (twitter) {
      socialArray.push("twitter");
    }
    if (youtube) {
      socialArray.push("youtube");
    }


    // setAddress({ ...dashboardReduxState.schoolData.locationObj });

    setSocialLinksSelected([...socialArray]);
    formik.setValues({
      ...businessProfileInfo,
      logo: schoolInfo.logo,
      coverPhoto: businessProfileInfo.images[0],
      businessTypes: schoolInfo?.businessTypes ? schoolInfo?.businessTypes : {},
      businessProfileType: schoolInfo?.businessProfileType ? schoolInfo?.businessProfileType : "individual",
      socialLinksSelected: socialArray
    });
    setTimeout(() => {
      setIsThereChange(true)
    }, 1 * 1000);
  }, [])

  React.useEffect(() => {
    // console.log("isThereChange", isThereChange);
    // console.log("!(formik.isValid && formik.dirty) && !formik.isSubmitting && isThereChange", (!(formik.isValid && formik.dirty) && !formik.isSubmitting) || isThereChange);
    setIsThereChange(false)
  }, [formik.values])

  React.useEffect(() => {
    setCategories([...schoolReduxState.schoolCategories]);
  }, [schoolReduxState]);

  const handleOpenOtpModal = () => { setLoading(false); setOpenOtpModal(true); }
  const handleCloseOtpModal = () => { setLoading(false); setOpenOtpModal(false); };
  const updateProfileForAll = async (values) => { 
    
    const schoolUpdateBody = {
      myBusinessProfile: {
        businessName: values.businessName,
        businessTypes: values.businessTypes,
        businessProfileType: values.businessProfileType,
        logo: values.logo,
        images: values.images,
        about: values.about,
        locationObj: values.locationObj,
        typeOfBusiness: "",
        workNumber: values.workNumber,
        workEmail: values.workEmail,
        workWebsite: values.workWebsite,
        socialLink: {
          ...values.socialLink,
        },
        // address: values.locationObj,
        address: { ...(updateLocationConfig?.data ? updateLocationConfig.data : (schoolInfo?.address || {})) },
      },
    };
    console.log("schoolUpdateBody", schoolUpdateBody);
    const schoolUpdateConfig = {
      method: "put",
      // url: `${getUrlForClasses()}updateSchool`,
      url: `${getUrlForUsers()}updateProfile`,
      headers: {
        "x-access-token": userToken,
      },
      data: schoolUpdateBody,
    };
    const schoolUpdateRequest = await axios(schoolUpdateConfig);
    setLoading(false);
    setIsThereChange(true);
    if (schoolUpdateRequest.status === 200) {
      if (schoolUpdateRequest.data.statusCode === 200) {
        dispatch(schoolActions.setName(values.businessName));
        dispatch(schoolActions.setLogo(values.logo));
        dispatch(schoolActions.setCover(values.images[0]));
        dispatch(
          dashboardActions.setSchoolData(
            schoolUpdateRequest.data.data
          )
        );
        cogoToast.success("Updated Successfully");
        dispatch(dashboardActions.setCalendarSessionId(-1));
        dispatch(dashboardActions.setCalendarOpen(false));
        navigate("/businessprofile/view");
        return;
      } else {
        setLoading(false);
        cogoToast.error(schoolUpdateRequest.data.message);
        console.log(schoolUpdateRequest);
        return;
      }
    } else {
      setLoading(false);
      console.log(
        "ERROR SUBMIT FORM, SCHOOL UPDATE:",
        schoolUpdateRequest.data
      );
    }
   };

  const formInputStyle = {
    // m: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
    p: isMobile ? "0.35rem" : isTablet ? "0.65rem" : 2,
    borderRadius: 3,
    // border: 1,
    // borderColor: "#EBEBEB",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    fontSize: isMobile ? 14 : isTablet ? 13 : 17,
    // minWidth: isMobile ? 290 : isTablet ? 340 : 620,
    // maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  if (!userToken || userToken.length < 1) {
    navigate("/", { replace: true });
  }

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          Edit Business Profile
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box sx={{ py: 5 }}>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Box
              className={
                "grid grid-cols-2 gap-[3rem] mx-auto max-w-5xl"
              }
            >
              <Box className={"flex flex-col border-r border-r-[#0000001A]"}>
                {/* {formik.errors["businessTypes"] && (
                  <FormHelperText >{formik.errors["businessTypes"]}</FormHelperText>
                )} */}
                <BusinessProfileImgInput
                  setFieldValue={formik.setFieldValue}
                  values={formik.values}
                />
                <Box className={"flex flex-col"}>
                  <div className={"mt-2"}>
                    <h4 className={"font-medium text-brown-coffee text-lg"}>
                      Link to Social Media
                    </h4>
                    <Box className="flex flex-row gap-2 mt-5">
                      <ToggleButtonGroup
                        value={socialLinksSelected}
                        onChange={(event, newFormats) => {
                          formik.setFieldValue("socialLinksSelected", newFormats);
                          setSocialLinksSelected(newFormats);
                        }}
                        aria-label="text alignment"
                      >
                        {defaultSocialLinks.map((data, i) => {
                          return (
                            <ToggleButton
                              key={i}
                              value={data.name}
                              className="rounded-full"
                              style={{
                                marginRight: "10px",
                                width: "65px",
                                height: "65px",
                                border: "1px solid",
                                borderRadius: "50%",
                                borderColor: "#EBEBEB",
                                backgroundColor: (formik.values.socialLinksSelected.indexOf(data.name) !== -1)
                                  ? "#0000000A"
                                  : "#FFFFFF",
                              }}
                            >
                              <div className={"indicator cursor-pointer"} >
                                <img className={"w-6"} src={data.icon} alt={""} />
                                {(formik.values.socialLinksSelected.indexOf(data.name) !== -1) ? (<img className={"w-20 indicator-item border-0 p-0 badge badge-lg -translate-y-[1.5rem] translate-x-[-1.5rem]"} src={progressdone} alt={""} />) : ""}

                              </div>
                              {/* <IconTint
                                maxWidth={26}
                                maxHeight={26}
                                src={data.icon}
                              /> */}
                            </ToggleButton>
                          );
                        })}
                      </ToggleButtonGroup>
                    </Box>
                  </div>
                  <Stack direction={"column"} spacing={1} sx={{ my: 2, mr: 2 }}>
                    {defaultSocialLinks.map((data, i) => {
                      return (
                        formik.values.socialLinksSelected.indexOf(data.name) !== -1 && (
                          <Stack direction={"row"} spacing={3} alignItems={"center"} key={i}>
                            <InputBase
                              // component={InputBase}
                              name={"socialLink.facebook"}
                              value={formik.values.socialLink[data.name]}
                              placeholder={data.placeholder}
                              classes={formInputClasses}
                              sx={formInputStyle}
                              startAdornment={
                                <InputAdornment position={"start"}>
                                  <img className={"w-8"} src={data.icon} alt={""} />
                                </InputAdornment>
                              }
                              fullWidth
                              onChange={(event) => {
                                formik.setFieldValue("socialLink." + data.name, event.target.value);
                              }}
                            />
                            <IconButton
                              sx={{
                                backgroundColor: "secondary.main",
                                height: 20,
                                width: 20,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                let linksSelected = [...formik.values.socialLinksSelected];
                                linksSelected = linksSelected.filter(
                                  (link) => link !== data.name
                                );
                                formik.setFieldValue("socialLinksSelected", linksSelected);
                                formik.setFieldValue("socialLink." + data.name, "");
                              }}
                            >
                              <Remove sx={{ color: "common.white", width: 16 }} />
                            </IconButton>
                          </Stack>
                        )
                      );
                    })}
                  </Stack>
                </Box>
              </Box>
              <BusinessProfileForm
                form={formik}
                formErros={formik.errors}
                contactAddress={contactAddress}
                setContactAddress={setContactAddress}
                setPostAddressConfig={setPostAddressConfig}
                setUpdateLocationConfig={setUpdateLocationConfig}
                setLocationChanged={setLocationChanged}
              />
            </Box>
            <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ my: 5 }}>
              <Button
                sx={{
                  color: "secondary.mainLight",
                  backgroundColor: "common.white",
                  borderColor: "secondary.mainLight",
                  border: 2,
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    color: "common.white",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                onClick={() => {
                  navigate("/businessprofile/view");
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  color: "common.white",
                  backgroundColor: "primary.main",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "primary.main",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                variant={"contained"}
                disabled={
                  (!(formik.isValid && formik.dirty) && !formik.isSubmitting) || isThereChange
                }
                onClick={formik.submitForm}
              >
                Update
              </Button>
            </Stack>
          </form>

          <OtpModal
            open={openOtpModal}
            handleClose={handleCloseOtpModal}
            title={otpTitle}
            subTitle={otpSubTitle}
            values={formik.values}
            reqToken={otpToken}
            categories={categories}
            locationChanged={locationChanged}
            updateLocationConfig={updateLocationConfig}
            setLoading={setLoading}
            onSubmit={async () => {
              await updateProfileForAll(formik.values)
            }}
          />
          <Dialog
            open={showNameChangedModal}
            handleClose={() => {
              setShowNameChangedModal(false);
            }}
            PaperProps={{
              sx: {
                maxWidth: 500,
                border: 1,
                borderColor: "#EBEBEB",
                borderRadius: 5,
                p: 5,
                overflowX: "hidden",
              },
            }}
          >
            <DialogTitle>Alert!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Changing school name will have an imapct on their entire classes
                and, their clients might not be able to recognise them anymore
                with the older name.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant={"contained"}
                sx={{ textTransform: "none", borderRadius: 2.5 }}
                onClick={() => {
                  setShowNameChangedModal(false);
                }}
              >
                No
              </Button>
              <Button
                variant={"contained"}
                color={"secondary"}
                sx={{ textTransform: "none", borderRadius: 2.5 }}
                onClick={() => {
                  setDialogShow(true);
                  setShowNameChangedModal(false);
                  formik.submitForm();
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </ Box>
      </LoadingBackdrop>} />
  );
}
