import {
    Box,
    Button, Drawer, FormControl,
    FormHelperText, Stack, Tab, Table, TableBody, TableContainer, TableHead
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { format, parseISO } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { minutesToDuration } from "../../../../helpers";
import * as dashboardActions from "../../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../../redux/reducers/dashboardReducer";
import EditScheduleClassPopover from './EditScheduleClassPopover';

import moment from "moment";
import charetright from "../../../../assets/charetright.svg";
import { useLocalStorage } from "../../../../hooks";
import EditAddScheduleDate from "./EditAddScheduleDate";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getUrlForEvent } from "../../../../utils/confighelpers";

export default function EditSchedules({
    setFormScheduleValues,
    edit = false,
    setLoading = null,
    reloadScheduleDetails,
    handleRadioValueChange = () => null,
    handleRadioValueChange2 = () => null,
    handleStartDateChange = () => null,
    handleEndDateChange = () => null,
    handleStartTimeChange = () => null,
    handleLocationChange = () => null,
    handleDaysInWeekChange = () => null,
    handleClearDaysInWeek = () => null,
    handleCalendarDaysChange = () => null,
    formValues = null,
    formErrors = null,
    handleSubmit,
    clearFormValues,
    isFromDetail = false
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userToken] = useLocalStorage("userToken");
    const [isEdit, setIsEdit] = React.useState(-1);
    const [formPriceErrors, setFormPriceErrors] = React.useState({});
    const [eventPricingList, setEventPricingList] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [showAddDatesModal, setShowAddDatesModal] = React.useState(false);
    const [tabCurrentState, setTabCurrentState] = React.useState('1');

    const dashboardReduxState = useSelector(dashboardState);
    const sessionDataToDisplay = dashboardReduxState.calendarSessionData;
    const eventInfo = sessionDataToDisplay.eventId
    const durationObj = minutesToDuration(eventInfo.duration);

    const cutString = (text, count, insertDots) => {
        return text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "");
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 18,
            fontWeight: "bold",
            border: 0,
            padding: "0px 16px",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 18,
            color: "#464E5F",
            fontWeight: "bold",
            border: 0,
        },
        [`&.${tableCellClasses.body} > div`]: {
            backgroundColor: "rgba(244, 244, 244, 0.4)",
            padding: "30px 16px",
            // minHeight: "72px",
            height: "108px",
            maxHeight: "108px",
            verticalAlign: "middle",
        },
        [`tr.disabled &.${tableCellClasses.body} div`]: {
            backgroundColor: "#D9D9D9",
            opacity: 0.6,
        },
        [`&.${tableCellClasses.body} > div > p`]: {
            fontSize: 13,
            color: "#B5B5C3",
            fontWeight: "500",
        },
        [`&:first-child div`]: {
            // borderRadius: "12px 0 0 12px",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
        },
        [`&:last-child div`]: {
            // borderRadius: "0 12px 12px 0",
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
        },
        padding: "10px 0px 5px 0px",
    }));

    const renderInstances = (listData) => {

        return (
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width="10%" align="left">Date</StyledTableCell>
                            <StyledTableCell width="20%" align="left"></StyledTableCell>
                            <StyledTableCell width="20%" align="left">Time</StyledTableCell>
                            <StyledTableCell width="20%" align="left">Location</StyledTableCell>
                            <StyledTableCell width="20%" align="center">Ticket Sold</StyledTableCell>
                            <StyledTableCell width="10%" align="center"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {sessionDataToDisplay?.allEventSchedules?.map((row, i) => { */}
                        {listData.length ? listData?.reverse()?.map((row, i) => {
                            let seatsBooked = 0;
                            let totalSeats = 0;
                            row.eventPricingList.map((val, j) => {
                                // console.log("val?.ticketsBooked", val?.ticketsBooked);
                                totalSeats = parseInt(totalSeats) + parseInt(val?.capacity)
                                if (val?.ticketsBooked?.ticketsBookedConfirmed) {
                                    seatsBooked = parseInt(seatsBooked) + parseInt(val?.ticketsBooked?.ticketsBookedConfirmed)
                                }
                            });
                            let seatsBookedPerc = (seatsBooked / totalSeats) * 100;
                            return (
                                <StyledTableRow key={i} className={`${(row?.eventStatus && [/* 'Draft', 'Published',  */'cancelled', 'refunded'].indexOf(row?.eventStatus?.toLowerCase()) > -1) ? "disabled" : ""}`}>
                                    <StyledTableCell align="left">
                                        <Box>{moment(parseISO(row.eventDate)).format('DD MMM')}
                                            <br />
                                            <p>{moment(row.eventDate).format('yyyy')}</p>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Box className="leading-[2.8rem]">
                                            <span className="text-[#FF5733]">&nbsp;{(row?.eventStatus && [/* 'Draft', 'Published',  */'cancelled', 'refunded'].indexOf(row?.eventStatus?.toLowerCase()) > -1) ? "CANCELLED" : ""}</span>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Box>{format(parseISO(row.eventDate), "hh:mm aa") + " - " + format(parseISO(row.eventEndDate), "hh:mm aa")}
                                            <p>{`${durationObj.hours} hr ${durationObj.mins} min`}</p>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Box>{cutString(row?.location?.addressName, 25, true)}
                                            <p>{cutString(row?.location?.address, 30, true)}</p>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Box style={{ padding: "16px 16px" }}>
                                            <div className={"flex flex-col items-center"}>
                                                <div
                                                    className={"radial-progress text-light-brown-coffee w-[40px] h-[40px] after:bg-transparent"}
                                                    style={{
                                                        "--value": Number(seatsBookedPerc),
                                                        "--size": "3.5rem",
                                                        "--thickness": "1.5px",
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            "w-[35px] h-[35px] flex justify-center items-center bg-light-brown-coffee rounded-full border-2 border-white"
                                                        }
                                                    >
                                                        <p className={"font-bold text-white text-[8px]"}>
                                                            {Number(seatsBookedPerc).toFixed(1)}%
                                                        </p>
                                                    </div>
                                                </div>
                                                <h3
                                                    className={
                                                        "text-light-brown-coffee font-bold text-sm sm:text-sm md:text-sm lg:text-sm"
                                                    }
                                                >
                                                    {seatsBooked}/{totalSeats}
                                                </h3>
                                                <p className={"text-xs text-center text-[#B5B5C3]"}>
                                                    Seats Booked
                                                </p>
                                            </div>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {isFromDetail ? (
                                            <Box>
                                                <img src={charetright} alt="" className="inline align-baseline my-4 mr-8 cursor-pointer" onClick={async () => {
                                                    console.log("row", row);
                                                    const config = {
                                                        method: "get",
                                                        url: `${getUrlForEvent()}schedule/get?eventScheduleId=${row._id}&isPackage=${row.isPackage}`,
                                                        headers: {
                                                            "x-access-token": userToken,
                                                        },
                                                    };
                                                    const request = await axios(config);
                                                    if (request.status === 200) {
                                                        if (request.data.statusCode === 200) {
                                                            console.log(request.data.data);
                                                            dispatch(dashboardActions.setCalendarClassData(request.data.data));
                                                            dispatch(dashboardActions.setCalendarSessionId(row._id));
                                                            dispatch(dashboardActions.setCalendarSessionData(request.data.data));
                                                            dispatch(dashboardActions.setViewSessionPage(true));
                                                            navigate("/myschedule/detail");
                                                        }
                                                    }
                                                }} />
                                            </Box>
                                        ) : (<EditScheduleClassPopover
                                            scheduleClassDetails={row}
                                            handleUpdate={() => {
                                                reloadScheduleDetails()
                                            }}
                                            setLoading={setLoading}
                                        />)}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        }) : (
                            <StyledTableRow key={0} >
                              <StyledTableCell align="center" colSpan={6}><Box>No instances created</Box></StyledTableCell>
                            </StyledTableRow>
                          )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide last border
        margin: "10px",
        border: 0,
        [`&:hover td > div`]: {
            backgroundColor: "#F4F4F4",
        },
    }));


    return (
        <Stack direction={"column"} className={`${isFromDetail === false ? "py-6 px-8 w-4/5" : "px-0"}`} spacing={2}>
            <div className={"grid grid-cols-2 w-full"}>
                <Box className="items-left my-5 w-full" sx={{ fontSize: 25 }}>
                    Event instances
                </Box>
                <Box className="flex justify-end my-5 w-full">
                    <Button
                        variant={"contained"}
                        className="place-items-end"
                        sx={{
                            width: 152,
                            height: 48,
                            borderRadius: 3,
                            textTransform: "none",
                            backgroundColor: "secondary.mainLight",
                        }}
                        onClick={() => {
                            setShowAddDatesModal(true);
                        }}
                    >
                        Add Dates
                    </Button>
                </Box>
            </div>
            <TabContext value={tabCurrentState}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                    <TabList onChange={(event, newValue) => {
                        setTabCurrentState(newValue);
                    }} aria-label="lab API tabs example" sx={{
                        fontSize: 18
                    }}>
                        <Tab label="Upcoming" value="1" />
                        <Tab label="Past" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    {renderInstances(sessionDataToDisplay?.allEventSchedules.filter((d) => moment(d.eventDate).subtract({ hours: 0 }).isAfter()))}
                </TabPanel>
                <TabPanel value="2">
                    {renderInstances(sessionDataToDisplay?.allEventSchedules.filter((d) => moment(d.eventDate).subtract({ hours: 0 }).isBefore()))}
                </TabPanel>
            </TabContext>
            <FormControl error={formPriceErrors["eventPricingList"] ? true : false}>
                {formPriceErrors["eventPricingList"] && (
                    <FormHelperText>{formPriceErrors["eventPricingList"]}</FormHelperText>
                )}</FormControl>
            {/* <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}> */}
            {/* <Button
                    sx={{
                        color: "secondary.mainLight",
                        backgroundColor: "common.white",
                        borderColor: "secondary.mainLight",
                        border: 2,
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "secondary.mainLight",
                            color: "common.white",
                            boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 3,
                        width: 231,
                        height: 52,
                        fontSize: 16,
                    }}
                    onClick={() => {
                        if (edit) {
                            dispatch(dashboardActions.setCalendarSessionId(-1));
                            dispatch(dashboardActions.setCalendarOpen(false));
                            navigate("/home/dashboard");
                        }
                    }}
                >
                    {edit ? "Cancel" : "Back"}
                </Button> */}
            {/* <Button
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          variant={"contained"}
          onClick={() => null}
        >
          {"Update"}
        </Button> */}
            {/* </Stack> */}
            <Drawer
                ModalProps={{
                    onBackdropClick: () => { },
                    keepMounted: true,
                }}
                open={showAddDatesModal}
                anchor="right"
            >
                <div
                    className={"grid grid-rows-7 p-5"}
                ><Stack direction={"column"} spacing={2}>
                        <Stack spacing={2}>
                            <h4 style={{
                                fontWeight: '600',
                                fontSize: '34.4898px',
                                borderBottom: "1.97085px solid #F4F4F4"
                            }}>Add Dates</h4>
                            <Box>
                                <EditAddScheduleDate
                                    handleRadioValueChange={handleRadioValueChange}
                                    handleRadioValueChange2={handleRadioValueChange2}
                                    handleStartDateChange={handleStartDateChange}
                                    handleEndDateChange={handleEndDateChange}
                                    handleStartTimeChange={handleStartTimeChange}
                                    handleLocationChange={handleLocationChange}
                                    handleDaysInWeekChange={handleDaysInWeekChange}
                                    handleClearDaysInWeek={handleClearDaysInWeek}
                                    handleCalendarDaysChange={handleCalendarDaysChange}
                                    formValues={formValues}
                                    formErrors={formErrors}
                                    setLoading={setLoading}
                                />
                            </Box>
                        </Stack>
                        <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                            <Button
                                sx={{
                                    color: "secondary.mainLight",
                                    backgroundColor: "common.white",
                                    borderColor: "secondary.mainLight",
                                    "&:hover": {
                                        backgroundColor: "secondary.mainLight",
                                        color: "common.white",
                                        boxShadow: "none",
                                    },
                                    boxShadow: "none",
                                    textTransform: "none",
                                    fontWeight: 600,
                                    border: 2,
                                    borderRadius: 3,
                                    width: 231,
                                    height: 52,
                                    fontSize: 16,
                                }}
                                onClick={() => {
                                    clearFormValues();
                                    setShowAddDatesModal(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    color: "common.white",
                                    backgroundColor: "secondary.mainLight",
                                    boxShadow: "none",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "secondary.mainLight",
                                        boxShadow: "none",
                                    },
                                    fontWeight: 600,
                                    borderRadius: 3,
                                    width: 231,
                                    height: 52,
                                    fontSize: 16,
                                }}
                                variant={"contained"}
                                onClick={async () => {
                                    let status = await handleSubmit();
                                    if (status) {
                                        clearFormValues()
                                        setShowAddDatesModal(false);
                                        await reloadScheduleDetails()
                                    }
                                }}>
                                {"Add Date"}
                            </Button>
                        </Stack>
                    </Stack>
                </div>
            </Drawer>
        </Stack>
    );
}
