import React from "react";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Popover, Box } from "@mui/material";

import { useLocalStorage } from "../../../../hooks";
import MarkAsPaidDialog from "../../../../components/MarkAsPaidDialog";
import WarningDialog from "../../../confirmationModals/WarningDialog";
import ErrorDialog from "../../../confirmationModals/ErrorDialog";
import Alert from "../../../../assets/Alert.png";
import BookingLinkModal from "../../../../components/BookingLinkModal";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getUrlForBookings, getWebshopUrlForEvent } from "../../../../utils/confighelpers";
import { useSelector } from "react-redux";
import { userState } from "../../../../redux/reducers/userReducer";

export default function ActionPopover({
  bookingDetails,
  handleUpdate,
  anchorEl,
  setAnchorEl,
  setLoading
}) {
  const navigate = useNavigate();
  const [userToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const { bookingId } = bookingDetails;
  const username = bookingDetails?.student_detail?.username;
  const email = bookingDetails?.student_detail?.email;
  const isPaid = (["paid", "refund-pendding", "refunded"].indexOf(bookingDetails?.paymentStatus?.toLowerCase()) > -1) ? true : false;

  const [markAsPaidDialogOpen, setMarkAsPaidDialogOpen] = React.useState(false);
  const [openPatmentProfileWarningDialog, setOpenPatmentProfileWarningDialog] = React.useState(false);
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
  const [warningText, setWarningText] = React.useState("");
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [errorText, setErrorText] = React.useState(false);
  const [bookingLinkModalOpen, setBookingLinkModalOpen] = React.useState(false);
  const [shareLink, setShareLink] = React.useState("false");

  const handleOpenMarkAsPaidDialog = () => {
    handleClose();
    setMarkAsPaidDialogOpen(true);
  };
  const handleCloseMarkAsPaidDialog = () => setMarkAsPaidDialogOpen(false);
  const handleOpenWarningDialog = () => {
    handleClose();
    setOpenWarningDialog(true);
  };
  const handleCloseWarningDialog = () => setOpenWarningDialog(false);
  const handleOpenErrorDialog = () => {
    handleClose();
    setOpenErrorDialog(true);
  };
  const handleCloseErrorDialog = () => setOpenErrorDialog(false);

  const handleBookingLinkModalOpen = () => setBookingLinkModalOpen(true);
  const handleBookingLinkModalClose = () => setBookingLinkModalOpen(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  const sendTikkiePromise = async (type) => {
    cogoToast.loading("Loading...");
    const body = {
      bookingId,
      forceSendLink: type,
      bookingFor: "Tikkie",
    };
    const config = {
      method: "post",
      url: `${getUrlForBookings()}payments/generateTikkiePmtLink`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    const request = await axios(config);
    return request;
  };

  const sendTikkie = async () => {
    handleClose();
    const request = await sendTikkiePromise(false);
    if (request.status === 200) {
      console.log(request);
      if (request.data.statusCode === 417) {
        setErrorText(request.data.message.errors ? "Something is wrong please try again" : request.data.message);
        handleOpenErrorDialog();
      } else if (request.data.statusCode === 406) {
        setWarningText(request.data.message.errors ? "Something is wrong please try again" : request.data.message);
        handleOpenWarningDialog();
      } else if (request.data.statusCode === 403) {
        setWarningText(request.data.message.errors ? "Something is wrong please try again" : request.data.message);
        handleClose();
        handleCloseWarningDialog();
        setOpenPatmentProfileWarningDialog(true)
      } else if (request.data.statusCode === 200) {
        setShareLink(request.data.data.generatedUrl);
        handleBookingLinkModalOpen();
        handleClose();
      }
    }
  };

  const handleMarkAsPaid = async () => {
    handleClose();
    handleCloseMarkAsPaidDialog();
    cogoToast.loading("Loading...");
    setLoading(true);
    const body = {
      bookingId,
      paymentStatus: "Paid",
      paymentMode: "Cash",
    };
    const config = {
      method: "put",
      url: `${getUrlForBookings()}payments/updatePaymentStatus`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    const request = await axios(config);
    setLoading(false);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        cogoToast.success("Success!");
        handleUpdate();
        console.log(request);
      } else {
        cogoToast.error(request.data.message);
        console.log(request);
      }
    }
  };

  const getStudentInfo = () => {
    let studentDetails = {}

    // if (bookingInfo && bookingInfo?.schedule_class_detail?.participants?.length) {
    //     studentDetails = bookingInfo?.schedule_class_detail?.participants.find((item) => item?.bookingId === bookingInfo.bookingId)
    // }

    if (bookingDetails) {
      studentDetails = bookingDetails?.student_detail
    }

    return studentDetails;
  }

  const getTeacherInfo = () => {
    let teacherDetails = {}

    if (bookingDetails) {
      teacherDetails = bookingDetails?.teacher_detail
    }

    return teacherDetails;
  }

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    let serviceFees = 0;

    if (bookingDetails && bookingDetails?.servicesBought) {
      serviceFees = bookingDetails?.servicesBought.serviceFee
      totalPrice = bookingDetails?.servicesBought.totalPrice

    }

    // return parseFloat(totalPrice + serviceFees).toFixed(2)
    return parseFloat(totalPrice).toFixed(2)
  }

  const sendMail = async () => {
    handleClose();
    cogoToast.loading("Loading...");
    setLoading(true);
    // const body = {
    //   fromEmail: "info@bookzy.nl",
    //   name: getStudentInfo().fullName,
    //   toEmail: getStudentInfo().email,
    //   bookingId: bookingId,
    //   bookingDate: moment(bookingDetails?.bookingDate).format('MMM Do YY, hh:mm'),
    //   totalPrice: calculateTotalPrice()
    // };
    const body = {
      fromName: getTeacherInfo()?.myBusinessProfile?.businessName,
      toName: getStudentInfo().fullName,
      toEmail: getStudentInfo().email,
      bookingType: userReduxState.businessType,
      variables: {
        customer_name: getStudentInfo().fullName,        
        eventOrganizer: getTeacherInfo()?.myBusinessProfile?.businessName,
        // serviceFee: `€ ${calculateServiceFees()}`,
        serviceFee: `€ ${bookingDetails?.totalPaymentServiceFee}`,
        totalPayableAmount: `€ ${calculateTotalPrice()}`,
        paymentMethod: `${bookingDetails?.paymentMethod}`,
        bookingId: `${bookingDetails?.bookingId}`,
        paymentStatus: `${bookingDetails?.paymentStatus}`,
        // manageBookingUrl: `${bookingDetails?.manageBookingUrl}`,
      }
    };
    const config = {
      method: "post",
      url: `${getUrlForBookings()}sendBookingConfirmation`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    const request = await axios(config);
    setLoading(false);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        cogoToast.success("Success!");
        console.log(request);
      } else {
        cogoToast.error(request.data.message);
        console.log(request);
      }
    }
  };

  const handleContactClick = () => {
    handleClose();
    if (email) {
      window.open(`mailto:${email}`);
    } else {
      const usernameCopy = username;
      const number = usernameCopy.replace(/[^\w\s]/gi, "").replace(/ /g, "");
      window.open(`https://web.whatsapp.com/send?phone=${number}`);
    }
  };

  const popoverOptions = [
    {
      text: "Send Tikkie",
      onClick: sendTikkie,
      disabled: (bookingDetails?.status == "Cancelled") || (["paid", "refund-pendding", "refunded"].indexOf(bookingDetails?.paymentStatus?.toLowerCase()) > -1) || parseInt(bookingDetails.amount) <= 0,
      icon: Alert,
    },
    {
      text: "Mark as paid",
      onClick: handleOpenMarkAsPaidDialog,
      // disabled: isPaid,
      disabled: (bookingDetails?.status == "Cancelled") || (["paid", "Not-refunded"].indexOf(bookingDetails?.paymentStatus?.toLowerCase()) > -1) || parseInt(bookingDetails.amount) <= 0,
      icon: Alert,
    },
    {
      text: "Contact",
      onClick: handleContactClick,
      disabled: false,
      icon: Alert,
    },
    {
      text: "Send Email",
      onClick: sendMail,
      disabled: getStudentInfo()?.email ? false : true,
      icon: Alert,
    },
  ];

  return (

    <Box>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          my: 1,
          "& .MuiPopover-paper": {
            boxShadow: "none",
            border: 1,
            borderColor: "#EBEBEB",
            borderRadius: 3,
          },
        }}
      >
        <div className={"flex flex-col gap-3 py-5"}>
          {popoverOptions.map((opt, idx) => (
            <button
              key={idx}
              disabled={opt.disabled}
              className={`flex flex-row justify-between items-center gap-4 cursor-pointer px-8 py-2 transition-all ${opt.disabled
                ? ""
                : "active:bg-light-brown-coffee active:text-white"
                } disabled:opacity-60 disabled:cursor-default`}
              onClick={opt.onClick}
            >
              <p className={"text-sm"}>{opt.text}</p>
            </button>
          ))}
        </div>
      </Popover>

      <MarkAsPaidDialog
        open={markAsPaidDialogOpen}
        handleClose={handleCloseMarkAsPaidDialog}
        onSubmit={handleMarkAsPaid}
      />
      <WarningDialog
        open={openWarningDialog}
        text={warningText}
        onClose={handleCloseWarningDialog}
        onSubmit={async () => {
          cogoToast.loading("Loading...");
          const request = await sendTikkiePromise(true);
          if (request.status === 200) {
            if (request.data.statusCode === 200) {
              setShareLink(request.data.data.generatedUrl);
              handleBookingLinkModalOpen();
              handleClose();
              handleCloseWarningDialog();
            } else if (request.data.statusCode === 403) {
              setWarningText(request.data.message.errors ? "Something is wrong please try again" : request.data.message);
              handleClose();
              handleCloseWarningDialog();
              setOpenPatmentProfileWarningDialog(true)
            }
          }
        }}
      />

      <WarningDialog
        open={openPatmentProfileWarningDialog}
        text={"You have not enabled your payment settings. Do you want to set it now?"}
        onClose={() => setOpenPatmentProfileWarningDialog(false)}
        onSubmit={async () => {
          cogoToast.loading("Loading...");
          navigate("/payment/profile", { replace: true })
        }}
      />
      <ErrorDialog
        open={openErrorDialog}
        text={errorText}
        onClose={handleCloseErrorDialog}
      />
      <BookingLinkModal
        title={"Payment link for " + bookingDetails?.className}
        subtitle="Payment Link Generated"
        open={bookingLinkModalOpen}
        handleClose={handleBookingLinkModalClose}
        bookingLink={shareLink}
      />
    </Box>
  );
}
