import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "../hooks";
import { userState } from "../redux/reducers/userReducer";

import profile_img from "../assets/avatar_placeholder.png";
import logo from "../assets/DasboardLogo.png";
import logo_w from "../assets/logo-w.svg";
import whatsapp_png from "../assets/WhatsApp.png";

import NewNavBar from "./NewHeadNavBar";
import NewSidebarMenuList from "./NewSidebarMenuList";
import "./Sidebar.css";
import SmallScreenNotAllow from "../modals/error/SmallScreenNotAllow";

export default function NeewSidebar({
  children,
  navBarTitleComp,
  showCalendarIcon,
  backgroundColor,
}) {
  // console.log("cheildere----", showCalendarIcon);
  const [userToken, setUserToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [calendarDrawerOpen, setCalendarDrawerOpen] = React.useState(false);

  
  const [screenSizeType, setScreenSizeType] = React.useState("");
  React.useEffect(() => {
    if (screenSizeType === "small" || screenSizeType === "medium") {
      // window.location.href = "/small-screen-not-allow";
      // return false;
    }
    console.log("screenSizeType", screenSizeType);
  }, [screenSizeType]);
  React.useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // const screenWidth = window.outerWidth;
      console.log("screenWidth", screenWidth);
      if (screenWidth < 768) {
        setScreenSizeType("small");
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setScreenSizeType("medium");
      } else {
        setScreenSizeType("large");
      }
    };

    // Initial call to set screen size on component mount
    handleResize();

    // Event listener to update screen size on resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //   React.useEffect(() => {
  //     if (isTablet || isMobile) {
  //       setDrawerOpen(false);
  //     }
  //   }, [isTablet, isMobile]);

  if ((userToken === undefined || userToken == "")) {
    // navigation("/home/dashboard");
    window.location.href = "/";
  }

  React.useEffect(() => {
    document.body.style.backgroundColor = backgroundColor ? backgroundColor : "#FFF";
  }, [backgroundColor]);

  const navBarTitleHight = navBarTitleComp != undefined ? "7%" : "10%"

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCalendarDrawer = (status) => {
    setCalendarDrawerOpen(status);
  };

  const onContactClick = () => {
    const number = "+31680068100".replace(/[^\w\s]/gi, "").replace(/ /g, "");
    window.open(`https://web.whatsapp.com/send?phone=${number}`);
  };

  const mystyle = {
    position: "relative",
    width: "229px",
    height: "100%",
    display: "inline-block",
    float: "left",
  };

  const navstyle = {
    width: "100%",
    height: { navBarTitleHight },
    display: "inline-block",
    // float: "left",
  };

  const outerdiv = {
    width: "100%",
    // minWidth: "1382px",
    // minWidth: "1382px",
    minWidth: "1180px",
    maxWidth: "1382px",
    // height: "90%",
    // float: "left",
  };

  const ToolbarStyle = {
    width: " 100%",
    textAlign: "center"
    // height: "80px",
    // position: "relative",
    // display:"flex",
    // flexDirection: "row",
  };

  const Newouterdiv = {
    // display: "flex",
    // flexDirection: "row",
    width: "100%",
  };

  const drawerWidth = 250;

  const cutString = (text, count, insertDots) => {
    return text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "");
  }
  // console.log("check val-----",navBarTitleComp );

  return (
    (screenSizeType === "small") ? (
      <SmallScreenNotAllow />
    ) : (<div style={Newouterdiv}
      className={`w-full h-full flex flex-row`}
    >
      <div>
        <Drawer
          ModalProps={{
            onBackdropClick: () => setDrawerOpen(false),
            keepMounted: true,
          }}
          open={drawerOpen}
          variant="permanent"
          anchor="left"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#481B3A',
            },
          }}
        >
          <div
            className={"bg-brown-coffee text-white p-4 h-screen flex flex-col"}
          // style={{ minHeight: `100%` }}
          >
            <div style={ToolbarStyle}>
              <div style={{ padding: `5px` }}>
                <img
                  // className={"mask mask-circle inline w-16 h-16 lg:w-16 lg:h-16 min-h-16 max-w-16 min-h-16 max-h-16"}
                  // src={userReduxState.userData?.profilePic ?? profile_img}
                  className={"inline w-auto my-12 "}
                  src={logo_w}
                  alt={"you"}
                />
              </div>
              {/* <div className={"pt-3"}>
                <h3
                  title={userReduxState.userData?.fullName}
                  className={
                    "font-bold text-white text-xl lg:text-lg link-underline link-underline-black cursor-pointer"
                  }
                  onClick={() => navigate("/myprofile")}
                >
                  {cutString(userReduxState.userData?.fullName, 15, true)}
                </h3>
                <h6 className={"text-white text-sm lg:text-base"}>
                  Netherlands
                </h6>
              </div> */}
            </div>
            <div
              className={
                "grow row-start-2 row-span-6 gap-6 sm:gap-0 place-items-center flex flex-col"
              }
            >
              <div className={"row-span-3 self-start flex-1"}>
                <NewSidebarMenuList />
              </div>
              <div
                className={
                  "row-span-1 px-4 lg:mb-2 justify-self-start self-end"
                }
              >
                <button
                  className={
                    "flex flex-row items-center gap-2 my-8 w-[170px] px-4 py-2 bg-[#5B274B] rounded-xl active:bg-[#481B3A] active:opacity-70 transition ease-in-out duration-100"
                  }
                  onClick={onContactClick}
                >
                  <img
                    className={"mr-2 w-[27px] h-[27px]"}
                    src={whatsapp_png}
                    alt={""}
                  />
                  <p
                    className={"font-bold text-lg mt-1 text-white text-center"}
                  >
                    Let's Chat
                  </p>
                </button>
                {/* <img src={logo} alt={""} /> */}
              </div>
            </div>
          </div>
        </Drawer>
      </div>
      <Box
        className="flex-1"
      // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <div style={navstyle}>
          <AppBar
            elevation={2}
            sx={{
              width: `100%`,
              height: `100%`,
              ml: `0px`,
              backgroundColor: "white",
              color: "black",
              boxShadow: "none",
              position: "relative",
              filter:
                "drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06))",
            }}
          >
            <Toolbar>
              <NewNavBar
                titleComp={navBarTitleComp}
                handleDrawer={handleDrawer}
                showCalendarIcon={showCalendarIcon}
                setCalendarDrawer={(status) => { handleCalendarDrawer(status) }}
              />
            </Toolbar>
          </AppBar>
        </div>
        <div
          style={outerdiv}
        // className="justify-self-center"
        >{children}</div>
      </Box>
      <div>
        {/* <NewCalendarSidebar
          calendarDrawerOpen={calendarDrawerOpen}
          onBackdropClick={(status) => {
            setCalendarDrawerOpen(status);
          }}
        ></NewCalendarSidebar> */}
      </div>
    </div>)
  );
}
