import { Box } from "@mui/material";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ServiceTile from "./ServiceTile";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";

import { Button, useMediaQuery } from '@mui/material';
import NeewSidebar from "../../../components/NewSideBar";
import "../../../components/Sidebar.css";
import { LoadingBackdrop } from "../../../layouts";

import noEventImage from "../../../assets/noEventImage.svg";
import { getUrlForServices } from "../../../utils/confighelpers";

export default function ServiceList({ navigation, profileFor, title }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardReduxState = useSelector(dashboardState);
  const [userToken] = useLocalStorage("userToken");
  const [loading, setLoading] = React.useState(false);
  const [serviceList, setServiceList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await getListOfServices();
      setLoading(false);
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSessionClick = async (id, serviceData) => {
    // dispatch(dashboardActions.setCalendarClassData(serviceData));
    dispatch(dashboardActions.setServiceId(id));
    navigate("/myservices/update");
  };


  const getListOfServices = async () => {
    const manageClassesConfig = {
      method: "get",
      url: `${getUrlForServices()}list?getall=${true}&groupby=${"categories"}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    setLoading(true);
    const responce = await axios(manageClassesConfig);
    setLoading(false);
    if (responce.status === 200) {
      if (Array.isArray(responce.data.data)) {
        setServiceList(responce.data.data);
      }
    }
  };

  const renderServices = (data) => {
    return (
      <Box>
        {data.length > 0 ? (
          data.map((catdata, cdidx) => {

            return (<Box key={cdidx}>
              <p
                className={
                  "font-bold text-brown-coffee text-[24px] block my-2"
                }
              >
                {catdata.name}
              </p>
              {catdata.servises.length > 0 ? (
                catdata.servises.map((item, idx) => {
                  const modifiedData = {
                    popover: true,
                    item: item,
                  };
                  return (
                    <div
                      key={idx}
                      className={"cursor-pointer"}
                    // onClick={() => handleSessionClick(modifiedData.id, item)}
                    >
                      <ServiceTile
                        {...modifiedData}
                        key={idx}
                        setLoading={setLoading}
                        handleSessionClick={() => {
                          handleSessionClick(item._id, item);
                        }}
                        reload={() => {
                          getListOfServices();
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <div className={"text-black"}>no services are available</div>
              )}
            </Box>
            );
          })
        ) : (
          <div className={"text-black"}>no services are available</div>
        )}
      </Box>
    );
  };

  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          {title}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"  >

          {serviceList.length ? (<div
            className={"grid grid-cols-2 w-full px-3 pt-5"}
          >
            <div className={"flex flex-col justify-center items-center"}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <div className={`self-start px-3`}>
                  {renderServices(serviceList)}
                </div>
              </Box>
            </div>
            <div className={"flex justify-end items-top"}>
              <div className={"flex-col items-end mr-28"}>
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    dispatch(dashboardActions.setServiceId(-1));
                    navigate("/myservices/new");
                  }}
                >
                  Add New Service
                </Button>
              </div>
            </div>
          </div>) : loading === false ? (
            <Box className="flex flex-col justify-center items-center w-full mt-28">

              <Box className="flex flex-col justify-center items-center text-center">
                <img
                  className={"w-[235px]"}
                  src={noEventImage}
                  alt={""}
                />
                <Box sx={{
                  color: "#481B3A",
                  fontSize: "18px",
                  fontWeight: "700",
                  mt: 3,
                }}>
                  No Services Configured
                </Box>
              </Box>
              <Box className="flex justify-center items-center w-full mt-5">
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    dispatch(dashboardActions.setServiceId(-1));
                    navigate("/myservices/new");
                  }}
                >
                  Add  a new  Service
                </Button>
              </Box>
            </Box>
          ) : ""}
        </Box>
      </LoadingBackdrop>} />
  );
}
