import {
  Box,
  Button, Checkbox, Drawer, FormControl,
  FormHelperText, FormLabel, IconButton, InputBase, Stack, Table, TableBody, TableContainer, TableHead
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../../components/DeleteDialog";
import MGPopover from "../../../components/MGPopover";
import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { userState } from "../../../redux/reducers/userReducer";

import delete_svg from "../../../assets/delete_svg.svg";
import edit_svg from "../../../assets/edit_svg.svg";
import seats_png from "../../../assets/Seatsdark.png";
import { defaultAvailablePaymentMethods } from "../../../utils/constants";
import axios from "axios";
import { getUrlForBookings } from "../../../utils/confighelpers";

export default function PricingTab({
  profileFor,
  formValues,
  setFormValues,
  setTabCurrentState,
  edit = false,
  setLoading = null,
  handleUpdate,
  isFromDetail = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function createData(
    ticketType,
    amount,
    capacity,
    minQtyPerOrder,
    absorbServiceFee,
  ) {
    return { ticketType, amount, capacity, minQtyPerOrder, absorbServiceFee };
  }

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormPriceValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const userReduxState = useSelector(userState);
  const [isEdit, setIsEdit] = React.useState(-1);
  const [isFirstLoad, setIsFirstLoad] = React.useState(false);
  const [totalCapacity, setTotalCapacity] = React.useState(0);
  const [formPriceErrors, setFormPriceErrors] = React.useState({});
  const [formPriceValues, setFormPriceValues] = React.useState({ ticketType: "", amount: 0, capacity: 0, minQtyPerOrder: 1, absorbServiceFee: false });
  const [eventPricingList, setEventPricingList] = React.useState([]);
  const [addTicket, setAddTicket] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [contextMenuOpen, setContextMenuOpen] = React.useState(Boolean(anchorEl));
  const [delDialogOpen, setDelDialogOpen] = React.useState(false);
  const [userToken] = useLocalStorage("userToken");
  const [availablePaymentMethods, setAvailablePaymentMethods] = React.useState(defaultAvailablePaymentMethods);

  const getAvailablePaymentMethods = async () => {
    const requestConfig = {
      method: "get",
      url: `${getUrlForBookings()}payments/getAvailablePaymentMethods?teacherID=${userReduxState.userData?._id}&paymentMethodsFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {
        console.log("data", response.data.data);
        if (response.data.data) {
          setAvailablePaymentMethods(response.data.data)
        }
      }
    }
  };

  const calculatbuyerCost = (amount, onlyServicefee = true) => {
    let defaultPercentage = 0;
    if (availablePaymentMethods?.bookzyPlatformFee) {
      let flatFee = 0;
      let percentageFee = 0;
      if (availablePaymentMethods?.bookzyPlatformFee?.flatFee > 0) {
        flatFee = availablePaymentMethods?.bookzyPlatformFee?.flatFee;
      }
      if (availablePaymentMethods?.bookzyPlatformFee?.percentageFee > 0) {
        percentageFee = availablePaymentMethods?.bookzyPlatformFee?.percentageFee;
      }
      return parseFloat(amount) > 0 ? parseFloat((onlyServicefee ? parseFloat(amount) : 0 ) + parseFloat(flatFee) + (parseFloat(amount) * parseFloat(percentageFee / 100))).toFixed(2) : 0;
    }
    return parseFloat(amount) > 0 ? parseFloat((onlyServicefee ? parseFloat(amount) : 0 ) + (parseFloat(amount) * parseFloat(defaultPercentage / 100))).toFixed(2) : 0;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setContextMenuOpen(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuOpen(null);
  };


  const handleDialogOpen = () => {
    handleClose();
    setDelDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDelDialogOpen(false);
  };

  const handleDelete = async (price) => {
    let pricesCopy = [...eventPricingList];
    if (Array.isArray(pricesCopy)) {
      pricesCopy = pricesCopy.filter((item) => item !== price);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      eventPricingList: [...pricesCopy],
    }));
    setEventPricingList(prevValues => pricesCopy);
    setIsEdit(-1);
    handleDialogClose();
  };

  const clearForm = async () => {
    setFormPriceValues({ ticketType: "", amount: "0", capacity: "0", minQtyPerOrder: "1", absorbServiceFee: false });
  };

  const handleSubmit = async () => {
    const errors = {};
    if (formValues.eventPricingList.length <= 0) {
      errors.eventPricingList = "Please add at least one price!";
    }
    setFormPriceErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      return;
    }
    setTabCurrentState("3");
  };

  const contextId = contextMenuOpen ? "simple-popover" : undefined;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      // color: theme.palette.common.white,
      paddingLeft: isFromDetail === false ? "0px" : "16px",
      fontSize: 18,
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.head}:first-child`]: {
      paddingLeft: isFromDetail === false ? "0px" : "60px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      color: "#464E5F",
      fontWeight: "bold",
      border: 0,
      height: "77px",
      maxHeight: "77px",
    },
    [`&.${tableCellClasses.body} div`]: {
      backgroundColor: "rgba(244, 244, 244, 0.4)",
      padding: "16px",
      // minHeight: "72px",
      height: "77px",
      maxHeight: "77px",
      textAlign: "center",
    },
    [`&.${tableCellClasses.body} div p`]: {
      fontSize: 13,
      color: "#B5B5C3",
      fontWeight: "500",
    },
    /* [`&:first-child`]: {
      borderRadius: "10px 0 0 10px",
    },
    [`&:last-child`]: {
      borderRadius: "0 10px 10px 0"
    }, */
    [`&:first-child div`]: {
      // borderRadius: "12px 0 0 12px",
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      paddingLeft: isFromDetail === false ? "16px" : "60px",
    },
    [`&:last-child div`]: {
      // borderRadius: "0 12px 12px 0",
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
    padding: "10px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "10px",
    border: 0,
    [`&:hover td div`]: {
      backgroundColor: "#F4F4F4",
    },
  }));


  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    p: 1,
    // border: 1,
    borderRadius: 3,
    // borderColor: formPriceErrors["className"] ? "#d32f2f" : "#CCCCCC",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    minWidth: 494,
    maxWidth: 494,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  React.useEffect(() => {
    let totalCapacitys = 0;
    eventPricingList.forEach(element => {
      totalCapacitys = totalCapacitys + parseInt(element.capacity);
    });
    setTotalCapacity(totalCapacitys);
    setFormValues((prevValues) => ({
      ...prevValues,
      seats: totalCapacitys,
    }));
  }, [eventPricingList]);

  React.useEffect(() => {
    if (edit && isFirstLoad) handleUpdate()
    setIsFirstLoad(true)
    setEventPricingList(formValues.eventPricingList);
  }, [formValues.eventPricingList]);


  React.useEffect(() => {
    let pricesCopy = [...formValues.eventPricingList]
    if (!formValues.eventPricingList.length) {
      // pricesCopy.push(createData("Free Addmission", "0", "10", false))
      setFormValues((prevValues) => ({
        ...prevValues,
        eventPricingList: pricesCopy,
      }));
    }
    setIsEdit(-1);
    setEventPricingList(pricesCopy);
    getAvailablePaymentMethods();
  }, []);

  return (
    <Stack direction={"column"} className={`py-6 ${isFromDetail === false ? "px-8 w-4/5" : "px-0"}`} spacing={2}>
      <div className={"grid grid-cols-2 w-full"}>
        <Box className="items-left my-5 w-full" sx={{ fontSize: isFromDetail === false ? 25 : 15 }}>
          Total Capacity: {totalCapacity}
        </Box>
        <Box className="flex justify-end my-5 w-full">
          <Button
            variant={"contained"}
            className="place-items-end"
            sx={{
              width: 152,
              height: 48,
              borderRadius: 3,
              textTransform: "none",
              backgroundColor: "secondary.mainLight",
            }}
            onClick={() => {
              clearForm(); setAddTicket(true);
            }}
          >
            {(isEdit >= 0) ? "Update" : "Add"} Ticket
          </Button>
        </Box>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="30%" align={isFromDetail === false ? "center" : "left"}>Ticket Type</StyledTableCell>
              <StyledTableCell width="25%" align={isFromDetail === false ? "center" : "left"}>Capacity</StyledTableCell>
              <StyledTableCell width="30%" align={isFromDetail === false ? "center" : "left"}>Price</StyledTableCell>
              <StyledTableCell width="15%" align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventPricingList.length ? eventPricingList.map((row, i) => (
              <StyledTableRow key={i} >
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>{row.ticketType}</Box></StyledTableCell>
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>{row.capacity}<p>{row.capacity}/{totalCapacity}</p></Box></StyledTableCell>
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}>
                  <Box>
                    €{row.amount}
                    <p>Cost to buyer: €{(row.absorbServiceFee === true) ? parseFloat(row.amount) : calculatbuyerCost(row.amount)}</p>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center"><Box>

                  <IconButton sx={{ my: 1, p: 0 }} onClick={() => {
                    handleClose();
                    setIsEdit(i);
                    setFormPriceValues(row);
                    setAddTicket(true);
                  }}>
                    <img
                      src={edit_svg}
                      alt={`Edit`}
                    />
                  </IconButton>
                  <IconButton sx={{ p: 0 }} onClick={() => {
                    setIsEdit(i);
                    handleDialogOpen()
                  }}>
                    <img
                      src={delete_svg}
                      alt={`Delete`}
                    />
                  </IconButton>
                  {/* <IconButton sx={{ p: 0 }} onClick={handleClick}>
                    <MoreVertIcon sx={{ color: "black" }} />
                  </IconButton> */}
                  <MGPopover
                    id={"simple-popover" + i}
                    anchorEl={anchorEl}
                    open={contextMenuOpen}
                    handleClose={handleClose}
                    options={[
                      {
                        text: "Edit",
                        onClick: () => {
                          console.log(i);
                          console.log(row);
                          handleClose();
                          setIsEdit(i);
                          setFormPriceValues(row);
                          setAddTicket(true);
                        },
                        icon: seats_png,
                      },
                      {
                        text: "Delete",
                        onClick: handleDialogOpen,
                        icon: seats_png,
                      },
                    ]}
                  />

                </Box></StyledTableCell>
              </StyledTableRow>
            )) : (
              <StyledTableRow key={0} >
                <StyledTableCell align={isFromDetail === false ? "center" : "left"} colSpan={4}><Box>No Pricing created</Box></StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteDialog
        open={delDialogOpen}
        title={"Remove " + profileFor}
        type={profileFor}
        handleClose={handleDialogClose}
        handleDelete={() => handleDelete(eventPricingList[isEdit])}
      />
      <FormControl error={formPriceErrors["eventPricingList"] ? true : false}>
        {formPriceErrors["eventPricingList"] && (
          <FormHelperText>{formPriceErrors["eventPricingList"]}</FormHelperText>
        )}</FormControl>
      {isFromDetail === false ? (<Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}>
        <Button
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            if (edit) {
              dispatch(dashboardActions.setCalendarSessionId(-1));
              dispatch(dashboardActions.setCalendarOpen(false));
              navigate("/home/dashboard");
            } else {
              setTabCurrentState("1");
            }
          }}
        >
          {edit ? "Cancel" : "Back"}
        </Button>
        <Button
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          variant={"contained"}
          onClick={() => {
            if (edit) {
              handleUpdate()
            } else {
              handleSubmit();
            }
          }}
        >
          {edit ? "Update" : "Coninue"}
        </Button>
      </Stack>) : ""}
      <Drawer
        ModalProps={{
          onBackdropClick: () => { },
          keepMounted: true,
        }}
        open={addTicket}
        anchor="right"
      >
        <div
          className={"grid grid-rows-7 p-5"}
        ><Stack direction={"column"} spacing={2}>
            <Stack spacing={2}>
              <h4 style={{
                fontWeight: '600',
                fontSize: '34.4898px',
                borderBottom: "1.97085px solid #F4F4F4"
              }}>{(isEdit >= 0) ? "Update" : "Add"} Tickets</h4>
              <FormControl error={formPriceErrors["ticketType"] ? true : false}>
                <FormLabel sx={formLableStyle}>Ticket Type</FormLabel>
                <InputBase
                  name={"ticketType"}
                  // placeholder={"Title"}
                  value={formPriceValues.ticketType}
                  inputProps={{ maxLength: 30 }}
                  onChange={handleFormValueChange}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                <FormHelperText>Enter a ticket type differentiating pricing and access rights</FormHelperText>
                {formPriceErrors["ticketType"] && (
                  <FormHelperText>{formPriceErrors["ticketType"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["capacity"] ? true : false}>
                <FormLabel sx={formLableStyle}>Capacity</FormLabel>
                <InputBase
                  name={"capacity"}
                  type="number"
                  value={formPriceValues.capacity}
                  inputProps={{
                    maxLength: 30,
                    step: "1",
                  }}
                  onChange={handleFormValueChange}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                <FormHelperText>no. of tickets  for this price</FormHelperText>
                {formPriceErrors["capacity"] && (
                  <FormHelperText>{formPriceErrors["capacity"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["minQtyPerOrder"] ? true : false}>
                <FormLabel sx={formLableStyle}>Minimum Qty Per Booking</FormLabel>
                <InputBase
                  name={"minQtyPerOrder"}
                  type="number"
                  value={formPriceValues.minQtyPerOrder}
                  inputProps={{
                    maxLength: 30,
                    step: "1",
                  }}
                  onChange={handleFormValueChange}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                <FormHelperText>minimum no. of tickets for this price</FormHelperText>
                {formPriceErrors["minQtyPerOrder"] && (
                  <FormHelperText>{formPriceErrors["minQtyPerOrder"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["amount"] ? true : false}>
                <FormLabel sx={formLableStyle}>Price</FormLabel>
                <InputBase
                  name={"amount"}
                  type="number"
                  value={formPriceValues.amount}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    handleFormValueChange(e);
                  }}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                <FormHelperText>Cost to buyer: €{(formPriceValues.absorbServiceFee === true) ? parseFloat(formPriceValues.amount) + ", excluding" : calculatbuyerCost(formPriceValues.amount) + ", including"} €{calculatbuyerCost(formPriceValues.amount, false)} service fee</FormHelperText>
                {formPriceErrors["amount"] && (
                  <FormHelperText>{formPriceErrors["amount"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["absorbServiceFee"] ? true : false}>
                <FormLabel sx={formLableStyle}>
                  <Checkbox
                    name={"absorbServiceFee"}
                    // placeholder={"Title"}
                    // checked={formPriceValues.absorbServiceFee}                    
                    checked={formPriceValues.absorbServiceFee | false}
                    inputProps={{ maxLength: 30 }}
                    onChange={(e) => {
                      const { name, value, checked } = e.target;
                      console.log(checked);

                      setFormPriceValues((prevValues) => ({
                        ...prevValues,
                        absorbServiceFee: checked,
                      }));
                    }}
                    label="Absorb Service fee"
                  />
                  Absorb Service fee </FormLabel>
              </FormControl>
            </Stack>
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
              <Button
                sx={{
                  color: "secondary.mainLight",
                  backgroundColor: "common.white",
                  borderColor: "secondary.mainLight",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    color: "common.white",
                    boxShadow: "none",
                  },
                  boxShadow: "none",
                  textTransform: "none",
                  fontWeight: 600,
                  border: 2,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                onClick={() => {
                  setIsEdit(-1);
                  clearForm();
                  setAddTicket(false);
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  color: "common.white",
                  backgroundColor: "secondary.mainLight",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                variant={"contained"}
                onClick={() => {
                  const errors = {};
                  if (!formPriceValues.ticketType) {
                    errors.ticketType = "Required!";
                  } else {
                    eventPricingList.map((row, i) => {
                      if (isEdit !== i && formPriceValues.ticketType === row.ticketType) {
                        errors.ticketType = "Same ticket type is not allow!";
                      }
                      return row;
                    });
                  }
                  if (formPriceValues.amount < 0) {
                    errors.amount = "Required!";
                  }
                  if (formPriceValues.capacity <= 0) {
                    errors.capacity = "Required!";
                  }
                  if (formPriceValues.capacity % 1 !== 0) {
                    errors.capacity = "Please enter decimal value!";
                  }

                  console.log(errors);
                  setFormPriceErrors({ ...errors });
                  if (Object.keys(errors).length > 0) {
                    return;
                  }
                  let pricesCopy = []
                  if (isEdit >= 0) {
                    pricesCopy = eventPricingList.map((row, i) => {
                      if (isEdit === i) {
                        return formPriceValues;
                      }
                      return row;
                    });
                  } else {
                    pricesCopy = [...eventPricingList, formPriceValues]
                  }
                  setIsEdit(-1);
                  setEventPricingList(pricesCopy);
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    eventPricingList: pricesCopy,
                  }));
                  clearForm();
                  setAddTicket(false);
                }}>
                {(isEdit >= 0) ? "Update" : "Add Ticket"}
              </Button>
            </Stack>
          </Stack>
        </div>
      </Drawer>
    </Stack>
  );
}
