import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import axios from "axios";
import cogoToast from "cogo-toast";

import { Box, Popover, Rating } from "@mui/material";
import { useLocalStorage } from "../../hooks";
import { getUrlForEvent } from "../../utils/confighelpers";

export default function EventTile({
  imgSrc,
  name,
  rating,
  bookingRevenue,
  seatsBooked,
  popover,
  popoverLine,
  insideDetailView,
  handleSessionClick,
  classDetail,
  reloadData,
}) {

  const [userToken] = useLocalStorage("userToken");
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");

  const [eventStatusColors, setEventStatusColors] = React.useState({
    "draft": { //draft
      borderWidth: 0,
      borderColor: "#D7BB294D",
      color: "#8C780C",
      backgroundColor: "#D7BB294D"
    },
    "false": { //draft
      borderWidth: 0,
      borderColor: "#D7BB294D",
      color: "#8C780C",
      backgroundColor: "#D7BB294D"
    },
    "published": { //published
      borderWidth: 0,
      borderColor: "#468C111F",
      color: "#468C11",
      backgroundColor: "#468C111F" //#468C111F
    },
    "true": { //published
      borderWidth: 0,
      borderColor: "#468C111F",
      color: "#468C11",
      backgroundColor: "#468C111F" //#468C111F
    },
    "cancelled": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033" // #ff404033
    },
    "refunded": {
      borderWidth: 0,
      borderColor: "#ff404033",
      color: "#FF4040",
      backgroundColor: "#ff404033"
    }
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const updateStatus = async (eventData, status) => {
    cogoToast.loading("Loading...");
    const config = {
      method: "post",
      url: `${getUrlForEvent()}updatestatus/${eventData.id}`,
      headers: {
        "x-access-token": userToken,
      },
      data: { status }
    };
    const request = await axios(config);
    console.log("request.data", request.data.data);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        reloadData();
      }
    }
  };

  const popoverMenuOpen = Boolean(anchorEl);
  const popoverId = popoverMenuOpen ? "simple-popover" : undefined;

  const cutString = (text, count, insertDots) => {
    return text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "");
  }

  return (
    <div
      className={`${"my-3"} p-4 flex flex-row items-center gap-4 ${insideDetailView ? "cursor-pointer" : ""
        } bg-white hover:bg-[#e1e1e1] shadow-md border border-bright-gray rounded-2xl`}
    >
      <div className="flex flex-row items-top gap-4 grow" onClick={() => { handleSessionClick(); }}>
        <div
          className=""
        >
          <img
            className={`${"w-[127.28px] max-w-[127.28px] h-[101px] rounded-xl"
              } object-fill`}
            src={imgSrc}
            alt={"session img"}
          />
        </div>
        <div className={`flex item-top grow`}>
          <div className={`flex flex-col item-center w-full gap-2`}>
            <h3
              className={`text-[#481B3A] font-bold text-base`}
            >
              {name}
            </h3>
            <div className={"flex flex-row items-center gap-2"}>
              <Rating
                sx={{
                  color: "primary.light",
                  fontSize: 17,
                }}
                value={
                  Array.isArray(rating) ? (rating.length ? rating[0] : 0) : rating
                }
                precision={0.5}
                readOnly
              />
              <p
                className={`text-granite-gray text-xs`}
              >
                {rating}
              </p>
            </div>
            <div className={"flex flex-row items-center gap-2"}>
              {/* <Box
                className={`p-1 px-2 mt-2 text-xs rounded-full`}
                sx={{ ...(classDetail?.isPublic ? eventStatusColors[classDetail.isPublic.toString().toLowerCase()] : eventStatusColors["false"]) }}
              >
                {classDetail?.isPublic ? "Published" : "Draft"}
              </Box> */}
            </div>
          </div>
        </div>
        <div className={"flex flex-col items-center"}>
          <div className={"flex flex-col items-center align-middle"}>
            <div
              className={"text-light-brown-coffee h-[40px] mt-5"}
            >
              <div
                className={
                  "w-[35px] h-[35px] flex justify-center items-center bg-light-brown-coffee rounded-full"
                }
              >
                <p className={"font-bold text-white text-[10px]"}>
                  {Number(seatsBooked)}
                </p>
              </div>
            </div>
            {/* <h3
              className={
                "text-light-brown-coffee font-bold text-sm sm:text-sm md:text-sm lg:text-sm"
              }
            >
              {seatsBooked}/{totalSeats}
            </h3> */}
            <p className={"text-granite-gray text-xs text-center"}>
              Tickets Sold
            </p>
          </div>
        </div>
      </div>
      {popover && (
        <>
          <div className={"flex flex-col items-center ml-auto"}>
            <div
              className={
                "grid place-items-center rounded-xl cursor-pointer"
              }
              onClick={handlePopoverOpen}
            >
              <MoreVertIcon
                color={anchorEl ? "secondary" : "primary"}
              />
            </div>

            <Popover
              id={popoverId}
              open={popoverMenuOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                my: 1,
                "& .MuiPopover-paper": {
                  boxShadow: "none",
                  border: 1,
                  borderColor: "#EBEBEB",
                  borderRadius: 3,
                },
              }}
            >
              <div className={"flex flex-col gap-3 py-5"}>
                <button
                  onClick={() => {
                    handlePopoverClose();
                    handleSessionClick();
                    // handleDateTimeModalOpen();
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    View
                  </p>
                </button>
                {/* <button
                  onClick={() => {
                    handlePopoverClose();
                    updateStatus(classDetail, classDetail?.isPublic ? "Draft" : "Published");
                  }}
                >
                  <p
                    className={`${"text-light-brown-coffee"
                      } text-sm text-left`}
                  >
                    Mark As {classDetail?.isPublic ? "Draft" : "Published"}
                  </p>
                </button> */}
              </div>
            </Popover>
          </div>
        </>
      )}
    </div>
  );
}
