export const API_URL = "https://apiprod.bookzy.app";

// LOCAL DEV URL
export const API_URL_1 = "http://63.34.147.105:5000";

// DEV URL
// export const API_URL_1 = "https://bmcapi-dev.bookzy.app/dev";

export const S3_BUCKET_NAME = "bmcapp";
export const S3_ACCESS_KEY_ID = "AKIA5EIADQFZC72DHT6P";
export const S3_SECRET_ACCESS_KEY = "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";
export const S3_REGION = "eu-west-1";
export const GOOGLE_MAPS_API = "AIzaSyBoaBW9HXkaHd0cJpIMev52Ek__wJgHH90";
export const defaultAvailablePaymentMethods = {
     "payAtVenue": {
          "enable": false,
          "processingFeeApplicable": true,
          "reserveNowPaylater": true,
          "paymentGatewayServiceFee": {
               "paymentProcessingFlatFee": 0,
               "paymentProcessingPercentage": 0
          }
     },
     "tikkie": {
          "enable": true,
          "allowAccess": false,
          "processingFeeApplicable": true,
          "paymentGatewayServiceFee": {
               "paymentProcessingFlatFee": 0,
               "paymentProcessingPercentage": 0
          }
     },
     "ideal": {
          "enable": false,
          "processingFeeApplicable": true,
          "paymentGatewayServiceFee": {
               "paymentProcessingFlatFee": 1,
               "paymentProcessingPercentage": 0
          }
     },
     "applePay": {
          "enable": true,
          "processingFeeApplicable": true,
          "paymentGatewayServiceFee": {
               "paymentProcessingFlatFee": 0.3,
               "paymentProcessingPercentage": 2.5
          }
     },
     "googlePay": {
          "enable": true,
          "processingFeeApplicable": true,
          "paymentGatewayServiceFee": {
               "paymentProcessingFlatFee": 0.3,
               "paymentProcessingPercentage": 2.5
          }
     },
     "creditCard": {
          "enable": true,
          "processingFeeApplicable": true,
          "paymentGatewayServiceFee": {
               "paymentProcessingFlatFee": 0.3,
               "paymentProcessingPercentage": 2.5
          }
     },
     "bookzyPlatformFee": {
          "enable": true,
          "processingFeeApplicable": true,
          "flatFee": 1,
          "percentageFee": 0
     }
}

export const displayAvailablePaymentMethods = {
     "NA": "NA",
     "Not Applicable": "Not Applicable",
     "payAtVenue": "Pay At Venue",
     "tikkie": "Tikkie",
     "ideal": "Ideal",
     "applePay": "Apple Pay",
     "googlePay": "Google Pay",
     "creditCard": "Credit Card",
     "bookzyPlatformFee": "bookzyPlatformFee",
     "classPass": "Not Applicable",
     "eventPass": "Event Pass",
     "servicePass": "Service Pass",
}
