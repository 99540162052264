import {
  Box,
  Button, Checkbox, Drawer, FormControl,
  FormHelperText, FormLabel, IconButton, InputBase, MenuItem, Select, Stack, Table, TableBody, TableContainer, TableHead
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../../components/DeleteDialog";
import MGPopover from "../../../components/MGPopover";
import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { userState } from "../../../redux/reducers/userReducer";

import delete_svg from "../../../assets/delete_svg.svg";
import edit_svg from "../../../assets/edit_svg.svg";
import seats_png from "../../../assets/Seatsdark.png";
import defaultImage from "../../../assets/defaultImage.svg";
import uploadImage from "../../../assets/uploadImage.svg";
import { defaultAvailablePaymentMethods } from "../../../utils/constants";
import axios from "axios";
import Dropzone from "react-dropzone";
import { handleUploadImageToS3 } from "../../../helpers";
import LinearProgressWithLabel from "../../../components/LinearProgressWithLabel";
import { getUrlForBookings } from "../../../utils/confighelpers";

export default function AddonsTab({
  profileFor,
  formValues,
  setFormValues,
  setTabCurrentState,
  edit = false,
  setLoading = null,
  handleUpdate,
  isFromDetail = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function createData(
    id,
    addonType,
    addonphoto,
    addonName,
    maxQtyPerTicket,
    amountForTicket,
    amountForLocation,
    description,
  ) {
    return {
      id,
      addonType,
      addonphoto,
      addonName,
      maxQtyPerTicket,
      amountForTicket,
      amountForLocation,
      description,
    };
  }

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormPriceValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const userReduxState = useSelector(userState);
  const [isEdit, setIsEdit] = React.useState(-1);
  const [isFirstLoad, setIsFirstLoad] = React.useState(false);
  const [formPriceErrors, setFormPriceErrors] = React.useState({});
  const [formPriceValues, setFormPriceValues] = React.useState({ id: "", addonType: "", addonphoto: "", addonName: "", maxQtyPerTicket: 1, amountForTicket: 0, amountForLocation: 0, description: "", });
  const [addonsPricingList, setAddonsPricingList] = React.useState([]);
  const [addAddons, setAddAddons] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [contextMenuOpen, setContextMenuOpen] = React.useState(Boolean(anchorEl));
  const [delDialogOpen, setDelDialogOpen] = React.useState(false);
  const [userToken] = useLocalStorage("userToken");
  const [availablePaymentMethods, setAvailablePaymentMethods] = React.useState(defaultAvailablePaymentMethods);

  const [imageProgress, setImageProgress] = React.useState(0);
  const [addonTypes] = React.useState(["drinks", "food", "special_access",]);

  const getAvailablePaymentMethods = async () => {
    const requestConfig = {
      method: "get",
      url: `${getUrlForBookings()}payments/getAvailablePaymentMethods?teacherID=${userReduxState.userData?._id}&paymentMethodsFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {
        console.log("data", response.data.data);
        if (response.data.data) {
          setAvailablePaymentMethods(response.data.data)
        }
      }
    }
  };

  const calculatbuyerCost = (amount, onlyServicefee = true) => {
    let defaultPercentage = 0;
    if (availablePaymentMethods?.bookzyPlatformFee) {
      let flatFee = 0;
      let percentageFee = 0;
      if (availablePaymentMethods?.bookzyPlatformFee?.flatFee > 0) {
        flatFee = availablePaymentMethods?.bookzyPlatformFee?.flatFee;
      }
      if (availablePaymentMethods?.bookzyPlatformFee?.percentageFee > 0) {
        percentageFee = availablePaymentMethods?.bookzyPlatformFee?.percentageFee;
      }
      return parseFloat(amount) > 0 ? parseFloat((onlyServicefee ? parseFloat(amount) : 0) + parseFloat(flatFee) + (parseFloat(amount) * parseFloat(percentageFee / 100))).toFixed(2) : 0;
    }
    return parseFloat(amount) > 0 ? parseFloat((onlyServicefee ? parseFloat(amount) : 0) + (parseFloat(amount) * parseFloat(defaultPercentage / 100))).toFixed(2) : 0;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setContextMenuOpen(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuOpen(null);
  };


  const handleDialogOpen = () => {
    handleClose();
    setDelDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDelDialogOpen(false);
  };

  const handleDelete = async (price) => {
    let pricesCopy = [...addonsPricingList];
    if (Array.isArray(pricesCopy)) {
      pricesCopy = pricesCopy.filter((item) => item !== price);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      addonsPricingList: [...pricesCopy],
    }));
    setAddonsPricingList(prevValues => pricesCopy);
    setIsEdit(-1);
    handleDialogClose();
  };

  const clearForm = async () => {
    setFormPriceValues({ id: "", addonType: "", addonphoto: "", addonName: "", maxQtyPerTicket: 1, amountForTicket: 0, amountForLocation: 0, description: "", });
  };

  const handleSubmit = async () => {
    const errors = {};
    // if (formValues.addonsPricingList.length <= 0) {
    //   errors.addonsPricingList = "Please add at least one addons!";
    // }
    setFormPriceErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      return;
    }
    setTabCurrentState("4");
  };

  const contextId = contextMenuOpen ? "simple-popover" : undefined;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      // color: theme.palette.common.white,
      paddingLeft: isFromDetail === false ? "0px" : "16px",
      fontSize: 18,
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.head}:first-child`]: {
      paddingLeft: isFromDetail === false ? "0px" : "60px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      color: "#464E5F",
      fontWeight: "bold",
      border: 0,
      height: "77px",
      maxHeight: "77px",
    },
    [`&.${tableCellClasses.body} div`]: {
      backgroundColor: "rgba(244, 244, 244, 0.4)",
      padding: "16px",
      // minHeight: "72px",
      height: "77px",
      maxHeight: "77px",
      textAlign: "center",
    },
    [`&.${tableCellClasses.body} div p`]: {
      fontSize: 13,
      color: "#B5B5C3",
      fontWeight: "500",
    },
    /* [`&:first-child`]: {
      borderRadius: "10px 0 0 10px",
    },
    [`&:last-child`]: {
      borderRadius: "0 10px 10px 0"
    }, */
    [`&:first-child div`]: {
      // borderRadius: "12px 0 0 12px",
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      paddingLeft: isFromDetail === false ? "16px" : "60px",
    },
    [`&:last-child div`]: {
      // borderRadius: "0 12px 12px 0",
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
    padding: "10px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "10px",
    border: 0,
    [`&:hover td div`]: {
      backgroundColor: "#F4F4F4",
    },
  }));


  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    p: 1,
    // border: 1,
    borderRadius: 3,
    // borderColor: formPriceErrors["className"] ? "#d32f2f" : "#CCCCCC",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    minWidth: 494,
    maxWidth: 494,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  const formSelectClasses = {
    sx: {
      "& .MuiMenu-paper": {
        border: 1,
        borderColor: "#424242",
        borderRadius: 3,
        maxHeight: 250,
        mt: 1,
      },
      "&:hover, &.Mui-focused": {
        border: 1,
        borderColor: "#7E00DD"
      },
    },
  };

  // React.useEffect(() => {
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     seats: totalCapacitys,
  //   }));
  // }, [addonsPricingList]);

  React.useEffect(() => {
    if (edit && isFirstLoad) handleUpdate()
    setIsFirstLoad(true)
    setAddonsPricingList(formValues.addonsPricingList);
  }, [formValues.addonsPricingList]);


  React.useEffect(() => {
    let pricesCopy = [...formValues.addonsPricingList]
    if (!formValues.addonsPricingList.length) {
      // pricesCopy.push(createData("Free Addmission", "0", "10", false))
      setFormValues((prevValues) => ({
        ...prevValues,
        addonsPricingList: pricesCopy,
      }));
    }
    setIsEdit(-1);
    setAddonsPricingList(pricesCopy);
    getAvailablePaymentMethods();
  }, []);

  return (
    <Stack direction={"column"} className={`py-6 ${isFromDetail === false ? "px-8 w-11/12" : "px-0"}`} spacing={2}>
      <div className={"grid grid-cols-2 w-full"}>
        <Box className="items-left my-5 w-full" sx={{ fontSize: isFromDetail === false ? 25 : 15 }}>
        </Box>
        <Box className="flex justify-end my-5 w-full">
          <Button
            variant={"contained"}
            className="place-items-end"
            sx={{
              width: 152,
              height: 48,
              borderRadius: 3,
              textTransform: "none",
              backgroundColor: "secondary.mainLight",
            }}
            onClick={() => {
              clearForm(); setAddAddons(true);
            }}
          >
            {(isEdit >= 0) ? "Update" : "Add"} Addons
          </Button>
        </Box>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="20%" align={isFromDetail === false ? "center" : "left"}>Addons Name</StyledTableCell>
              <StyledTableCell width="15%" align={isFromDetail === false ? "center" : "left"}>Addons Type</StyledTableCell>
              <StyledTableCell width="15%" align={isFromDetail === false ? "center" : "left"}>Qty Per Ticket</StyledTableCell>
              <StyledTableCell width="20%" align={isFromDetail === false ? "center" : "left"}>Price Online</StyledTableCell>
              <StyledTableCell width="20%" align={isFromDetail === false ? "center" : "left"}>Price at Venue</StyledTableCell>
              <StyledTableCell width="15%" align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addonsPricingList.length ? addonsPricingList.map((row, i) => (
              <StyledTableRow key={i} >
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>{row.addonName}</Box></StyledTableCell>
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>
                  {row.addonType.replace("_", " ").toLowerCase().replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  })}
                </Box></StyledTableCell>
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>{row.maxQtyPerTicket}</Box></StyledTableCell>
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>€{row.amountForTicket}</Box></StyledTableCell>
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>€{row.amountForLocation}</Box></StyledTableCell>
                <StyledTableCell align="center"><Box>

                  <IconButton sx={{ my: 1, p: 0 }} onClick={() => {
                    handleClose();
                    setIsEdit(i);
                    setFormPriceValues(row);
                    setAddAddons(true);
                  }}>
                    <img
                      src={edit_svg}
                      alt={`Edit`}
                    />
                  </IconButton>
                  <IconButton sx={{ p: 0 }} onClick={() => {
                    setIsEdit(i);
                    handleDialogOpen()
                  }}>
                    <img
                      src={delete_svg}
                      alt={`Delete`}
                    />
                  </IconButton>
                  {/* <IconButton sx={{ p: 0 }} onClick={handleClick}>
                    <MoreVertIcon sx={{ color: "black" }} />
                  </IconButton> */}
                  <MGPopover
                    id={"simple-popover" + i}
                    anchorEl={anchorEl}
                    open={contextMenuOpen}
                    handleClose={handleClose}
                    options={[
                      {
                        text: "Edit",
                        onClick: () => {
                          console.log(i);
                          console.log(row);
                          handleClose();
                          setIsEdit(i);
                          setFormPriceValues(row);
                          setAddAddons(true);
                        },
                        icon: seats_png,
                      },
                      {
                        text: "Delete",
                        onClick: handleDialogOpen,
                        icon: seats_png,
                      },
                    ]}
                  />

                </Box></StyledTableCell>
              </StyledTableRow>
            )) : (
              <StyledTableRow key={0} >
                <StyledTableCell align={isFromDetail === false ? "center" : "left"} colSpan={6}><Box>No Addons created</Box></StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteDialog
        open={delDialogOpen}
        title={"Remove " + profileFor}
        type={profileFor}
        handleClose={handleDialogClose}
        handleDelete={() => handleDelete(addonsPricingList[isEdit])}
      />
      <FormControl error={formPriceErrors["addonsPricingList"] ? true : false}>
        {formPriceErrors["addonsPricingList"] && (
          <FormHelperText>{formPriceErrors["addonsPricingList"]}</FormHelperText>
        )}</FormControl>
      {isFromDetail === false ? (<Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}>
        <Button
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            if (edit) {
              dispatch(dashboardActions.setCalendarSessionId(-1));
              dispatch(dashboardActions.setCalendarOpen(false));
              navigate("/home/dashboard");
            } else {
              setTabCurrentState("2");
            }
          }}
        >
          {edit ? "Cancel" : "Back"}
        </Button>
        <Button
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          variant={"contained"}
          onClick={() => {
            if (edit) {
              handleUpdate()
            } else {
              handleSubmit();
            }
          }}
        >
          {edit ? "Update" : "Coninue"}
        </Button>
      </Stack>) : ""}
      <Drawer
        ModalProps={{
          onBackdropClick: () => { },
          keepMounted: true,
        }}
        open={addAddons}
        anchor="right"
      >
        <div
          className={"grid grid-rows-7 p-5"}
        ><Stack direction={"column"} spacing={2}>
            <Stack spacing={2}>
              <h4 style={{
                fontWeight: '600',
                fontSize: '34.4898px',
                borderBottom: "1.97085px solid #F4F4F4"
              }}>{(isEdit >= 0) ? "Update" : "Add"} Addons</h4>

              <FormControl error={formPriceErrors["addonType"] ? true : false}>
                <FormLabel sx={formLableStyle}>Addons Type</FormLabel>
                <Select
                  disableunderline="true"
                  MenuProps={formSelectClasses}
                  renderValue={(value) => (
                    <div className={"flex flex-row items-center gap-3"}>
                      {/* <img src={select_start_adornment} alt={""} /> */}
                      {value !== "" ? value.replace("_", " ").toLowerCase().replace(/\b[a-z]/g, function (letter) {
                        return letter.toUpperCase();
                      }) : (
                        <span className={"font-light text-plain-gray"}>
                          Select Addons Type
                        </span>
                      )}
                    </div>
                  )}
                  // value={formPriceValues.addonType && addonTypes.filter((d, idx) => d.value === formPriceValues.addonType)[0]}
                  value={formPriceValues.addonType}
                  onChange={(event) => {
                    setFormPriceValues((prevValues) => ({
                      ...prevValues,
                      addonType: event.target.value,
                    }));
                  }}
                  sx={{
                    ...formInputStyle,
                    py: 0.5,
                  }}
                >
                  {addonTypes.map((addonType, idx) => (
                    <MenuItem value={addonType} key={idx}>
                      {addonType.replace("_", " ").toLowerCase().replace(/\b[a-z]/g, function (letter) {
                        return letter.toUpperCase();
                      })}
                    </MenuItem>
                  ))}
                </Select>
                {formPriceErrors["addonType"] && (
                  <FormHelperText>{formPriceErrors["addonType"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["addonphoto"] ? true : false}>
                <FormLabel sx={formLableStyle}>Addons photo</FormLabel>
                <Dropzone
                  accept="image/png, image/jpeg"
                  noDrag={true}
                  onDrop={async (files) => {
                    console.log("files[0]", files[0]);
                    const errors = {};
                    if (files[0]) {
                      setImageProgress(Math.floor((Math.random() * 30) + 1));
                      const file = await handleUploadImageToS3(files[0]);
                      setImageProgress(100);
                      if (file.result.ok) {
                        setFormPriceValues(prevValues => ({
                          ...prevValues,
                          addonphoto: file.location,
                        }))
                      }
                      setImageProgress(100);
                    } else {
                      setFormPriceValues(prevValues => ({
                        ...prevValues,
                        addonphoto: "",
                      }))
                      errors.addonphoto = "Only PNG and JPG formats are allowed";
                    }
                    setFormPriceErrors({ ...errors });
                  }}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        className={"max-h-[165px]"}
                      >
                        <div
                          className={`p-2 border-dashed border-2 min-w-[450px] max-w-[450px] min-h-[120px] rounded-xl flex flex-col justify-center items-center ${formPriceValues.addonphoto ? "border-0" : "bg-white"
                            } ${formPriceErrors.addonphoto ? "border-red-500" : ""}`}
                        >
                          <div className={`grid grid-cols-2 gap-2 w-full`}>
                            {(<img
                              className={
                                "max-h-[120px] rounded-xl object-cover"
                              }
                              src={formPriceValues.addonphoto ? formPriceValues.addonphoto : defaultImage}
                              alt={`Your Addons Photo`}
                            />)}
                            <Box className="w-full flex flex-col justify-center items-center ">
                              <Box className="flex justify-center items-center 1w-1/2">
                                <Box className="flex-none">
                                  <img
                                    className={""}
                                    src={uploadImage}
                                    alt={""}
                                  />
                                </Box>
                                <Box className="flex-1 pl-2">
                                  <div className="text-[#33BE97]">Click here </div>
                                  <div>
                                    to upload Image
                                  </div>
                                </Box>
                              </Box>
                              <Box className="w-full" sx={{ color: "#33BE97" }}>
                                <LinearProgressWithLabel color="progress" variant="determinate" value={imageProgress} />
                              </Box>
                            </Box>
                          </div>
                        </div>
                        <input {...getInputProps()} />
                      </div>
                    );
                  }}
                </Dropzone>
                {formPriceErrors["addonphoto"] && (
                  <FormHelperText>{formPriceErrors["addonphoto"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["addonName"] ? true : false}>
                <FormLabel sx={formLableStyle}>Addons Name</FormLabel>
                <InputBase
                  name={"addonName"}
                  // placeholder={"Title"}
                  value={formPriceValues.addonName}
                  // inputProps={{ maxLength: 30 }}
                  onChange={handleFormValueChange}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                {formPriceErrors["addonName"] && (
                  <FormHelperText>{formPriceErrors["addonName"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["maxQtyPerTicket"] ? true : false}>
                <FormLabel sx={formLableStyle}>Maximum Qty Per Ticket</FormLabel>
                <InputBase
                  name={"maxQtyPerTicket"}
                  type="number"
                  value={formPriceValues.maxQtyPerTicket}
                  inputProps={{
                    maxLength: 30,
                    step: "1",
                  }}
                  onChange={handleFormValueChange}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                <FormHelperText>Maximum no. of addons per ticket</FormHelperText>
                {formPriceErrors["maxQtyPerTicket"] && (
                  <FormHelperText>{formPriceErrors["maxQtyPerTicket"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["amountForTicket"] ? true : false}>
                <FormLabel sx={formLableStyle}>Price Online</FormLabel>
                <InputBase
                  name={"amountForTicket"}
                  type="number"
                  value={formPriceValues.amountForTicket}
                  inputProps={{
                    maxLength: 30,
                    step: "1",
                  }}
                  onChange={handleFormValueChange}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                <FormHelperText>Price of add-on when bought with your ticket</FormHelperText>
                {formPriceErrors["amountForTicket"] && (
                  <FormHelperText>{formPriceErrors["amountForTicket"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["amountForLocation"] ? true : false}>
                <FormLabel sx={formLableStyle}>Price at Venue</FormLabel>
                <InputBase
                  name={"amountForLocation"}
                  type="number"
                  value={formPriceValues.amountForLocation}
                  inputProps={{
                    maxLength: 30,
                    step: "1",
                  }}
                  onChange={(e) => {
                    handleFormValueChange(e);
                  }}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                <FormHelperText>Price for add-on at location</FormHelperText>
                {formPriceErrors["amountForLocation"] && (
                  <FormHelperText>{formPriceErrors["amountForLocation"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPriceErrors["description"] ? true : false}>
                <FormLabel sx={formLableStyle}>Description</FormLabel>
                <InputBase
                  name={"description"}
                  value={formPriceValues.description}
                  onChange={(e) => {
                    handleFormValueChange(e);
                  }}
                  classes={formInputClasses}
                  multiline={true}
                  rows={4}
                  sx={formInputStyle}
                />
                {formPriceErrors["description"] && (
                  <FormHelperText>{formPriceErrors["description"]}</FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
              <Button
                sx={{
                  color: "secondary.mainLight",
                  backgroundColor: "common.white",
                  borderColor: "secondary.mainLight",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    color: "common.white",
                    boxShadow: "none",
                  },
                  boxShadow: "none",
                  textTransform: "none",
                  fontWeight: 600,
                  border: 2,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                onClick={() => {
                  setIsEdit(-1);
                  clearForm();
                  setAddAddons(false);
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  color: "common.white",
                  backgroundColor: "secondary.mainLight",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                variant={"contained"}
                onClick={() => {
                  const errors = {};
                  if (!formPriceValues.addonType) {
                    errors.addonType = "Required!";
                  } else {
                    addonsPricingList.map((row, i) => {
                      if (isEdit !== i && formPriceValues.addonType.value === row.addonType) {
                        errors.addonType = "Same addon type is not allow!";
                      }
                      return row;
                    });
                  }
                  /* if (!formPriceValues.addonphoto) {
                    errors.addonphoto = "Required!";
                  } */
                  if (!formPriceValues.addonName) {
                    errors.addonName = "Required!";
                  }
                  if (formPriceValues.maxQtyPerTicket === "" || formPriceValues.maxQtyPerTicket <= 0) {
                    errors.maxQtyPerTicket = "Required!";
                  }
                  if (formPriceValues.amountForTicket === "" || formPriceValues.amountForTicket < 0) {
                    errors.amountForTicket = "Required!";
                  }

                  if (formPriceValues.amountForLocation === "" || formPriceValues.amountForLocation < 0) {
                    errors.amountForLocation = "Required!";
                  }
                  if (!formPriceValues.description) {
                    errors.description = "Required!";
                  }
                  let addonType = addonTypes.filter((d, idx) => d.value === formPriceValues.addonType)
                  setFormPriceValues((prevValues) => ({
                    ...prevValues,
                    addonType: addonType.value,
                  }));
                  console.log(formPriceValues);
                  setFormPriceErrors({ ...errors });
                  if (Object.keys(errors).length > 0) {
                    return;
                  }
                  let pricesCopy = []
                  if (isEdit >= 0) {
                    pricesCopy = addonsPricingList.map((row, i) => {
                      if (isEdit === i) {
                        return formPriceValues;
                      }
                      return row;
                    });
                  } else {
                    pricesCopy = [...addonsPricingList, formPriceValues]
                  }
                  setIsEdit(-1);
                  setAddonsPricingList(pricesCopy);
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    addonsPricingList: pricesCopy,
                  }));
                  clearForm();
                  setAddAddons(false);
                }}>
                {(isEdit >= 0) ? "Update" : "Add Addons"}
              </Button>
            </Stack>
          </Stack>
        </div>
      </Drawer>
    </Stack>
  );
}
