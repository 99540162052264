import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Autocomplete, Box, Stack } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import { addMinutes, format, parse, parseISO } from "date-fns";
import { DateTime } from "luxon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Scroll from "react-scroll";

import CalendarView from "../../../components/CalendarView";
import ScheduleTile from "./ScheduleTile";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";
import { userState } from "../../../redux/reducers/userReducer";


import { Button, TextField } from '@mui/material';
import NeewSidebar from "../../../components/NewSideBar";
import "../../../components/Sidebar.css";
import {
  LoadingBackdrop
} from "../../../layouts";

import moment from "moment";
import noServiceImage from "../../../assets/noEventImage.svg";
import AddEditFreeSlotModal from "../venue/AddEditFreeSlotModal";
import UserFreeTimeTile from "./UserFreeTimeTile";
import { getUrlForBookings, getUrlForServices, getUrlForUsers } from "../../../utils/confighelpers";

export default function ScheduleList({ navigation, profileFor, title }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardReduxState = useSelector(dashboardState);
  const [userToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [services, setServices] = React.useState();
  const [serviceDates, setServiceDates] = React.useState();
  const [serviceDatesRange, setServiceDatesRange] = React.useState();
  const [sessions, setSessions] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [searchVal, setSearchValues] = React.useState('');
  const [tabCurrentState, setTabCurrentState] = React.useState('1');
  const [serviceList, setServiceList] = React.useState([]);
  const [createFreeSlotDialog, setCreateFreeSlotDialog] = React.useState({
    isEdit: false,
    open: false,
    text: "",
    data: {},
  });

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await getListOfService();
      await getListOfSchedule();
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, dashboardReduxState.updateSessionComp]);

  const onDateClickHandle = async (day) => {
    const dateFormat = "yyyy-MM-dd";
    const newDateFormated = format(new Date(day), dateFormat);
    setSelectedDate(day);
    const newDate = new Date(day).toISOString();
    const config = {
      method: "get",
      // url: `${process.env.REACT_APP_API_URL_1}/service/booking?teacherId=${userReduxState.userData?._id}&groupby=date&searchDate=${newDate}&isSchedule=true`,
      url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&groupby=date&searchDate=${newDate}&isSchedule=true`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    let selectedDateSessions;
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        selectedDateSessions = request.data.data;
        if (!Object.keys(selectedDateSessions).length) {
          const formattedDate = format(parseISO(newDate), dateFormat);
          selectedDateSessions = { [formattedDate]: [] };
        } else {
          const idxOfNewDateInServices = Object.keys(request.data.data).indexOf(
            newDateFormated
          );
          selectedDateSessions = Object.fromEntries(
            Object.entries(request.data.data).slice(idxOfNewDateInServices)
          );
          // selectedDateSessions = filterObject(
          //   selectedDateSessions,
          //   (data) => Array.isArray(data) && data.length > 0
          // );
        }
      }
    }

    setSessions({ ...selectedDateSessions });

    const { scroller } = Scroll;
    scroller.scrollTo(newDateFormated, {
      smooth: true,
      containerId: "sessionsPopover",
      delay: 2000,
    });
  };

  const handleSessionClick = async (id, serviceBookingData) => {

    // cogoToast.loading("Loading session details " + id);
    cogoToast.loading("Loading...");
    dispatch(dashboardActions.setServiceBookingData(serviceBookingData));
    // navigate("/myservices/schedule/detail");
    navigate("/myservices/schedule/detail", {
      state: {
        bookingId: id
      },
    })
  };

  const renderSessions = (day, data, tab = "all") => {
    const dateFormat = "yyyy-MM-dd";
    const parsedDay = parse(day, dateFormat, new Date());
    const displayDateFormat = "EEEE, LLLL d";
    let newArr = [...data];
    // data = newArr.reverse();
    return (
      <Scroll.Element name={dateFormat} id={"sessionsPopover"} key={day}>
        <Scroll.Element name={day}>
          <p
            className={
              "font-bold text-black text-sm block self-start mt-5 mb-1"
            }
          >
            {format(parsedDay, displayDateFormat)}
          </p>
        </Scroll.Element>
        {data.length > 0 ? (
          data.map((item, idx) => {
            const dtStart = DateTime.fromISO(item.slot_date);
            const dtEnd = format(
              addMinutes(dtStart.ts, (item?.duration || 0)),
              "hh:mma"
            );
            let ticketQty = 1;
            const modifiedData = {
              id: item._id,
              item: item,
            };
            return (
              tab === "all"
              || (tab === "published" && item.hasOwnProperty("isPublic") && item.isPublic === true)
              || (tab === "drafts" && item.hasOwnProperty("isPublic") && item.isPublic === false)
            ) ? (
              <div
                className={"cursor-pointer"}
              // onClick={() => handleSessionClick(modifiedData.id, item)}
              >
                {item.itemType === "servieBookings" ? (<ScheduleTile
                  {...modifiedData}
                  key={idx}
                  handleSessionClick={() => {
                    handleSessionClick(modifiedData.id, item);
                  }}
                />) : item.itemType === "freeTime" ? <UserFreeTimeTile
                  {...modifiedData}
                  key={idx}
                  handleSessionClick={() => {
                    // handleSessionClick(modifiedData.id, item);
                  }}
                  handleEditSlot={(data) => {
                    setCreateFreeSlotDialog((prevValues) => ({
                      ...prevValues,
                      isEdit: true,
                      data: {
                        id: data._id,
                        isFullDay: data.isFullDay,
                        slotDates: data.slotDates,
                        slot_date: moment(data.slot_date, 'YYYY-MM-DD'),
                        slot_end_date: moment(data.slot_end_date, 'YYYY-MM-DD'),
                        slot_start_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_start_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                        slot_end_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_end_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                      },
                      text: "",
                      open: true,
                    }));
                  }}
                  handleDeleteSlot={async (data) => {
                    await addEditFreetime(data, "delete");
                  }}
                  loading={loading}
                  setLoading={setLoading}
                /> : <></>}
              </div>
            ) : "";
          })
        ) : (
          <div className={"text-black"}>No Sessions for this day check</div>
        )}
      </Scroll.Element>
    );
  };

  const addEditFreetime = async (slotDetails, action) => {
    cogoToast.loading("Loading...");
    setLoading(true);
    let body = {
      ...slotDetails,
      // slot_date: moment(slotDetails.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      // slot_date: slotDetails.slot_date,
      slot_start_time: moment(slotDetails.slot_start_time, "h:mm A").format("h:mm A"),
      slot_end_time: moment(slotDetails.slot_end_time, "h:mm A").format("h:mm A"),
    };
    delete body.slotDates;
    delete body.slot_end_date;
    if (body.isFullDay === true) {
      delete body.slot_start_time;
      delete body.slot_end_time;
    }
    console.log("body", body);
    let config = {
      method: "post",
      url: `${getUrlForUsers()}userfreetimes/add`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    if (action === "add") {
      config = {
        ...config,
        method: "post",
        url: `${getUrlForUsers()}userfreetimes/add`,
      }
    } else if (action === "edit") {
      config = {
        ...config,
        method: "post",
        url: `${getUrlForUsers()}userfreetimes/update`,
      }
    } else if (action === "delete") {
      config = {
        ...config,
        method: "delete",
        url: `${getUrlForUsers()}userfreetimes`,
        data: {
          id: body._id
        },
      }
    }
    const request = await axios(config);
    console.log("request", request.data.statusCode);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        setCreateFreeSlotDialog((prevValues) => ({
          ...prevValues,
          open: false,
        }));
        await getListOfSchedule();
        setLoading(false);
        return true;
      } else {
        setCreateFreeSlotDialog((prevValues) => ({
          ...prevValues,
          // text: "You are not allowed to modify free slot, you have bookings available at this time",
          text: request.data.message,
        }));
        setLoading(false);
        return true;
      }
    }
    return true;
  }


  const calendarOpenFromRedux = React.useMemo(() => {
    const bool = !loading && dashboardReduxState.calendarOpen;
    return bool;
  }, [loading, dashboardReduxState.calendarOpen]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabCurrentState(newValue);
  };

  const getListOfService = async () => {
    let mgServices = [];
    const manageServicesConfig = {
      method: "get",
      url: `${getUrlForServices()}list?getall=true`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const manageServicesRequest = await axios(manageServicesConfig);
    if (manageServicesRequest.status === 200) {
      if (manageServicesRequest.data.data.length) {
        for (const item of manageServicesRequest.data.data) {
          mgServices.push({
            label: item.serviceName,
            id: item._id,
            data: item
          });
        }
        setServiceList(mgServices);
      }
    }
  };

  const getListOfSchedule = async () => {

    const date = new Date(selectedDate).toISOString();
    const config = {
      method: "get",
      url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&groupby=date&searchDate=${date}&isSchedule=true`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    if (request.status === 200) {
      const allServices = { ...request.data.data };
      let allServiceDates = [];
      const dateFormat = "yyyy-MM-dd";
      const serviceDatesRange = { from: "", to: "" };
      const serviceKeys = Object.keys(allServices);
      const startDate = parse(serviceKeys[0], dateFormat, new Date());
      const endDate = parse(
        serviceKeys[serviceKeys.length - 1],
        dateFormat,
        new Date()
      );
      serviceDatesRange.from = startDate;
      serviceDatesRange.to = endDate;
      console.log("serviceDatesRange", serviceDatesRange);
      serviceKeys.forEach((key) => {
        if (allServices[key].length > 0) {
          const parsedDate = parse(key, dateFormat, new Date());
          allServiceDates.push(parsedDate);
        }
      });
      setServiceDatesRange({ ...serviceDatesRange });
      setServiceDates([...allServiceDates]);
      setServices({ ...allServices });

      let initialSessionsData = { ...allServices };
      const todayFormated = format(new Date(selectedDate), dateFormat);
      const idxOfTodayInServices =
        Object.keys(initialSessionsData).indexOf(todayFormated);
      initialSessionsData = Object.fromEntries(
        Object.entries(initialSessionsData).slice(idxOfTodayInServices)
      );

      // initialSessionsData = filterObject(
      //   initialSessionsData,
      //   (data) => Array.isArray(data) && data.length > 0
      // );

      setSessions({ ...initialSessionsData });
      setLoading(false);
    }
  };

  const onSearchClickHandle = async (serviceData) => {
    const dateFormat = "yyyy-MM-dd";
    const newDateFormated = format(new Date(selectedDate), dateFormat);
    const newDate = new Date(selectedDate).toISOString();
    const config = {
      method: "get",
      url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&groupby=date&searchDate=${newDate}&serviceId=${serviceData.id}&isSchedule=true`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    let selectedDateSessions;
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        selectedDateSessions = request.data.data;
        if (!Object.keys(selectedDateSessions).length) {
          const formattedDate = format(parseISO(newDate), dateFormat);
          selectedDateSessions = { [formattedDate]: [] };
        } else {
          const idxOfNewDateInServices = Object.keys(request.data.data).indexOf(
            newDateFormated
          );
          selectedDateSessions = Object.fromEntries(
            Object.entries(request.data.data).slice(idxOfNewDateInServices)
          );
        }
      }
    }

    setSessions({ ...selectedDateSessions });

    const { scroller } = Scroll;
    scroller.scrollTo(newDateFormated, {
      smooth: true,
      containerId: "sessionsPopover",
      delay: 2000,
    });
  };



  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          {title}
        </h3>
      }
      children={<LoadingBackdrop loading={loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"  >

          {serviceList.length ? (<div
            className={"grid grid-cols-2 w-full px-3 pt-5"}
          >
            <div className={"flex flex-col justify-center items-center"}>
              <Autocomplete
                disablePortal
                options={serviceList}
                value={searchVal}
                onChange={(event: any, newValue: string | null) => {
                  console.log(newValue);
                  if (newValue) {
                    setSearchValues(newValue.label);
                    onSearchClickHandle(newValue);
                  } else {
                    setSearchValues("");
                    onSearchClickHandle({ label: "", id: "", data: {} });
                  }
                }}
                sx={{
                  borderColor: "#EBEBEB",
                  borderRadius: "10px",
                }}
                // renderInput={(params) => <TextField {...params} label="Movie" />}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder={"Search..."}
                      value={searchVal}
                      sx={{
                        borderColor: "#EBEBEB",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          borderColor: "#EBEBEB",
                        },
                      }}
                    /* endAdornment={
                      <InputAdornment position={"end"}>
                        <img
                          className={"lg:w-[18px] w-[18px]"}
                          src={search_png}
                          alt={""}
                        />
                      </InputAdornment>
                    } */
                    // endAdornment={InputProps?.endAdornment}
                    />
                  );
                }}
                className="w-full pr-7"
              />
              <AppBar
                position={"sticky"}
                elevation={0}
                sx={{
                  backgroundColor: "#FFFFFF",
                  py: 2,
                  px: 0,
                  pr: 3.5,
                }}
              >
                <Stack direction={"column"} alignItems={"center"}>
                  <CalendarView
                    selectedDate={selectedDate}
                    onDateClickHandle={onDateClickHandle}
                    eventDatesRange={serviceDatesRange}
                    eventDates={serviceDates}
                  />
                </Stack>
              </AppBar>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabCurrentState}>
                  {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={{
                      fontSize: 18
                    }}>
                      <Tab label="All" value="1" />
                      <Tab label="Published" value="2" />
                      <Tab label="Drafts" value="3" />
                      <Tab label="Archived" value="4" />
                    </TabList>
                  </Box> */}
                  <TabPanel value="1" sx={{ p: 2 }}>
                    <div className={`self-start px-3`}>
                      {Object.entries(sessions).map(([key, value]) =>
                        renderSessions(key, value, "all")
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div className={`self-start px-3`}>
                      {Object.entries(sessions).map(([key, value]) =>
                        renderSessions(key, value, "published")
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                    <div className={`self-start px-3`}>
                      {Object.entries(sessions).map(([key, value]) =>
                        renderSessions(key, value, "drafts")
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value="4">Archived</TabPanel>
                </TabContext>
              </Box>
            </div>
            <div className={"flex justify-end items-top"}>
              <div className={"flex flex-row items-start mr-28 gap-2"}>
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    navigate("/myservices/new");
                  }}
                >
                  Add New Service
                </Button>
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "primary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    setCreateFreeSlotDialog((prevValues) => ({
                      ...prevValues,
                      isEdit: false,
                      data: {
                        // slot_date: moment().format("YYYY-MM-DD"),
                        // slot_date: moment(),
                        title: "",
                        slot_date: "",
                        // slot_end_date: moment(),
                        slot_start_time: moment("9:00 AM", "h:mm A").format("h:mm A"),
                        slot_end_time: moment("10:00 AM", "h:mm A").format("h:mm A"),
                        isFullDay: false,
                        slotDates: [],
                      },
                      text: "",
                      open: true,
                    }));
                  }}
                >
                  Add Free time
                </Button>
              </div>
            </div>
          </div>) : loading === false ? (
            <Box className="flex flex-col justify-center items-center w-full mt-28">

              <Box className="flex flex-col justify-center items-center text-center">
                <img
                  className={"w-[235px]"}
                  src={noServiceImage}
                  alt={""}
                />
                <Box sx={{
                  color: "#481B3A",
                  fontSize: "18px",
                  fontWeight: "700",
                  mt: 3,
                }}>
                  No Services Configured
                </Box>
              </Box>
              <Box className="flex justify-center items-center w-full mt-5">
                <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    navigate("/myservices/new");
                  }}
                >
                  Add  a new  Service
                </Button>
              </Box>
            </Box>
          ) : ""}
          <AddEditFreeSlotModal
            isEdit={createFreeSlotDialog.isEdit}
            loading={loading}
            text={createFreeSlotDialog.text || ""}
            open={createFreeSlotDialog.open}
            data={createFreeSlotDialog.data}
            onClose={() => {
              setCreateFreeSlotDialog((prevValues) => ({
                ...prevValues,
                open: false,
              }));
            }}
            onSubmit={async (data) => {
              console.log("data", data);
              if (createFreeSlotDialog.isEdit) {
                // edit api
                await addEditFreetime(data, "edit");
              } else {
                // add api
                await addEditFreetime(data, "add");
              }
            }}
          />
        </Box>
      </LoadingBackdrop>} />
  );
}
