import {
  Box,
  Button, Checkbox, Drawer, FormControl,
  FormHelperText, FormLabel, IconButton, InputBase, Stack, Table, TableBody, TableContainer, TableHead
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../../components/DeleteDialog";
import MGPopover from "../../../components/MGPopover";
import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { userState } from "../../../redux/reducers/userReducer";

import delete_svg from "../../../assets/delete_svg.svg";
import edit_svg from "../../../assets/edit_svg.svg";
import seats_png from "../../../assets/Seatsdark.png";
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function PassesTab({
  profileFor,
  formValues,
  classInfo,
  setFormValues,
  setTabCurrentState,
  edit = false,
  setLoading = null,
  handleUpdate,
  isFromDetail = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function createData(
    passId, passName, noOfAllowedSessions, amount, validityDays, validUntilConsumed
  ) {
    return { passId, passName, noOfAllowedSessions, amount, validityDays, validUntilConsumed };
  }

  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormPassValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const userReduxState = useSelector(userState);
  const [isEdit, setIsEdit] = React.useState(-1);
  const [isFirstLoad, setIsFirstLoad] = React.useState(false);
  const [formPassErrors, setFormPassErrors] = React.useState({});
  const [formPassValues, setFormPassValues] = React.useState({ passId: "", passName: "", noOfAllowedSessions: 0, amount: 0, validityDays: 0, validUntilConsumed: false, availableForSale: true });
  const [multiSessionPassList, setMultiSessionPassList] = React.useState([]);
  const [addPass, setAddPass] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [contextMenuOpen, setContextMenuOpen] = React.useState(Boolean(anchorEl));
  const [delDialogOpen, setDelDialogOpen] = React.useState(false);
  const [userToken] = useLocalStorage("userToken");


  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuOpen(null);
  };


  const handleDialogOpen = () => {
    handleClose();
    setDelDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDelDialogOpen(false);
  };

  const handleDelete = async (price) => {
    let pricesCopy = [...multiSessionPassList];
    if (Array.isArray(pricesCopy)) {
      pricesCopy = pricesCopy.filter((item) => item !== price);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      multiSessionPassList: [...pricesCopy],
    }));
    setMultiSessionPassList(prevValues => pricesCopy);
    setIsEdit(-1);
    handleDialogClose();
  };

  const clearForm = async () => {
    setFormPassValues({ passId: "", passName: "", noOfAllowedSessions: 0, amount: 0, validityDays: 0, validUntilConsumed: false, availableForSale: true });
  };

  const handleSubmit = async () => {
    const errors = {};
    // if (formValues.multiSessionPassList.length <= 0) {
    //   errors.multiSessionPassList = "Please add at least one price!";
    // }
    setFormPassErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      return;
    }
    setTabCurrentState("4");
  };

  const contextId = contextMenuOpen ? "simple-popover" : undefined;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      // color: theme.palette.common.white,
      paddingLeft: isFromDetail === false ? "0px" : "16px",
      fontSize: 18,
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.head}:first-child`]: {
      paddingLeft: isFromDetail === false ? "0px" : "60px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      color: "#464E5F",
      fontWeight: "bold",
      border: 0,
      height: "77px",
      maxHeight: "77px",
    },
    [`&.${tableCellClasses.body} div`]: {
      backgroundColor: "rgba(244, 244, 244, 0.4)",
      padding: "16px",
      // minHeight: "72px",
      height: "77px",
      maxHeight: "77px",
      textAlign: "center",
      // display: "flex",
      // "justify-content": "center",
      // "align-items": "center",
    },
    [`&.${tableCellClasses.body} div p`]: {
      fontSize: 13,
      color: "#B5B5C3",
      fontWeight: "500",
    },
    /* [`&:first-child`]: {
      borderRadius: "10px 0 0 10px",
    },
    [`&:last-child`]: {
      borderRadius: "0 10px 10px 0"
    }, */
    [`&:first-child div`]: {
      // borderRadius: "12px 0 0 12px",
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      paddingLeft: isFromDetail === false ? "16px" : "60px",
    },
    [`&:last-child div`]: {
      // borderRadius: "0 12px 12px 0",
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
    padding: "10px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "10px",
    border: 0,
    [`&:hover td div`]: {
      backgroundColor: "#F4F4F4",
    },
  }));


  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    p: 1,
    // border: 1,
    borderRadius: 3,
    // borderColor: formPassErrors["className"] ? "#d32f2f" : "#CCCCCC",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    minWidth: 494,
    maxWidth: 494,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };


  React.useEffect(() => {
    if (edit && isFirstLoad) handleUpdate()
    setIsFirstLoad(true)
    setMultiSessionPassList(formValues.multiSessionPassList);
  }, [formValues.multiSessionPassList]);


  React.useEffect(() => {
    let pricesCopy = [...formValues.multiSessionPassList]
    if (!formValues.multiSessionPassList.length) {
      // pricesCopy.push(createData("", "Free Addmission", "10", "0", "10", false))
      setFormValues((prevValues) => ({
        ...prevValues,
        multiSessionPassList: pricesCopy,
      }));
    }
    setIsEdit(-1);
    setMultiSessionPassList(pricesCopy);
  }, []);

  return (
    <Stack direction={"column"} className={`py-6 ${isFromDetail === false ? "px-8 w-4/5" : "px-0"}`} spacing={2}>
      <div className={"grid grid-cols-2 w-full"}>
        <Box className="items-left my-5 w-full" sx={{ fontSize: isFromDetail === false ? 25 : 15 }}>
          {edit === false ? "Session Passes" : ""}
        </Box>
        <Box className="flex justify-end my-5 w-full">
          <Button
            variant={"contained"}
            className="place-items-end"
            sx={{
              width: 152,
              height: 48,
              borderRadius: 3,
              textTransform: "none",
              backgroundColor: "secondary.mainLight",
            }}
            onClick={() => {
              clearForm(); setAddPass(true);
            }}
          >
            {(isEdit >= 0) ? "Update" : "Add"} Pass
          </Button>
        </Box>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="30%" align={"center"}>Pass Name</StyledTableCell>
              <StyledTableCell width="20%" align={"center"}>No Of Sessions</StyledTableCell>
              <StyledTableCell width="20%" align={"center"}>Price</StyledTableCell>
              <StyledTableCell width="15%" align={"center"}>Validity</StyledTableCell>
              <StyledTableCell width="15%" align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {multiSessionPassList.length ? multiSessionPassList.map((row, i) => (
              <StyledTableRow key={i} >
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>{row.passName}</Box></StyledTableCell>
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>{row.noOfAllowedSessions}</Box></StyledTableCell>
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}><Box>€{row.amount}</Box></StyledTableCell>
                <StyledTableCell align={isFromDetail === false ? "center" : "left"}>
                  <Box>
                    {(row.validUntilConsumed === true) ? <span style={{ p: 0, fontSize: 14, lineHeight: "14px" }}>Until Consumed</span> : parseInt(row.validityDays)}
                    <p>days from purchase date</p>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center"><Box>

                  <IconButton sx={{ my: 1, p: 0 }} onClick={() => {
                    handleClose();
                    setIsEdit(i);
                    setFormPassValues(row);
                    setAddPass(true);
                  }}>
                    <img
                      src={edit_svg}
                      alt={`Edit`}
                    />
                  </IconButton>
                  <IconButton sx={{ p: 0 }} onClick={() => {
                    setIsEdit(i);
                    handleDialogOpen()
                  }}>
                    <img
                      src={delete_svg}
                      alt={`Delete`}
                    />
                  </IconButton>
                  {edit ? (<IconButton sx={{ p: 0 }} onClick={() => {
                    dispatch(dashboardActions.setSessionPassData({
                      selectedPassDetail: row,
                      classId: formValues.classId,
                      ...classInfo
                    }));
                    navigate("/class/sessionpasses/purchase");
                  }}>
                    <VisibilityIcon sx={{ color: "black" }} />
                  </IconButton>) : null}
                  <MGPopover
                    id={"simple-popover" + i}
                    anchorEl={anchorEl}
                    open={contextMenuOpen}
                    handleClose={handleClose}
                    options={[
                      {
                        text: "Edit",
                        onClick: () => {
                          console.log(i);
                          console.log(row);
                          handleClose();
                          setIsEdit(i);
                          setFormPassValues(row);
                          setAddPass(true);
                        },
                        icon: seats_png,
                      },
                      {
                        text: "Delete",
                        onClick: handleDialogOpen,
                        icon: seats_png,
                      },
                    ]}
                  />

                </Box></StyledTableCell>
              </StyledTableRow>
            )) : (
              <StyledTableRow key={0} >
                <StyledTableCell align={isFromDetail === false ? "center" : "left"} colSpan={5}><Box>No Pass created</Box></StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteDialog
        open={delDialogOpen}
        title={"Remove Pass"}
        type={"Pass"}
        handleClose={handleDialogClose}
        handleDelete={() => handleDelete(multiSessionPassList[isEdit])}
      />
      <FormControl error={formPassErrors["multiSessionPassList"] ? true : false}>
        {formPassErrors["multiSessionPassList"] && (
          <FormHelperText>{formPassErrors["multiSessionPassList"]}</FormHelperText>
        )}</FormControl>
      {isFromDetail === false ? (<Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}>
        <Button
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            if (edit) {
              dispatch(dashboardActions.setCalendarSessionId(-1));
              dispatch(dashboardActions.setCalendarOpen(false));
              navigate("/home/dashboard");
            } else {
              setTabCurrentState("2");
            }
          }}
        >
          {edit ? "Cancel" : "Back"}
        </Button>
        <Button
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          variant={"contained"}
          onClick={() => {
            if (edit) {
              handleUpdate()
            } else {
              handleSubmit();
            }
          }}
        >
          {edit ? "Update" : "Coninue"}
        </Button>
      </Stack>) : ""}
      <Drawer
        ModalProps={{
          onBackdropClick: () => { },
          keepMounted: true,
        }}
        open={addPass}
        anchor="right"
      >
        <div
          className={"grid grid-rows-7 p-5"}
        ><Stack direction={"column"} spacing={2}>
            <Stack spacing={2}>
              <h4 style={{
                fontWeight: '600',
                fontSize: '34.4898px',
                borderBottom: "1.97085px solid #F4F4F4"
              }}>{(isEdit >= 0) ? "Update" : "Add"} Passes</h4>
              <FormControl error={formPassErrors["passName"] ? true : false}>
                <FormLabel sx={formLableStyle}>Pass name</FormLabel>
                <InputBase
                  name={"passName"}
                  // placeholder={"Title"}
                  value={formPassValues.passName}
                  inputProps={{ maxLength: 30 }}
                  onChange={handleFormValueChange}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                {/* <FormHelperText>Enter a pass type differentiating pricing and access rights</FormHelperText> */}
                {formPassErrors["passName"] && (
                  <FormHelperText>{formPassErrors["passName"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPassErrors["noOfAllowedSessions"] ? true : false}>
                <FormLabel sx={formLableStyle}>No of sessions included</FormLabel>
                <InputBase
                  name={"noOfAllowedSessions"}
                  type="number"
                  value={formPassValues.noOfAllowedSessions}
                  inputProps={{
                    maxLength: 30,
                    step: "1",
                  }}
                  onChange={handleFormValueChange}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                <FormHelperText>Max no. of sessions allow to buy</FormHelperText>
                {formPassErrors["noOfAllowedSessions"] && (
                  <FormHelperText>{formPassErrors["noOfAllowedSessions"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPassErrors["amount"] ? true : false}>
                <FormLabel sx={formLableStyle}>Price</FormLabel>
                <InputBase
                  name={"amount"}
                  type="number"
                  value={formPassValues.amount}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    handleFormValueChange(e);
                  }}
                  classes={formInputClasses}
                  sx={formInputStyle}
                />
                {formPassErrors["amount"] && (
                  <FormHelperText>{formPassErrors["amount"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPassErrors["validityDays"] ? true : false} sx={{
                display: formPassValues.validUntilConsumed ? "none" : ""
              }}>
                <FormLabel sx={formLableStyle}>Validity Days</FormLabel>
                <InputBase
                  name={"validityDays"}
                  type="number"
                  value={formPassValues.validityDays}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    handleFormValueChange(e);
                  }}
                  classes={formInputClasses}
                  sx={formInputStyle}
                  disabled={formPassValues.validUntilConsumed}
                />
                <FormHelperText>Valid for (x) days from the day of buying</FormHelperText>
                {formPassErrors["validityDays"] && (
                  <FormHelperText>{formPassErrors["validityDays"]}</FormHelperText>
                )}
              </FormControl>
              <FormControl error={formPassErrors["validUntilConsumed"] ? true : false}>
                <FormLabel sx={formLableStyle}>
                  <Checkbox
                    name={"validUntilConsumed"}
                    // placeholder={"Title"}
                    // checked={formPassValues.validUntilConsumed}                    
                    checked={formPassValues.validUntilConsumed | false}
                    inputProps={{ maxLength: 30 }}
                    onChange={(e) => {
                      const { name, value, checked } = e.target;
                      console.log(checked);

                      setFormPassValues((prevValues) => ({
                        ...prevValues,
                        ...(checked ? { validityDays: 0 } : {}),
                        validUntilConsumed: checked,
                      }));
                    }}
                    label="Valid forever until consumed"
                  />
                  Valid forever until consumed </FormLabel>
              </FormControl>
              <FormControl error={formPassErrors["availableForSale"] ? true : false}>
                <FormLabel sx={formLableStyle}>
                  <Checkbox
                    name={"availableForSale"}
                    checked={formPassValues?.availableForSale | false}
                    inputProps={{ maxLength: 30 }}
                    onChange={(e) => {
                      const { name, value, checked } = e.target;
                      console.log(checked);

                      setFormPassValues((prevValues) => ({
                        ...prevValues,
                        ...(checked ? { validityDays: 0 } : {}),
                        availableForSale: checked,
                      }));
                    }}
                    label="Available for Sale"
                  />
                  Available for Sale </FormLabel>
              </FormControl>
            </Stack>
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
              <Button
                sx={{
                  color: "secondary.mainLight",
                  backgroundColor: "common.white",
                  borderColor: "secondary.mainLight",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    color: "common.white",
                    boxShadow: "none",
                  },
                  boxShadow: "none",
                  textTransform: "none",
                  fontWeight: 600,
                  border: 2,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                onClick={() => {
                  setIsEdit(-1);
                  clearForm();
                  setAddPass(false);
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  color: "common.white",
                  backgroundColor: "secondary.mainLight",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  width: 231,
                  height: 52,
                  fontSize: 16,
                }}
                variant={"contained"}
                onClick={() => {
                  const errors = {};
                  // passId, passName, noOfAllowedSessions, amount, validityDays, validUntilConsumed
                  if (!formPassValues.passName) {
                    errors.passName = "Required!";
                  } else {
                    console.log("isEdit", isEdit);
                    multiSessionPassList.map((row, i) => {
                      if (isEdit !== i && formPassValues.passName === row.passName) {
                        errors.passName = "Same pass type is not allow!";
                      }
                      return row;
                    });
                  }
                  if (formPassValues.noOfAllowedSessions <= 0) {
                    errors.noOfAllowedSessions = "Required!";
                  }
                  if (formPassValues.noOfAllowedSessions % 1 !== 0) {
                    errors.noOfAllowedSessions = "Please enter decimal value!";
                  }
                  if (formPassValues.amount < 0) {
                    errors.amount = "Required!";
                  }
                  if (formPassValues.validUntilConsumed === false) {
                    if (formPassValues.validityDays <= 0) {
                      errors.validityDays = "Required!";
                    }
                    if (formPassValues.validityDays % 1 !== 0) {
                      errors.validityDays = "Please enter decimal value!";
                    }
                  } else {
                    setFormPassValues((prevValues) => ({
                      ...prevValues,
                      validityDays: 0,
                    }));
                  }

                  console.log(errors);
                  setFormPassErrors({ ...errors });
                  if (Object.keys(errors).length > 0) {
                    return;
                  }
                  let pricesCopy = []
                  if (isEdit >= 0) {
                    pricesCopy = multiSessionPassList.map((row, i) => {
                      if (isEdit === i) {
                        return formPassValues;
                      }
                      return row;
                    });
                  } else {
                    pricesCopy = [...multiSessionPassList, formPassValues]
                  }
                  setIsEdit(-1);
                  setMultiSessionPassList(pricesCopy);
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    multiSessionPassList: pricesCopy,
                  }));
                  clearForm();
                  setAddPass(false);
                }}>
                {(isEdit >= 0) ? "Update" : "Add Pass"}
              </Button>
            </Stack>
          </Stack>
        </div>
      </Drawer>
    </Stack>
  );
}
